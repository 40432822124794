import React, { Component } from 'react';
import Paginate from './paginate';
import {Spinner,Button} from 'react-bootstrap'
import cloud from './../assets/set/cloud.svg'
import Pills from './../Pills/'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { withTranslation } from 'react-i18next';

class Transactions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPage:1,
      live:'',
      interval:2500,
      prepss:{totalStake:'0x0',totalDelegated:'100',preps:[]},
      preps:{}

    }
  }

componentDidMount() {
    let live= this.props.location.pathname === '/live' || this.props.location.pathname === '/live/'
    document.title = 'ICON Transactions'

    axios.get(`/data/thes`)
    .then(res => {
      this.setState({preps:res.data});
    })

    this.setState({ live,isLoading: true });
    this.props.match.params.currentPage !== 1 || typeof(this.props.match.params.currentPage) !== 'undefined' || this.props.match.params.currentPage !== '' ? this.setState({currentPage:this.props.match.params.currentPage}) : this.setState({currentPage:1})
    this.setState({isLoading:false})
  }

static getDerivedStateFromProps(nextProps, prevState){

if(typeof(nextProps.match.params.currentPage!=='undefined')) {
 if(nextProps.match.params.currentPage!==prevState.currentPage){
   return { activePage: nextProps.match.params.currentPage, pageNumber: nextProps.match.params.currentPage};
}
else return null;
}
}


async componentDidUpdate(prevProps, prevState) {
  if(prevProps.match.params.currentPage!==this.props.match.params.currentPage){
 this.setState({currentPage:this.props.match.params.currentPage})

}

if(prevProps.location.pathname!==this.props.location.pathname ){
  let live = this.props.location.pathname === '/live' || this.props.location.pathname === '/live/'
  this.setState({live});

}
}


lex = (w) => this.props.t(w, { framework: "react-i18next" })

changeLive = (live) => this.setState({live:!live})




render() {
    const { isLoading, live, interval, preps, prepss} = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }

return (
  <div className="Transactions container">
  <Pills type="tx" thes={preps} preps={prepss}/>

      <div className="row mt-3 mb-5">
        <div className="col-sm-12">

        <div className="card border-0 shadow-sm">
          <div className="card-body">
            <div className="row">
              <div className="col-10 float-left text-left">
                  <h3 className="float-left">
                  <img
                       src={cloud}
                       className="pb-2"
                       width="35"
                       height="35"
                       alt={'transactions'}
                     />
                   ICON {this.lex('transactions')}</h3>

                </div>
                <div className={`col-2 float-right`}>
                                        <Button
                                        className="float-right mr-2"
                                        variant={live? 'dark' : 'primary'}
                                        disabled={isLoading}
                                        onClick={()=>this.changeLive(live)}
                                        >
                                        {isLoading ? 'Loading…' : <span><FontAwesomeIcon icon={live? faPause : faPlay} /></span>}
                                      </Button>

                  </div>


            </div>
            <Paginate page={this.props.match.params.currentPage} live={live} interval={interval} preps={preps} type={'tx'} />
      </div>
      </div>
            </div>

</div>
</div>

);
}
}

export default withTranslation('common')(Transactions);
