import React, { useState, useEffect } from 'react';
import Posts from './TransactionPosts';
import Pagination from './../Pagination';
import axios from 'axios';
import {Table} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import {wait} from './../Clipboard/wait'



const Transactions = ({account, leader, type}) => {
  const [posts, setPosts] = useState({listSize:0,data:[{address:'hx1000000000000000000000000000000000000000'}]});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(40);
  // let [count, setCount] = useState(0);

  useEffect(() => {

    setCurrentPage(1);

  }, [account]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      let link;

      switch (type) {
        case 'tokenpage':
        link = 'https://tracker.icon.foundation/v3/token/txList?page='+currentPage+'&count='+postsPerPage+'&contractAddr='+account
        break;
        case 'token':
        link = 'https://tracker.icon.foundation/v3/address/tokenTxList?page='+currentPage+'&count='+postsPerPage+'&address='+account
        break;
        case 'tokentx':
        link = 'https://tracker.icon.foundation/v3/contract/tokenTxList?addr='+account+'&count='+postsPerPage+'&page='+currentPage
        break;
        case 'claims':
        link = 'https://tracker.icon.foundation/v3/address/claimIScoreList?page='+currentPage+'&count='+postsPerPage+'&address='+account
        break;
        case 'internal':
        link = 'https://tracker.icon.foundation/v3/address/internalTxList?page='+currentPage+'&count='+postsPerPage+'&address='+account
        break;
        default:
        link = 'https://tracker.icon.foundation/v3/address/txList?page='+currentPage+'&count='+postsPerPage+'&address='+account
      }
      const res = await axios.get(link);
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage,postsPerPage,account,type]);
  
  const currentPosts = posts.data
  const paginate = pageNumber => setCurrentPage(pageNumber);



  if ((typeof(posts.data) === 'undefined') || (posts.data === null)) {
    return (
      'Nothing here yet!'
    )
  }
  
  return (loading) 
  ? wait()
  : (

    <div className="text-left">
    <p>A total of
    <NumberFormat className="font-weight-bold" decimalScale='2' value={posts.listSize} displayType={'text'} thousandSeparator={true} prefix='&nbsp;' suffix='&nbsp;'/>
    {type==='claims' ? 'claims' : 'transactions'}
    </p>
    <Table className="breakTable text-color" hover>
      <thead>
        <tr>
        <th colSpan="2" className="d-none d-lg-table-cell border-0">Hash</th>
        <th colSpan="2" className="d-none d-xl-table-cell border-0">Type</th>
        <th colSpan="5" className="border-0">Transaction</th>
        <th colSpan="2" className="d-none d-xl-table-cell border-0">Change</th>
        <th colSpan="3" className="d-none d-lg-table-cell border-0">Time</th>
        </tr>
      </thead>
      <tbody>
      <Posts currentPage={currentPage} posts={currentPosts} loading={loading} leader={leader} account={account} />
      </tbody>
    </Table>
    <div className="pl-2 pb-0 mb-0 pt-2">
    <div className={posts.listSize < 40 ? 'd-none' : 'd-block' }>
    <Pagination
      currentPage={currentPage}
      postsPerPage={postsPerPage}
      totalPosts={posts.listSize}
      paginate={paginate}
    />
    </div>
    </div>
    </div>

  );
};

export default Transactions;
