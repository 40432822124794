import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap'

const ContractDl = ({contract, zip}) => {

  // let array = zip.replace(/(.{3})/g,' ')



    const downloadZip = () => {

      function convert(file) {
        var cleaned_hex = file;
        if (cleaned_hex.length % 2) {
          alert ("Error: cleaned hex string length is odd.");
          return;
        }

        var binary = [];
        for (var i=0; i<cleaned_hex.length/2; i++) {
          var h = cleaned_hex.substr(i*2, 2);
          binary[i] = parseInt(h,16);
        }
        var byteArray = new Uint8Array(binary);
        return byteArray;
      }

    const element = document.createElement("a");
    // const file = new Blob([(zip.substring(2).match(/.{4}/g).join(' ')).split('').map((l,i) => (i + 1) % 40 ? l : '\n').join('')], {type: 'application/zip'});

    const file = new Blob([convert(zip.substring(2).toUpperCase().replace(/[^A-Fa-f0-9]/g, ""))], {type: "application/octet-stream"});

    element.href = URL.createObjectURL(file);
    element.download = contract+'.zip';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

    return (
      <div className="float-right m-2">
        <Button variant="outline-white" className={contract === 'cx0000000000000000000000000000000000000000' ? 'd-none' : 'text-color'} onClick={downloadZip}><FontAwesomeIcon icon={faCode} /> Download Contract</Button>
      </div>
    );
  }

export default ContractDl;
