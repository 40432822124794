import React from 'react';
import { Link } from 'react-router-dom';
import {Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import Moment from 'react-moment';
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {wait} from './../Clipboard/wait'

const Posts = ({ currentPage, posts, loading, leader, account }) => {

  // console.log(posts)
  return loading
  ? wait()
  : posts.map((line, index) => {

    // console.log(leader[address])

    let { txHash, dataType, amount, toAddr, fromAddr, createDate, quantity, symbol, contractSymbol, address, icx, age } = line //destructuring
     // console.log(leader[address])
     let time = (typeof(createDate) !== 'undefined') ? createDate : age


     return (
       <tr key={index}>
       <td colSpan="2" className="align-middle"><div className="media border-0 p-0 pr-2">
       <span className="d-inline-block d-md-none">TX Hash: </span>&nbsp;
       {(txHash ==='genesistx 0') ? 'genesistx 0' : (<Link to={`/tx/${txHash}`}>
       {(typeof(txHash) === 'undefined') ? '' : txHash.slice(2,12)+'...'}
       </Link>)}
       </div></td>
       <td colSpan="2" className="d-none d-xl-table-cell"><div className="media border-0 p-0 d-none d-xl-flex">
       <Badge className='p-1 badge-width' variant={((dataType) === 'base') ? 'dark' : ((dataType=== 'call') ? 'secondary' : (fromAddr === account) ? 'danger' : 'primary')}>
       {((dataType) === 'base') ? 'SYSTEM' : ((dataType=== 'call') ? 'CONTRACT' : (fromAddr === account) ? 'SEND' : 'RECEIVE')}
       </Badge>
       </div></td>
       <td colSpan="5"><div className="media border-0 p-0 mx-auto">
       {(typeof(fromAddr) === 'undefined' || txHash === 'genesistx 0') ? ' ' : (
       <div className="d-none d-md-block"><Jazzicon diameter={20} seed={(txHash === 'genesistx' || typeof(fromAddr)) === 'undefined' ? 0 : jsNumberForAddress(fromAddr)} /></div>
     )}
       &nbsp;
       {(txHash ==='genesistx 0') ? 'genesistx 0' : (fromAddr === account && typeof(leader[fromAddr]) !== 'undefined') ? leader[fromAddr] : (fromAddr === account) ? (fromAddr.slice(0,12)+'...')  : (typeof(fromAddr) !== 'undefined') ?
        (<Link to={`/address/${fromAddr}`}>
        {typeof(fromAddr) === 'undefined' ? '' : (typeof(leader[fromAddr]) === 'undefined') ? fromAddr.slice(0,12)+'...' : leader[fromAddr] }
        </Link> ) : ('iscoreClaim')}
       <FontAwesomeIcon className="mt-1 mb-1 mr-3 ml-3" icon={faLongArrowAltRight} />
       <div className="d-none d-md-block"><Jazzicon diameter={20} seed={typeof(toAddr) !== 'undefined' || typeof(toAddr) !== 'undefined'  ? jsNumberForAddress(toAddr) : (typeof(address) !== 'undefined') ? jsNumberForAddress(address) : 0} /></div>
       &nbsp;
       {(toAddr === account && typeof(leader[toAddr]) !== 'undefined') ? leader[toAddr] : (toAddr === account) ? (toAddr.slice(0,12)+'...') : (typeof(address) === 'undefined') ? (
       <Link to={`/address/${toAddr}`}>
       {typeof(toAddr) === 'undefined' ? '' : (typeof(leader[toAddr]) === 'undefined') ? toAddr.slice(0,12)+'...' : leader[toAddr] }
       </Link> ) : (
         <Link to={`/address/${address}`}>
         {typeof(address) === 'undefined' ? '' : (typeof(leader[address]) === 'undefined') ? address.slice(0,12)+'...' : leader[address] }
         </Link>
       )}
       </div></td>
       <td colSpan="2"><div className="media border-0 p-0">
       <Badge className='p-1 badge-width media-bg text-color'>
       {(typeof(symbol) !== 'undefined') ?
       (<NumberFormat decimalScale='2' value={quantity} displayType={'text'} thousandSeparator={true} prefix={quantity === '0' ? '' : (fromAddr === account) ? '-' : '+'} suffix={' '+symbol}/>) :
       (typeof(contractSymbol) === 'undefined') ?
         (<NumberFormat decimalScale='2' value={(typeof(amount) === 'undefined') ? (icx) :
         (amount)} displayType={'text'} prefix={amount === '0' ? '' : (fromAddr === account) ? '-' : '+'} thousandSeparator={true} suffix={' ICX'}/>) :
         <NumberFormat decimalScale='2' value={quantity} displayType={'text'} thousandSeparator={true} prefix={quantity === '0' ? '' : (fromAddr === account) ? '-' : '+'} suffix={' '+contractSymbol}/>
       }
       </Badge>
       </div></td>
       <td colSpan="3" className="align-middle"><div className="media border-0 p-0">
       <Moment format="YYYY/MM/DD HH:mm:ss">{time}</Moment>
       </div></td>
       </tr>
     )
   })
};

export default Posts;
