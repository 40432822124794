import React, {Component} from 'react';
import {ListGroup,
        Row
        } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  {
        faTelegram,
        faTwitter
        } from "@fortawesome/free-brands-svg-icons/";
import logo from './../assets/flogo.svg';
import blockmove from './../assets/blockmove.svg';
import { withTranslation } from 'react-i18next';

  class AppFooter extends Component {



    render() {
    const lex = (w) => this.props.t(w, { framework: "react-i18next" })

    let group = (css, title, content) => <div className={css}><h6 className="text-white heading-border text-uppercase">{title}</h6><div className="d-flex justify-content-center"><ListGroup size="sm">{content}</ListGroup></div></div>
    let item = (link, name) => <ListGroup.Item className="app-footer"><a className="list-group-item" href={link} rel="noopener noreferrer" target="_blank">{name}</a></ListGroup.Item>

    return (
      <div className="footer">
      <div className="app-footer pt-4 pb-5 pl-2 pr-2">
      <div className="container">
      <Row className="justify-content-md-between">
      <div className="col-lg-2 mb-4 ml-2 mr-2 mb-lg-0">
      <div className="d-none d-lg-flex lex-column">
      <div className="w-auto p-0">
      <img src={logo} loading="lazy" width="50" height="50" className="d-inline-block align-middle" alt="ICONwatch logo" />
      <br/><span className="d-none d-sm-inline-block text-white logoSpan">iconwatch</span>
      </div>
      </div>
      </div>
      {group('col-6 col-md-4 p-0 col-lg-3 mb-4',lex('madeby'),
      <div>
      {item('https://blockmove.eu/',<span><img
                src={blockmove}
                loading="lazy"
                width="30"
                height="30"
                className="d-inline-block align-middle"
                alt="blockmove logo"
              />
                  <span className="p-1 linkSpan">blockmove</span></span>)}
      </div>
      )}
      {group('col-6 col-md-4 col-lg-3 mb-4 mb-lg-0',lex('tools'),
      <div>
      {item('https://iconwatch.freshstatus.io/',lex('network'))}
      {item('https://ganymede.blockmove.eu/','Ganymede API')}
      </div>
      )}
      {group('d-none d-md-block col-md-4 col-lg-3 mb-4 mb-lg-0',lex('social'),
      <div>
      {item('https://t.me/blockmove',<span><FontAwesomeIcon icon={faTelegram} /> Telegram</span>)}
      {item('https://twitter.com/eublockmove',<span><FontAwesomeIcon icon={faTwitter} /> Twitter</span>)}
      </div>
      )}
      </Row>
      </div>
      </div>
        <div className="lowerFooter p-3">
          <div className="container">
            <Row>
              <div className="col-6 text-left text-white">
                {lex('copyright')} &copy;&nbsp;
              {new Date().getFullYear()}

              </div>
              <div className="col-6 text-right">
              v0.0.94 alpha
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
}
}

export default withTranslation('common')(AppFooter);
