import React, { Component } from 'react';
import server from './../assets/set/server.svg'
// import BigNumber from './bignumber'
import BigNumber from 'bignumber.js'
import {Col,Row} from 'react-bootstrap'

class Convert extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date:'0x59b43f5b2a671',
      newDate:'4 Jan 2020 0:37:50',
      delegated:'0',
      daily:'0',
      weekly:'0',
      monthly:'0',
      yearly:'0',
      hex:'0x0',
      dec:0

    }
  }

  componentDidMount() {
    document.title = 'Prep Tools';
  }

  onCalcInputChange(e) {
    const target = e.currentTarget
    // this.setState({ [`${target.id}`]: parseFloat(target.value.toString()) || '', show:true })
    this.setState({ [`${target.id}`]: (target.value.toString())}, this.calculate(target.id,target.value.replace(/^[0-9]([,.])$/).toString()))

      // this.setState({ [`${target.id}`]: parseFloat(target.value.toString()) || ''}, this.calculate(target.id,target.value.replace(/^[0-9]([,.])$/).toString()))
  }

  onDateInputChange(e) {
    const target = e.currentTarget
    this.setState({ [`${target.id}`]: (target.value.toString()) || ''}, this.calcDate(target.value.toString()))
  }

  onHexInputChange(e) {
    const target = e.currentTarget
    let value;
    target.id === 'dec' || target.id === 'decc'  ? value = '0x'+BigNumber(target.value,10).toString(16) : target.value.slice(0,2) === '0x' ? value = BigNumber(target.value.slice(2,100),16).toString(10) : value = BigNumber(target.value.slice(0,100),16).toString(10)
    target.id === 'dec' || target.id === 'decc'  ? this.setState({ hex:value, dec:target.value}) : this.setState({ dec:value, hex:target.value})
  }

  calcDate(datea) {
    let newDate;
    datea.slice(0,2) === '0x' ? newDate = (parseInt(datea.slice(2,100),16)/1000) : newDate = (parseInt(datea,16)/1000)

    var a = new Date(newDate);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;

    this.setState({newDate:time})
  }


  calculate(id,number) {
    let value = typeof(number) === 'undefined' || number === '' ? BigNumber(0) : BigNumber(number)
      switch(id) {
        case('loop'):
        {
          let gloop = value.multipliedBy(1e-7).toFixed()
          let step = value.multipliedBy(1e-10).toFixed()
          let iscore = value.multipliedBy(1e-15).toFixed()
          let icx = value.multipliedBy(1e-18).toFixed()
          this.setState({gloop,step,iscore,icx})
        }
        break;
        case('gloop'):
        {
          let loop = value.multipliedBy(10000000).toFixed()
          let step = value.multipliedBy(0.001).toFixed()
          let iscore = value.multipliedBy(0.00000001).toFixed()
          let icx = value.multipliedBy(1e-11).toFixed()
          this.setState({loop,step,iscore,icx})

        }
        break;
        case('step'):
        {
          let loop = value.multipliedBy(1e+10).toFixed()
          let gloop = value.multipliedBy(1000).toFixed()
          let iscore = value.multipliedBy(0.00001).toFixed()
          let icx = value.multipliedBy(1e-8).toFixed()
          this.setState({loop,gloop,iscore,icx})

        }
        break;
        case('iscore'):
        {
          let loop = value.multipliedBy(1e15).toFixed()
          let gloop = value.multipliedBy(100000000).toFixed()
          let step = value.multipliedBy(100000).toFixed()
          let icx = value.multipliedBy(0.001).toFixed()
          this.setState({loop,gloop,step,icx})

        }
        break;
        default:
        case('icx'):
        {
          let loop = value.multipliedBy(1000000000000000000).toFixed()
          let gloop = value.multipliedBy(100000000000).toFixed()
          let step = value.multipliedBy(100000000).toFixed()
          let iscore = value.multipliedBy(1000).toFixed()
          this.setState({loop,gloop,step,iscore})
          break;
        }
      }
    }

render() {
    const { newDate } = this.state;
    let row='pt-4 pb-0 pr-4 pl-4'
    let row2='pt-0 pb-0 pr-2 pl-2'
    let col='col-sm-12 col-md-6'
    const apend = (title) => {
      return(
        <div className="input-group-append">
        <div className="groupinput input-group-text media-color pl-2 text-center">{title}</div>
        </div>
      )
    }

    const formcontainer = (form,title) => {
      return(
        <div className="input-group mt-2 mr-sm-2 mb-sm-0">{form}{apend(title)}</div>
        )
    }

    const title = (title) => {
      return (
        <Row className={row}>
          <div className="text-left"><h5>{title}</h5>
          </div>
        </Row>
      )
    }

return (
  <div className="Convert container">
      <Row className="mb-5 mt-3">
        <Col>
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <Row className="p-2">
                <Col>
                  <div className="d-flex align-items-center">
                  <h3 className="float-left">
                  <img
                       src={server}
                       className="pb-2"
                       width="35"
                       height="35"
                       alt={'server'}
                     />
                      Prep Tools</h3>
                  </div>
                </Col>
                <div className="container">
                  <Row className={row}>
                    <div className="text-left"><h5>Reward Calculator</h5>
                    </div>
                      {formcontainer(<input type="number" step="any" id='delegated' className="form-control text-color" onChange={this.onCalcInputChange.bind(this)} value={(this.state.loop)} />,'Votes')}
                      {formcontainer(<input type="number" step="any" id='daily' className="form-control text-color" onChange={this.onCalcInputChange.bind(this)} value={(this.state.gloop)} />,'Daily')}
                      {formcontainer(<input type="number" step="any" id='weekly' className="form-control text-color" onChange={this.onCalcInputChange.bind(this)} value={(this.state.step)} />,'Weekly')}
                      {formcontainer(<input type="number" step="any" id='monthly' className="form-control text-color" onChange={this.onCalcInputChange.bind(this)} value={(this.state.iscore)} />,'Monthly')}
                      {formcontainer(<input type="number" step="any" id='yearly' className="form-control text-color" onChange={this.onCalcInputChange.bind(this)} value={(this.state.icx)} />,'Yearly')}
                  </Row>
                  {title('Date Converter')}
                  <Row className={row2}>
                  <div className={col}>
                    {formcontainer(<input type="text" id='date' step="any" className="form-control text-color" onChange={this.onDateInputChange.bind(this)} value={(this.state.date)} />,'ICON Date')}
                    </div>
                    <div className={col}>
                    {formcontainer(<input type="text" id='todate' step="any" className="form-control text-color" disabled value={newDate} />,'Human Readable')}
                    </div>
                  </Row>
                  {title('Votes2Rewards')}
                  <Row className={row2}>
                  <div className={col}>
                    {formcontainer(<input type="text" id='hex' step="any" className="form-control text-color" onChange={this.onHexInputChange.bind(this)} value={(this.state.hex)} />,'Hexadecimal')}
                    </div>
                    <div className={col}>
                    {formcontainer(<input type="number" id='dec' step="any" className="form-control text-color" onChange={this.onHexInputChange.bind(this)} value={(this.state.dec)} />,'Decimal')}
                    </div>
                  </Row>
                  {title('Dec2Hex')}
                  <Row className={row2}>
                    <div className={col}>
                    {formcontainer(<input type="number" id='decc' step="any" className="form-control text-color" onChange={this.onHexInputChange.bind(this)} value={(this.state.dec)} />,'Decimal')}
                    </div>
                    <div className={col}>
                    {formcontainer(<input type="text" id='hexx' step="any" className="form-control text-color" onChange={this.onHexInputChange.bind(this)} value={(this.state.hex)} />,'Hexadecimal')}
                    </div>
                  </Row>
                </div>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
);
}
}

export default Convert;
