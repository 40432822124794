import React, { useState, useEffect, useRef } from 'react';
import Posts from './posts';
// import Blockposts from './../Blocks/posts';
import Pagination from './../Pagination';
import axios from 'axios';
import {Table} from 'react-bootstrap'
// import SquareLoader from 'react-spinners/BounceLoader';
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';

function useInterval(callback, delay) {
  const savedCallback = useRef();


  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Paginate = ({page, live, interval, preps, t}) => {
  const [posts, setPosts] = useState({listSize:0,blocks:[{"timestamp":1576849851877092}]});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(page === 0 || typeof(page) === 'undefined' || page === '' ? 1 : page);
  // const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  // const [leaders, setLeaders] = useState([]);
  const [delay] = useState(interval);
  let [count, setCount] = useState(0);
  // let [count, setCount] = useState(0);
  const lex = (w) => t(w, { framework: "react-i18next" })

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      // await (axios.get(`/data/thes`))
      // .then(mes => {
      //   setLeaders(mes.data);
      // })

      let link;
      link = 'https://api.iconwat.ch/transactions/?page='+currentPage+'&count='+postsPerPage

      const res = await axios.get(link);

      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage, postsPerPage]);

  useInterval(() => {
    const fetchPosts = async () => {
      // setLoading(true);

      let link;
      link = 'https://api.iconwat.ch/transactions/?page='+currentPage+'&count='+postsPerPage

      const res = await axios.get(link);

      setPosts(res.data);
      // setLoading(false);
    };
    if (live === true) fetchPosts();


    setCount(count + 1);
  }, delay, [live]);


  const currentPosts = posts.transactions


  const paginate = pageNumber => {setCurrentPage(pageNumber)
  window.scrollTo(0, 0)
};
  return loading
  ? wait()
  : ((typeof(posts.transactions) === 'undefined') || (posts.transactions === null))
  ? 'Nothing here yet!'
  : (

    <div>
    <Table className="breakTable text-left text-color" hover>
            <thead>
            <tr>
            <th colSpan="3">{lex('hash')}</th>
            <th colSpan="3" className="d-none d-lg-table-cell">{lex('status')}</th>
            <th colSpan="3" className={live ? 'd-none' : 'd-none d-lg-table-cell'}>{lex('time')}</th>
            <th colSpan="3" className="d-none d-lg-table-cell">{lex('block')}</th>
            <th colSpan="12">{lex('tx')}</th>
            <th colSpan="3">{lex('value')}</th>
            </tr>
            </thead>
            <tbody id="txs">
<Posts currentPage={currentPage} posts={currentPosts} loading={loading} leaders={preps} live={live} />
      </tbody>
    </Table>
    <div className={`pl-2 pb-0 mb-0 pt-2 ${(live===true) ? 'd-none' : ''}`}>
    <div className={posts.total < 50 ? 'd-none' : 'd-block' }>
    <Pagination
      currentPage={currentPage}
      postsPerPage={postsPerPage}
      totalPosts={posts.total}
      paginate={paginate}
    />
    </div>
    </div>
    </div>

  );
};

export default withTranslation('common')(Paginate);
