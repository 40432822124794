import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfinity } from "@fortawesome/free-solid-svg-icons/faInfinity";
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import axios from 'axios';
import block from './../assets/set/block.svg';
import blocks from './../assets/set/blocks.svg';
import cloud from './../assets/set/cloud.svg';
import dolar from './../assets/set/dolar.svg';
import wallet from './../assets/set/wallet.svg';
import staked from './../assets/set/staked.svg';
import delegated from './../assets/set/delegated.svg';
import voters from './../assets/set/vote2.svg';
import world from './../assets/set/blockvote.svg';
import node from './../assets/set/server.svg';
import mcap from './../assets/set/mcap.svg';
import tokens from './../assets/set/token.svg';
import smart from './../assets/set/smartcontracts.svg';
// import vol from './../assets/set/vol2.svg';
import { withTranslation } from 'react-i18next';
import {API} from './../utils/util'

class Pills extends Component {
_isMounted = false;
intervalID;

constructor(props) {
  super(props);
  this.state = {
    curr_supply: null,
    res:0,
    num:0,
    tokennum:0,
    unitname:'M',
    block:{confirmed_transaction_list:[]},
    prep:{}
  }
}

componentDidMount() {
  this._isMounted = true;
  this.getData();
  if (this.props.type !== 'gov' || this.props.type !== 'token') {
  this.intervalID = setInterval(this.getData.bind(this), this.props.type !== 'tx' ? 2500 : 2500);
  }
}

componentWillUnmount() {
  clearTimeout(this.intervalID);
  this._isMounted = false;
}

lex = (w) => this.props.t(w, { framework: "react-i18next" })


async getData() {
  if (this._isMounted) {
    let requestTwo = axios.get('./../data/data.json')
    // let requestThree = axios.post(API,'{"jsonrpc": "2.0","method": "icx_getLastBlock","id": 1234}')
    let requestFour = axios.get('https://api.iconwat.ch/blocks/?page=1&count=1')
if (this.props.type === 'tx') {

  let request = axios.get('https://api.iconwat.ch/transactions/?page=1&count=1')
await axios.all([request, requestFour, requestTwo])
.then(axios.spread((...responses) => {
const responseOne = responses[0]
const responseTwo = responses[1]
const responseThree = responses[2]
// const responseFour = responses[3]

this.setState({prep:responseOne.data,block:responseTwo.data.blocks[0],res:responseThree.data})

}))
}

if (this.props.type !== 'tx' || this.props.type !== 'gov') {

let requestOne = axios.post(API,'{"jsonrpc": "2.0","method": "icx_getTotalSupply","id": 1234}')
await axios.all([requestOne, requestTwo, requestFour])
.then(axios.spread((...responses) => {
  const responseOne = responses[0]
  const responseTwo = responses[1]
  const responseThree = responses[2]

  let supply = parseInt(responseOne.data.result.slice(2,50),16)/1000000000000000000

  let num = ((this.props.icxusd*this.props.mult)*(parseFloat(supply)-299411698))
this.commarize(num)
this.commarizeToken(responseTwo.data.tokenMarketCap*this.props.mult)
  // console.log(responseThree.data.blocks[0])
  this.setState(
    {block:responseThree.data.blocks[0],curr_supply:supply.toLocaleString(undefined, {maximumFractionDigits: 0}),res:responseTwo.data,
    isLoading: false}
  )


})).catch(errors => {
  // react on errors.
})
}

}
}

commarize (labelValue) {

  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

  ? this.setState({unitname:'B',num:Math.abs(Number(labelValue)) / 1.0e+9})
  // Six Zeroes for Millions 
  : Math.abs(Number(labelValue)) >= 1.0e+6

  ? this.setState({unitname:'M',num:Math.abs(Number(labelValue)) / 1.0e+6})
  // Three Zeroes for Thousands
  : Math.abs(Number(labelValue)) >= 1.0e+3

  ? this.setState({unitname:'K',num:Math.abs(Number(labelValue)) / 1.0e+3})

  : this.setState({unitname:'M',num:Math.abs(Number(labelValue))})

}

commarizeToken (labelValue) {

  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

  ? this.setState({tokenunitname:'B',tokennum:Math.abs(Number(labelValue)) / 1.0e+9})
  // Six Zeroes for Millions 
  : Math.abs(Number(labelValue)) >= 1.0e+6

  ? this.setState({tokenunitname:'M',tokennum:Math.abs(Number(labelValue)) / 1.0e+6})
  // Three Zeroes for Thousands
  : Math.abs(Number(labelValue)) >= 1.0e+3

  ? this.setState({tokenunitname:'K',tokennum:Math.abs(Number(labelValue)) / 1.0e+3})

  : this.setState({tokenunitname:'M',tokennum:Math.abs(Number(labelValue))})

}


renderPills(type, prep, blok) {

  const renderLinkIf = (content, link, href) => {
    if (typeof(link) !== 'undefined' && typeof(href) === 'undefined' ) {
      return (<Link to={link}>{content}</Link>);
    }
    if (typeof(href) !== 'undefined') {
      return (<a target="_blank" rel="noopener noreferrer" href={href}>{content}</a>);
    }
    return (content);
  };

  const numberify = (numbeer) => {
    return (parseInt(numbeer.slice(2,60),16)/1000000000000000000).toLocaleString(undefined, {maximumFractionDigits: 0})
  };

  const stake = {
    show: 'd-block',
    link: `/chart/iconomics`,
    img: staked,
    title: this.lex('staked'),
    value: numberify(this.props.preps.totalStake)
  }

  const delegate = {
    show: 'd-none d-lg-block',
    link: `/chart/iconomics`,
    img: delegated,
    title: this.lex('tdelegated'),
    value: numberify(this.props.preps.totalDelegated),
  }

  const reps = {
    show: 'd-none d-sm-block',
    img: node,
    title: this.lex('preps'),
    value: this.props.preps.preps.length
  }

  const votes = {
    show: 'd-none d-sm-block',
    img: world,
    title: this.lex('votes'),
    value: this.state.res.votes
  }

  const voter = {
    show: 'd-block',
    img: voters,
    title: this.lex('voters'),
    value: this.state.res.voters
  }

  // const price = {
  //   show: 'd-none d-lg-block',
  //   img: dolar,
  //   title: this.lex('price'),
  //   value: this.props.icxusd*this.props.mult, 
  //   priceChange: this.state.res.priceChangePercent
  // }

  const pricehome = {
  show: 'd-none d-sm-block',
  img: dolar,
  href: 'https://coinmarketcap.com/currencies/icon/',
  link: '',
  title: this.lex('marketcap'),
  // priceChange: this.state.res.priceChangePercent,
  value: this.state.num
  }

  const supply = {
    show: 'd-block',
    img: blocks,
    title: this.lex('supply'),
    value: this.state.curr_supply
  }

  const wallets = {
    show: 'd-none d-md-block',
    link: `/chart/addresses`,
    img: wallet,
    title: this.lex('wallets'),
    value: this.state.res.accounts
  }
  const gov = [stake, delegate, reps, votes, voter]

  const tx = [
    {
      show: 'd-block',
      link: `/block/${blok.height}`,
      img: block,
      title: this.lex('height'),
      value: blok.height
    },
    {
      show: 'd-block',
      img: voters,
      title: this.lex('transactions'),
      value: prep.total
    },
    {
      show: 'd-none d-lg-block',
      img: cloud,
      title: this.lex('tpb'),
      value: blok.txCount,
      plus: <FontAwesomeIcon icon={faInfinity} />
    },
    {
      show: 'd-none d-lg-block',
      img: node,
      link: `/address/${blok.producer}`,
      title: this.lex('leader'),
      extra: this.props.thes[blok.producer],
      value: ''
    }
  ]

const token = [
{
  show: 'd-none d-sm-block',
  // link: `/contracts`,
  img: smart,
  title: this.lex('contracts'),
  value: this.state.res.scores
},
{
  show: 'd-block',
  link: `/tokens`,
  img: tokens,
  title: this.lex('tokens'),
  value: this.state.res.tokens,
},
pricehome,
{
  show: 'd-block',
  img: mcap,
  title: this.lex('tokencap'),
  value: this.state.tokennum
}
]
const data = [{
  show: 'd-block',
  link: `/block/${blok.height}`,
  img: block,
  title: this.lex('height'),
  value: blok.height
},
{
  show: 'd-none d-lg-block',
  img: cloud,
  link: `/live`,
  title: this.lex('tpb'),
  value: blok.txCount,
  plus: <FontAwesomeIcon icon={faInfinity} />
},
wallets,
pricehome,
supply
]
  return (type === 'gov' ? gov : type === 'tx' ? tx : type === 'token' ? token : data).map((pill, index) => {
     const { link, img, title, value, plus, href, show, extra, priceChange } = pill //destructuring
     return (
       <div key={index} className={`card border-0 pr-3 pl-3 m-0 pt-3 pb-1 `+show}>
         {renderLinkIf(
         <div className="card-body card-block p-0 m-0">
         <img
             src={img}
             width="35"
             height="35"
             alt={title}
           />
           <h5 className="card-title m-0 font-weight-bold">
               <NumberFormat value={value} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={title === 'toke' ? '$' : ''} suffix={img === mcap ? this.state.tokenunitname : img === dolar? this.state.unitname : ''} />
               {!priceChange ? '' : <sup className={`change ${priceChange < 0 ? 'text-danger' : 'text-success'}`}>&nbsp;
               <NumberFormat value={priceChange} decimalScale={1} displayType={'text'} thousandSeparator={true} suffix={'%'} />
               </sup>}
               {typeof(plus) === 'undefined' ? '' : ' / '}{typeof(plus) === 'undefined' ? '' : plus}{typeof(extra) === 'undefined' ? '' : extra}
           </h5>
           </div>, link, href)}
           <div className="card-footer p-0 border-0 m-0">
           <h6 className="text-muted font-weight-vold">{title.toUpperCase()}</h6>
           </div>
        </div>
  )
  })
  }

render() {
return (
          <div className="card-group border-0 shadow-sm mt-3">
          {this.renderPills(this.props.type, this.state.prep, this.state.block)}
          </div>
);
}
}

// export default Pills;
export default withTranslation('common')(Pills);
