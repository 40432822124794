import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const DropMenu = ({ condition, content }) => {
  const [dropshow, setShow] = useState(false);

const onMouseEnter = () => {
    setShow(true);
  }

const onMouseLeave = () => {
    setShow(false);
  }

  const toggle = () => {
      dropshow === false ? setShow(true) : setShow(false)
    }

  return (
    <div>
    <Dropdown style={{ paddingBottom: 5, paddingLeft: 10}} className={condition} as={ButtonGroup} alignRight onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} show={dropshow} onClick={toggle}>
    <Dropdown.Toggle size="sm" variant="outline-white text-color"><FontAwesomeIcon icon={faEllipsisV} /></Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-nohover">
    {content}
     </Dropdown.Menu>
   </Dropdown>
   </div>
  );
};
export default DropMenu;
