import React, { Component } from 'react';
import {Row, Col} from 'react-bootstrap'
import Paginate from './paginate';
import Pills from './../Pills';
import {wait} from './../Clipboard/wait'
import smart from './../assets/set/smartcontracts.svg';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';

class Tokens extends Component {

        constructor(props) {
          super(props);
          this.state = {
            isLoading:true,
            contracts:true,
            preps:{totalStake:'',totalDelegated:'',preps:[]}
          }
          // this.handleClick = this.handleClick.bind(this);
        }
        lex = (w) => this.props.t(w, { framework: "react-i18next" })

componentDidMount() {
  this.setState({isLoading:true})
  let contracts = this.props.location.pathname === '/contracts'
  document.title = contracts ? 'ICON '+this.lex('contracts') : 'ICON '+this.lex('tokens');
  this.setState({isLoading:false, contracts})
}

componentDidUpdate(prevProps, prevState) {
if(prevProps.location.pathname!==this.props.location.pathname){
  this.setState({isLoading:true})
  let contracts = this.props.location.pathname === '/contracts'
  document.title = contracts ? 'ICON '+this.lex('contracts') : 'ICON '+this.lex('tokens');
    this.setState({isLoading:false,contracts})
}
}


render() {
  const {preps, isLoading} = this.state;
return isLoading
? wait()
: (
  <div className="Token container">
  <Pills type="token" thes={preps} preps={preps} mult={this.props.mult} icxusd={this.props.icxusd}/>

      <Row className="mt-3 mb-5">
        <Col>

        <div className="card border-0 shadow-sm">
          <div className="card-body">
            <Row>
                  <Col className="float-left text-left">

                        <h3 className="float-left">
                        <img
                             src={smart}
                             className="pb-2"
                             width="35"
                             height="35"
                             alt={'smart'}
                           /> ICON {this.props.location.pathname === '/contracts' ? this.lex('contracts') : this.lex('tokens')}</h3>

                          </Col>

                  </Row>
                  <Paginate page={this.props.match.params.currentPage} type={'tx'} preps={preps} tokens={true} mult={this.props.mult} icxusd={this.props.icxusd}/>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

);
}
}

export default withTranslation('common')(withRouter(Tokens));

