import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import {Row,Col,Table} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highmaps'
import { FlagIcon } from './../Clipboard/flag';
import axios from 'axios';
import {API} from './../utils/util'
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';

class NodeTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      chartOptions:[],
      worldMap:{},
      node:[{'hc-key':'WW',value:1}],
      nodes:{},
      leader:'',
      key: 1,
      preps:[]
    }
    this.scrollDiv = React.createRef();
  }

  lex = (w) => this.props.t(w, { framework: "react-i18next" })


  async componentDidMount() {

    document.title = this.lex('ICON Node Tracker');


    await this.load()
    await this.buildMap()
    await this.getStatus()
  }

  async load() {
    let requestOne = axios.post(API,'{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPReps"}}}');
    let requestTwo = axios.get(`/data/thes`)

    await axios.all([requestOne,requestTwo])
        .then(axios.spread((...responses) => {
        const responseOne = responses[0]
        const responseTwo = responses[1]

        this.setState({preps:responseOne.data.result.preps,nodes:responseTwo.data})

        }))

  }

  buildMap = async () => {
    const {preps} = this.state
    let worldMap;
    await axios.get(`/data/worldMap.json`).then(x=>worldMap=x.data)

    const res = preps.reduce((counts, item) => {
        if (counts[item.country] === undefined) counts[item.country] = 0;
        counts[item.country]++;
        return counts;
      }, {})
    var data = Object.entries(res).map(x=>[x[0],x[1]])
    this.setState({node:data})
      const mapOptions = {
        title: {
          text: 'P-Rep Locations'
        },
        colorAxis: {
          min: 0
        },
        mapNavigation: {
            enabled: false,
        },
        zoomType: 'x',
        credits:{enabled:false},
        series: [
          {
            data: data,
            mapData: worldMap,
            name: 'Country',
            joinBy: ['iso-a3', 'hc-key'],
            allowPointSelect: true,
            cursor: 'pointer',
            states: {
                hover: {
                    color: '#BADA55'
                }
            },
            
            
          }
        ]
      };
      this.setState({chartOptions:mapOptions})
  }

async getStatus() {
    await axios.get('https://api.iconwat.ch/blocks/?page=1&count=1')
    .then(res => {
      this.setState({leader:res.data.blocks[0].producer,isLoading: false})
    })
  }
tbody=(t0,arr)=>{

  return <tbody>
    {arr.map((x,i)=>(
                <tr>
                <th className={i === 0 ? t0 : ''}>{x[0]}:</th>
                <td className={i === 0 ? t0 : ''}>{x[1]}</td>
                </tr>
    ))}

  </tbody>
}

  extraData(t0, lor) {
    const { isLoading } = this.state;

    return isLoading
    ? wait()
    : (
      <div className="container card border-0">
      <Row>
        <Col>
        <HighchartsReact
            options = { this.state.chartOptions }
            highcharts = { Highcharts }
            constructorType = { 'mapChart' }
            allowChartUpdate = { true }
            immutable = { false }
            updateArgs = { [true, true, true] }
            containerProps = {{ className: 'chartContainer' }}
            callback = { this.chartCallback }
        />
        </Col>
      </Row>
      </div>
    )
  }

  topTable(t0, lor){
    const { isLoading, leader, preps, node, nodes } = this.state;
    let topCountry = (node.sort((x,y)=>x.value-y.value)[node.length-1]["hc-key"])

    return isLoading
    ? wait()
    : (

    <Row>
      <Col md={6} className="overflow-auto">
        <Table className="text-color text-left">
          {this.tbody(t0,[[this.lex('Nodes'),preps.length],[this.lex('Main P-Reps'),preps.filter(x=>x.grade==='0x0').length],[this.lex('Sub P-Reps'),preps.length - preps.filter(x=>x.grade==='0x0').length]])}
        </Table>
      </Col>
      <Col md={6}>
      <Table className="text-color text-left">
        {this.tbody(t0,[[this.lex('leader'),(<Jazzicon diameter={16} seed={typeof(leader) === 'undefined' ? 0 : jsNumberForAddress(leader)} />,<Link to={`/address/${leader}`}>{nodes[leader]}</Link>)],[this.lex('Most Nodes'),(<FlagIcon className="mr-1" code={topCountry !== '' && typeof(topCountry) !== 'undefined' ? topCountry : 'WW'} size={20} />,topCountry )],['Pre-Registered',87]])}
    </Table>
  </Col>
</Row>
    )
  }

  bottomTable=()=>{
    const { isLoading, node, preps } = this.state;
    let total = preps.length
    let countries = node.filter(x=>x.value!==null).sort((x,y)=>x.value-y.value).reverse()
    return isLoading
    ? wait()
    : (<div><table className="table text-color text-left"><thead><tr><th>Country</th><th>Nodes</th><th>Main</th><th>Sub</th><th>Percentage</th></tr></thead><tbody>

    {countries.map((x,i)=><tr key={i}><td><div className="d-flex d-flex align-items-center"><FlagIcon className="mr-1" code={x["hc-key"] !== '' && typeof(x['hc-key']) !== 'undefined' ? x['hc-key'] : 'WW'} size={20} />{x['hc-key']}</div></td><td>{x.value}</td><td>{(preps.filter(y=>y.country === x['hc-key'] && y.grade === '0x0').length)}</td><td>{(preps.filter(y=>y.country === x['hc-key'] && y.grade !== '0x0').length)}</td><td>{(x.value*100/total).toFixed(2)+'%'}</td></tr>)




        }
        
        
        </tbody></table>    
    </div>
    )

  }


  render() {
    const { isLoading } = this.state;
    let t0 = 'border-top-0'
    let lor = 'text-color'

    return isLoading
    ? wait()
    : (
<div className="Block mt-3">
      <div className="container">
      <div className="card text-color border-0 shadow-sm">
     	 <div className="card-body pb-0 rounded">
     		 <div className="container">
     			 <Row>
              <div className="grey-border mb-3 col-md-12 text-left">
                  <h4 className="display-5">{this.lex('ICON Node Tracker')}</h4>
                                        <h6 className="d-none d-md-inline text-secondary">The ICON Node Tracker shows stats for all the nodes on the ICON Network.</h6>

              </div>
            </Row>
            {this.topTable(t0,lor)}

      </div>
    </div>
  </div>

  <div ref={this.scrollDiv} className="card mt-3 text-color border-0 p-4 shadow-sm">
{this.extraData(t0,lor)}
        </div>
        <div ref={this.scrollDiv} className="card mt-3 text-color border-0 p-4 shadow-sm">
        {this.bottomTable()}
        </div>
        </div>
        </div>
  );
  }
}
export default withTranslation('common')(withRouter(NodeTracker));
