import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import common_en from "./locale/en/common.json";
import common_ko from "./locale/ko/common.json";
import common_cn from "./locale/cn/common.json";
import {I18nextProvider} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { disableReactDevTools } from './components/utils/disabledev';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

i18next.use(LanguageDetector).init({
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		},
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        zh: {
            common: common_cn               // 'common' is our custom namespace
        },
        ko: {
            common: common_ko               // 'common' is our custom namespace
        }
    },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
      <App/>
  </I18nextProvider>

  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
