export const thes = () => './../data/thes';
export const media = () =>  './../data/media.json';


export const internalTxList = (address,n,p) => 'https://tracker.icon.foundation/v3/address/internalTxList?page='+p+'&count='+n+'&address='+address;
export const tokenTxList = (address,n,p) => 'https://tracker.icon.foundation/v3/address/tokenTxList?page='+p+'&count='+n+'&address='+address;
export const claimIScoreList = (address,n,p) => 'https://tracker.icon.foundation/v3/address/claimIScoreList?page='+p+'&count='+n+'&address='+address;
export const info = (address) => 'https://tracker.icon.foundation/v3/address/info?address='+address;
export const peer = () => 'http://44.228.193.64:9000/admin/chain/0x1';

//contracts

export const ctrinternalTxList = (address,n,p) =>  'https://tracker.icon.foundation/v3/contract/internalTxList?page='+p+'&count='+n+'&addr='+address;
export const ctrtokenTxList = (address,n,p) =>  'https://tracker.icon.foundation/v3/contract/tokenTxList?page='+p+'&count='+n+'&addr='+address;