import React from 'react';
import BigNumber from 'bignumber.js'
import logo from '.././assets/wait.svg'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import {Link} from 'react-router-dom'



export function wait(isLoading) {
  return <div className="p-5">
  <img
      className="rotate"
      src={logo}
      width="75"
      height="75"
      alt='wait'
    />
    {/*<br/>
    <h2 className="text-color m-2">Loading...</h2>*/}
  </div>;
}

export function simple(n) {
  return BigNumber(n).toFixed();
}

export function numberFormat(n,d) {
  let dec = parseInt(d)
  return BigNumber(n.slice(2,60),16).multipliedBy(1/(dec < 2 ? 1 : 10**dec)).toFixed();
}

export function apiFormat(n) {
  return BigNumber(n.slice(2,60),16).toFixed();
}

export function tomApi(n,d) {
  let dec = parseInt(d)
  return BigNumber(n).multipliedBy(1/(dec < 2 ? 1 : 10**dec)).toFixed();
}

export function renderVoteTab(votes,preps) {
    return (votes.length === 0)
    ? wait()
    : (
         <div className="container pb-5">
           <div className="row" >
           Voting for a total of
           <NumberFormat className="font-weight-bold" decimalScale='2' value={votes.length} displayType={'text'} thousandSeparator={true} prefix='&nbsp;' suffix='&nbsp;'/>
           P-Rep{votes.length < 2 ? '' : 's'}
           </div>
           <div className="row pt-3">
           {renderNewVoteTable(votes,preps)}
           </div>
         </div>
)
}

function renderNewVoteTable(votes, preps) {
	return (votes.length === 0)
	? wait()
	: votes.map((vote, index) => {
		 const { address, value } = vote //destructuring
		 return (
			 <div className="col-12 col-sm-4 col-lg-3 p-1" key={index}>
			 <div className="card p-1 border-0 shadow-sm media-bg">
					<div className="d-flex align-items-center grey-border justify-content-center p-1">
					<h6 className="media border-top-0 p-1 m-0"><Jazzicon diameter={18} seed={typeof(address) === 'undefined' ? 0 : jsNumberForAddress(address)} />&nbsp;
					<Link to={`/address/${address}`}>
					{(typeof(preps[address]) === 'undefined') ? address.slice(0,12) : preps[address] }
				 </Link>
				 </h6>
				 </div>
				 <div className="card-body p-1 media-bg">
				 <p className="card-text">Votes: <NumberFormat decimalScale='2' value={numberFormat(value,18)} displayType={'text'} thousandSeparator={true} suffix={''}/></p>
				 </div>
			 </div>
			 </div>

)}
)
}
export const NETWORK_REF_MAINNET = 'mainnet';
export const NETWORK_REF_TESTNET = 'testnet';

export function getNetwork(networkRef) {
  switch (networkRef) {
    case NETWORK_REF_MAINNET:
      return {
        nid: 1,
        ref: NETWORK_REF_MAINNET,
        apiEndpoint: 'https://ctz.solidwallet.io/api/v3',
        trackerUrl: 'https://tracker.icon.foundation',
      };
    case NETWORK_REF_TESTNET:
    default:
      return {
        nid: 80,
        ref: NETWORK_REF_TESTNET,
        apiEndpoint: 'https://zicon.net.solidwallet.io/api/v3',
        trackerUrl: 'https://zicon.tracker.solidwallet.io',
      };
  }
}
