import React, { Component } from 'react';
import {wait} from './../Clipboard/wait'
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCodeBranch, faDice, faGamepad, faMusic } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import axios from 'axios'
library.add(faDice,faGamepad, faMusic)

class Dapps extends Component {

        constructor(props) {
          super(props);
          this.state = {
            dapps:[]
          }
        }

componentDidMount() {
  this.setState({ isLoading: true });
  document.title = 'ICON dApps';

  axios.get('./data/dapps')
  .then(res => {this.setState({dapps:res.data.data})
  this.setState({ isLoading: false })
})

}

renderDapps = () => {
const {dapps} = this.state
return dapps.map((dapp, index) => {
const {img, url, name, ctr, media, desc, type} = dapp
return (

    <div key={index} className="col-sm-12 col-md-6 col-lg-4 pt-0 pr-2 pl-2 pb-0 mb-0">
      <div className="dapp shadow-sm p-0 mb-0">
      <a target="_blank" rel="noopener noreferrer" href={url}>
      <img src={`/img/dapps/${img}`} alt={name} />
      </a>
        <div className="head">
          <div className="name">
          {name}
          </div>
          <div className="link">
          <FontAwesomeIcon icon={['fa', type]} />
          </div>
        </div>
        <div className="desc grey-border text-left">
        {desc}
        </div>
        <div className="desc pt-3">
        <Link to={`/address/${ctr}`} className="text-seconday p-1">
        <FontAwesomeIcon icon={faCodeBranch} /> Contract
        </Link>
        <a target="_blank" rel="noopener noreferrer" href={media.telegram} className="text-seconday p-1">
        <FontAwesomeIcon icon={faTelegram} /> Telegram
        </a>
        <a target="_blank" rel="noopener noreferrer" href={media.twitter} className="text-seconday p-1">
        <FontAwesomeIcon icon={faTwitter} /> Twitter
        </a>
        </div>
      </div>
      </div>
)})
}

render() {
  const { isLoading } = this.state;
  return isLoading
    ? wait()
    : (
        <div className="Dapps">
          <div className="top-section">
          <h1 className="text-white font-weight-bold">
            Popular ICON dApps
          </h1>
          </div>
          <div className="container">
            <div className="d-flex flex-wrap">
              {this.renderDapps()}
            </div>
          </div>
        </div>
);
}
}

export default Dapps;
