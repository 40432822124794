import React, { Component } from 'react';
import {withRouter, Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import {Container,Row,Table, Tabs, Tab, Badge,Tooltip,OverlayTrigger} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import ReactJson from 'react-json-view'
// import Receipt from './../Receipt'
import axios from 'axios'
import ClipBoard from './../Clipboard';
import {wait, numberFormat, apiFormat, renderVoteTab} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';
import {API} from './../utils/util'
import {currSymbol} from './../utils/funcs'

class Transaction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			failure: 0,
			icxusd:0,
			icxusdcur:0,
			multcur:1,
			icxusdhis:0,
			multhis:1,
			dataType:0,
			historical: false,
			tx: {blockHeight:''},
			hash:'',
			preps:{},
			rawData:{data:'No Data.'},
			result:{eventLogs:[]},
			txdata:[],
			splitlogs:[],
			key:1,
			votes:[]
			}
			this.scrollDiv = React.createRef();
		}
componentDidMount() {
	let mult = this.props.mult
	let icxusd = this.props.icxusd
	this.setState({	icxusdcur:icxusd,multcur:mult})

	this.setState({isLoading: true})
	if (this.props.match.params.txHash.length !== 66) {this.props.history.push('/404')}
	switch (this.props.location.hash) {
		case ('#details'):
		this.setState({ key:1,hash:'#details' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		case ('#raw'):
		this.setState({ key:5,hash:'#raw' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		case ('#votes'):
		this.setState({key:4,hash:'#votes' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		case ('#events'):
		this.setState({key:3,hash:'#events' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		case ('#transactions'):
		this.setState({key:2,hash:'#transactions' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		default:
		this.setState({key:1})

	}
		document.title = 'ICON '+ this.lex('tx')+': '+this.props.match.params.txHash;

	var tx_hash = this.props.match.params.txHash;
	this.getTxState(tx_hash);
}

lex = (w) => this.props.t(w, { framework: "react-i18next" })

static getDerivedStateFromProps(nextProps, prevState){

 if(nextProps.match.params.txHash!==prevState.txHash){
	 return { title:nextProps.match.params.txHash,txHash: nextProps.match.params.txHash};
} else if (nextProps.location.hash!==prevState.hash) {

	return { title:nextProps.match.params.txHash,blockHash: nextProps.match.params.txHash};
}
else return null;
}

async componentDidUpdate(prevProps, prevState) {

	if(prevProps.mult !== this.props.mult) {
		let {historical,tx} = this.state
		let icxusd = this.props.icxusd
		let mult = this.props.mult
		let currency = this.props.currency
		let now = +new Date()
		const oneDay = 60 * 60 * 39 * 1000
		if (historical && (typeof(tx.timestamp) !== 'undefined') && ((now - (tx.timestamp/1000)) > oneDay) ) {
			let today = new Date((tx.timestamp/1000))
			let to = today.toISOString().slice(0,10)
			let link = 'https://api.iconwat.ch/daily/?from='+to+'&to='+to
			axios.get(link)
			.then(res => {
				let price = typeof(res.data.data[0]) !== 'undefined' ? res.data.data[0].priceClose : 0
				const multiplier = res.data.data[0].forex[currency];
				// console.log(price)

				this.setState({icxusd:price*multiplier})
	
			})
			} else {
				this.setState({icxusd:icxusd*mult})
			}
	}

	if(prevProps.match.params.txHash!==this.props.match.params.txHash){
		if (this.props.match.params.txHash.length !== 66) {this.props.history.push('/404')}
		let tx_hash=this.props.match.params.txHash
		this.props.location.hash === '#transactions' ? this.setState({ key:2, hash:'#transactions' }) : this.props.location.hash === '#votes' ? this.setState({ key:4, hash:'#votes' }) : this.props.location.hash === '#raw' ? this.setState({ key:5, hash:'#raw' }) : this.props.location.hash === '#events' ? this.setState({ key:3, hash:'#events' }) : this.setState({ key:1 });
		this.setState({isLoading: true});
		document.title = 'ICON '+ this.lex('tx')+': '+this.props.match.params.txHash;
		this.getTxState(tx_hash);
	}
  if(prevProps.location.hash!==this.props.location.hash){
    this.props.location.hash === '#transactions' ? this.setState({ key:2, hash:'#transactions' }) : this.props.location.hash === '#votes' ? this.setState({ key:4, hash:'#votes' }) : this.props.location.hash === '#raw' ? this.setState({ key:5, hash:'#raw' }) : this.props.location.hash === '#events' ? this.setState({ key:3, hash:'#events' }) : this.setState({ key:1 });
  }
	
	
}

async getTxState(tx_hash) {

	let currTxObj,txStatus,curr_block_no,preps;


	let call1 = '{"jsonrpc":"2.0","id":1577509156742,"method":"icx_getTransactionByHash","params":{"txHash":"'+tx_hash+'"}}'
	let call2 = '{"jsonrpc":"2.0","id":1577509156900,"method":"icx_getTransactionResult","params":{"txHash":"'+tx_hash+'"}}'
	let call3 = '{"jsonrpc":"2.0","id":1577509157053,"method":"icx_getLastBlock"}';

	let firstCall = axios.post(API,call1)
	let secondCall = axios.post(API,call2)
	let thirdCall = axios.post(API,call3)
	let fourthCall = (axios.get(`/data/thes`))

	await axios.all([firstCall, secondCall, thirdCall, fourthCall]).then(axios.spread((...responses) => {
		const responseOne = responses[0]
		const responseTwo = responses[1]
		const responseThree = responses[2]
		const responseFour = responses[3]

		currTxObj = responseOne.data.result
		txStatus = responseTwo.data.result
		curr_block_no = responseThree.data.result.height
		preps = responseFour.data

		this.setState({preps:preps})

	})).catch(errors => {
	  // react on errors.
		currTxObj = {}
		currTxObj.timestamp = '0x59b43f5b2a671';
		currTxObj.blockHeight = '0x0'
		txStatus = {}
		txStatus.stepUsed = '0x0'
		votes = []
	})

let now = +new Date()
const oneDay = 60 * 60 * 39 * 1000
let {currency} = this.props

if ((typeof(currTxObj.timestamp) !== 'undefined') && ((now - (currTxObj.timestamp/1000)) > oneDay) ) {
let today = new Date((currTxObj.timestamp/1000))
let to = today.toISOString().slice(0,10)
let link = 'https://api.iconwat.ch/daily/?from='+to+'&to='+to
await (axios.get(link))
.then(res => {
	// let price = res.data.data[0].priceClose;
	let price = typeof(res.data.data[0]) !== 'undefined' ? res.data.data[0].priceClose : 0
	const multiplier = typeof(res.data.data[0]) !== 'undefined' ? res.data.data[0].forex[currency] : 1
	this.setState({icxusdhis:price,multhis:multiplier,icxusdcur:this.props.icxusd,multcur:this.props.mult,icxusd:this.props.icxusd*this.props.mult})
})
} else {
	this.setState({icxusdhis:this.props.icxusd,multhis:this.props.mult,icxusdcur:this.props.icxusd,multcur:this.props.mult,icxusd:this.props.icxusd*this.props.mult})
}

let dataObj = 'No data!',
value = 0,
failure = 0,
confirmations = 0,
nid = 0,
nonce = 0,
txindex = 0,
steplimit = 0,
dataType = 'transfer',
stepsused = 0,
fromcx = 'address',
tocx = 'address',
txfee = 0,
signature = 'No signature',
status = 'FAILED',
status2 = 'CONFIRMED',
badge = 'secondary',
badge2 = 'secondary',
version = 0,
votes = []

let splitlogs;

if(typeof(currTxObj.data) !== 'undefined') {

if (currTxObj.data.method === 'setDelegation'){
	votes = currTxObj.data.params.delegations
}}
confirmations = curr_block_no - apiFormat(currTxObj.blockHeight);

if (typeof txStatus.stepUsed !== 'undefined') {
	stepsused = apiFormat(txStatus.stepUsed);
} else {
	stepsused = 0;
}

if (typeof txStatus.failure !== 'undefined') {
	failure = txStatus.failure.message;
} else {
	failure = 0;
}


if (typeof txStatus.eventLogs !== 'undefined') {
	if (txStatus.eventLogs.length !== 0) {
	splitlogs = txStatus.eventLogs.filter(x => x.indexed[0] === 'ICXTransfer(Address,Address,int)')
} else {
	splitlogs = [];

}
} else {
	splitlogs = [];

}

if (typeof txStatus.stepPrice !== 'undefined')  {
txfee = (stepsused) * numberFormat(txStatus.stepPrice,18);
} else {
txfee = 0;
}

if (txStatus.status !== '0x1') {
	status = this.lex('failed');
	status2 = this.lex('confirmed');
	badge = 'danger'
	badge2 = 'secondary'
} else {
	status = this.lex('success');
	status2 = this.lex('confirmed');
	badge = 'success'
	badge2 = 'secondary'
}

if (typeof currTxObj.txIndex !== 'undefined') {
	txindex = apiFormat(currTxObj.txIndex);
} else {
	txindex = '0';
}

if (typeof currTxObj.signature !== 'undefined') {
	signature = currTxObj.signature;
} else {
	signature = 'No signature';
}

if (typeof currTxObj.dataType !== 'undefined') {
	dataType = currTxObj.dataType;
} else {
	dataType = 0;
}
		fromcx = "address";
		tocx = "address";

let raw ={}
raw.icx_getTransactionByHash = currTxObj;
raw.icx_getTransactionResult = txStatus;

if (typeof currTxObj.value !== 'undefined') {
	value = numberFormat(currTxObj.value,18)
} else {
	value = 0;
}

if (typeof currTxObj.stepLimit !== 'undefined') {
	steplimit = apiFormat(currTxObj.stepLimit)
} else {
	steplimit = 0;
}

if (typeof currTxObj.data !== 'undefined') {
	dataObj = JSON.stringify(currTxObj.data);
} else {
	dataObj = 'No data';
}
if (typeof currTxObj.nid === 'undefined') {
	nid = 0;
} else {
	nid = apiFormat(currTxObj.nid);
}

if (typeof currTxObj.nonce !== 'undefined') {
	nonce = apiFormat(currTxObj.nonce);
} else {
	nonce = 0;
}


if (typeof currTxObj.version !== 'undefined') {
	version = apiFormat(currTxObj.version);
} else {
	version = 0;
}

		this.setState({
		votes:votes,
		splitlogs:splitlogs,
		result:txStatus,
		 data: dataObj,
		 failure:failure,
		 confirmations: confirmations,
		 status: status,
		 status2: status2,
		 rawData:raw,
		 txindex:txindex,
		 stepsused:stepsused,
		 dataType: dataType,
		 version: version,
		 tocx: tocx,
		 fromcx: fromcx,
		 signature: signature,
		 badge:badge,
		 badge2:badge2,
		 value: value,
		 steplimit: steplimit,
		 nid: nid,
		 txfee:txfee,
		 nonce: nonce,
		 tx_ts: (currTxObj.timestamp/ 1000000),
		 tx: currTxObj,
		 tx_hash: currTxObj.blockHash,
		 isLoading: false
	 })
}

changePrice = () => {
	let {historical,tx} = this.state
	let icxusdcur = this.props.icxusd
	let multcur = this.props.mult
	let {currency} = this.props
	let now = +new Date()
	const oneDay = 60 * 60 * 39 * 1000

	if (!historical && (typeof(tx.timestamp) !== 'undefined') && ((now - (tx.timestamp/1000)) > oneDay) ) {
		let today = new Date((tx.timestamp/1000))
		let to = today.toISOString().slice(0,10)
		let link = 'https://api.iconwat.ch/daily/?from='+to+'&to='+to
		axios.get(link)
		.then(res => {
			let price = res.data.data[0].priceClose;
			const multiplier = res.data.data[0].forex[currency];
			if (typeof(price))
			// this.setState({icxusdhis:price,multhis:multiplier,icxusdcur:this.props.icxusd,multcur:this.props.mult,icxusd:this.props.icxusd*this.props.mult})

			this.setState({historical:true,icxusd:price*multiplier})

		})
		} else {
			this.setState({historical:false,icxusd:icxusdcur*multcur})
		}





  }


async getTx(curr_block_no) {
	const tx_hashes = this.state.tx_hashes.slice();
	var max_blocks = 10;


	for (var i = 0; i < max_blocks; i++, curr_block_no--) {
		tx_hashes.push(this.state.tx.txHash);
	}
	this.setState({
		tx_hashes: tx_hashes
	})
}

topTable() {
	const { isLoading,preps, historical, tx } = this.state;
	return isLoading
	? wait()
	: (
	<Row>
		<div className="col-md-6">
			<table className="table text-color text-left">
				<tbody>
					<tr>
						<th className="border-top-0">{this.lex('height')+':'}</th>
						<td className="border-top-0">
						 <Link to={`../block/${apiFormat(tx.blockHeight)}`}><NumberFormat value={apiFormat(tx.blockHeight)} displayType={'text'} thousandSeparator={true} prefix={''}/></Link>
						</td>
					</tr>
					<tr>
						<th>{this.lex('time')+':'}</th>
						<td>
							<Moment format="YYYY/MM/DD HH:mm:ss" unix="unix">{this.state.tx_ts}</Moment>
						</td>
					</tr>
					<tr>
						<th>{this.lex('value')+':'}</th>
						<td>
							<div className="d-flex align-items-center flex-wrap">
							<div className="align-self-stretch">
							<NumberFormat value={this.state.value} decimalScale='6' displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
							&nbsp;
							</div>
							<div className="align-self-stretch">
							<OverlayTrigger placement="right" overlay={
							<Tooltip id="tooltip-left">{historical ? this.lex('historical') : this.lex('current')} {this.lex('price')+':'}<br/>
							<NumberFormat decimalScale='4' value={this.state.icxusd} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} suffix={'/ICX'}/>
							</Tooltip>}>
							<Badge onClick={this.changePrice} className='media-bg text-color pointer p-1'>
							<NumberFormat decimalScale='2' value={((this.state.value)*(this.state.icxusd))} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} suffix={''}/>
							</Badge>
							</OverlayTrigger>
							</div>
							</div>
						 </td>
					</tr>
					<tr>
						<th>{this.lex('status')+':'}</th>
						<td>
						<Badge className='p-1 mr-1 badge-width d-none d-md-inline-block text-uppercase' variant={this.state.badge2}>{this.state.status2}</Badge>
						 <Badge className='p-1 badge-width text-uppercase' variant={this.state.badge}>{this.state.status}</Badge>
						</td>
					</tr>
					<tr className={(this.state.failure === 0 ? 'd-none' : 'd-table-row')}>
						<th>{this.lex('failure')+':'}</th>
						<td>
						<code className="text-danger">{this.state.failure}</code>
						</td>
					</tr>
			 </tbody>
			</table>
		</div>
<div className="col-md-6">
<div className="table">
<table className="table text-color text-left">
<tbody>
<tr>
<th className="border-top-0">{this.lex('fee')+':'}</th>
<td className="border-top-0">
<div className="d-flex align-items-center flex-wrap">
<div className="align-self-stretch">
<NumberFormat value={this.state.txfee} decimalScale='6' displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
&nbsp;
</div>
<div className="align-self-stretch">
<OverlayTrigger placement="right" overlay={
<Tooltip id="tooltip-left">{historical ? this.lex('historical') : this.lex('current')} {this.lex('price')+':'}<br/>
<NumberFormat decimalScale='4' value={this.state.icxusd} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} suffix={'/ICX'}/>
</Tooltip>}>
<Badge onClick={this.changePrice} className='media-bg text-color pointer p-1'>
<NumberFormat decimalScale='2' value={((this.state.txfee)*(this.state.icxusd))} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} suffix={''}/>
</Badge>
</OverlayTrigger>
</div>
</div>



</td>
</tr>
<tr>
<th>{this.lex('from')+':'}</th>
<td>
{(typeof(this.state.tx.from) === 'undefined') ? this.lex('syscall') : (
<Link to={`../${this.state.fromcx}/${this.state.tx.from}`}>{typeof(preps[this.state.tx.from]) !== 'undefined' ? (preps[this.state.tx.from]) : this.state.tx.from}</Link>
)}
</td>
</tr>
<tr>
<th>{this.lex('to')+':'}</th>
<td>
{(typeof(this.state.tx.to) === 'undefined') ? this.lex('syscall') : (
<Link to={`../${this.state.tocx}/${this.state.tx.to}`}>{typeof(preps[this.state.tx.to]) !== 'undefined' ? (preps[this.state.tx.to]) : this.state.tx.to}</Link>
)}
</td>
</tr>
<tr className={this.state.dataType === 0 ? 'd-none' : 'd-table-row'}>
<th>{this.lex('type')+':'}</th>
<td><code className="text-break text-color">{this.state.dataType}</code></td>
</tr>
</tbody>
</table>
</div>
</div>
</Row>


)
}

renderLogs(txlogs) {
	const { isLoading, preps } = this.state;
	let col='col-md-2 col-lg-1 p-2 text-md-right '
	let row='row align-items-baseline mb-3 '
	let head='col-md-10 mb-0 '

	function renderIndexed(logs) {
		return logs.map((log, index) => {
			return(
			<li key={index}><Badge className='media-bg text-color p-2 m-1'>{index}</Badge>&nbsp;<code className="text-break text-color">{log}</code></li>
	)})
}

return isLoading
? wait()
: txlogs.map((tx, index) => {
		 const { scoreAddress, indexed, data } = tx
		 return (
			 <div key={index}  className="overflow-auto media">
				 <div className="media-body">
					 <dl className={row}>
					 <dt className={col}>
					 <h6 className="mb-0">{this.lex('address')}</h6>
					 </dt>
					 <dd className={`${head} text-truncate`}>
					 <div className="d-flex align-items-center ml-1">
					 <Jazzicon diameter={20} seed={typeof(scoreAddress) === 'undefined' ? 0 : jsNumberForAddress(scoreAddress)} />
					 &nbsp;
					 <Link to={typeof(scoreAddress) === 'undefined' ? '/' : '/address/'+scoreAddress }>{typeof(preps[scoreAddress]) !== 'undefined' ? (preps[scoreAddress]) : scoreAddress}</Link>
					 </div>
					</dd>
					</dl>
					<dl className={row}>
					<dt className={col}>
					<h6 className="mb-0">{this.lex('action')}</h6></dt>
					<dd className={head}>
					<ul className="list-unstyled mb-0">
					{renderIndexed(indexed)}
					 </ul>
					 </dd>
					 </dl>
					 <dl className={`align-items-baseline mb-0 mt-0 ${(data.length === 0 ? 'd-none' : 'row')}`}>
					 <dt className={col}>
					 <h6 className="mb-0">{this.lex('data')}</h6>
					 </dt><dd className={head}>
					 <ul className="list-unstyled mb-0">
					 {renderIndexed(data)}
					 </ul></dd>
					 </dl>
				</div>
			</div>

		 )
	 })
}

renderTransfers(txlogs) {
	const splitlogs = txlogs.filter(x => x.indexed[0] === 'ICXTransfer(Address,Address,int)')
	let col='col-special text-left text-truncate'
	let spec='d-flex align-items-center border-top-0 p-0'

	return splitlogs.map((tx, index) => {
		 const { scoreAddress } = tx
		 return (
			 <tr key={index}>
			 <td colSpan="2" className={`d-none d-md-table-cell ${col}`}>
			 <div className={spec}>
			 <Badge variant='primary' className='badge-width p-1 text-uppercase'>
			 {this.lex('transfer')}
			 </Badge>
			 </div>
			 </td>
			 <td colSpan="2" className={col}>
			 <div className={spec}>
			 <Jazzicon diameter={18} seed={typeof(scoreAddress) === 'undefined' ? 0 : jsNumberForAddress(scoreAddress)} />
			 &nbsp;
			 <Link to={typeof(scoreAddress) === 'undefined' ? '/' : '/address/'+scoreAddress }>
			 {scoreAddress.slice(0,12)+'...'}
			 </Link>
			 </div>
			 </td>
			 <td colSpan="2" className={col}>
			 <div className={spec}>
			 <Jazzicon diameter={18} seed={typeof(tx.indexed[2]) === 'undefined' ? 0 : (tx.indexed[2].slice(0,2)) === 'hx' || (tx.indexed[2].slice(0,2)) === 'cx'  ? jsNumberForAddress(tx.indexed[2]) : 0 } />
			 &nbsp;
			 <Link to={typeof(tx.indexed[2]) === 'undefined' ? '/' : (tx.indexed[2].slice(0,2)) === 'hx' || (tx.indexed[2].slice(0,2)) === 'cx' ? '/address/'+tx.indexed[2] : '/' }>
			 {tx.indexed[2].slice(0,12)+'...'}
			 </Link>
			 </div>
			 </td>
			 <td colSpan="2" className={col}>
			 <div className={spec}>
			 <Badge className='text-color pointer p-1 prime-badge media-bg'>
			 <NumberFormat decimalScale='4' value={(typeof(parseInt((tx.indexed[3].slice(2,40)),16)/1000000000000000000)) === 'undefined' ? 0 : numberFormat(tx.indexed[3],18)} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
			 </Badge>
			 </div>
			 </td>
			 </tr>
		 )
	 })
}


renderTxhead(txlogs) {
	let top='border-top-0'

	return (
	<Table className='text-color text-left' size='md' hover>
			<thead>
				<tr>
				<th colSpan="2" className={`d-none d-md-table-cell ${top}`}>{this.lex('type')}</th>
					<th colSpan="2" className={top}>{this.lex('from')}</th>
					<th colSpan="2" className={top}>{this.lex('to')}</th>
					<th colSpan="2" className={top}>{this.lex('value')}</th>
				</tr>
			</thead>
			<tbody>
				{this.renderTransfers(txlogs)}
			</tbody>
		</Table>
)}

handleClick = (e) => {
	this.setState({key:e})
	switch (e) {
		case '1':
		default:
			return this.props.history.push("#details")
		case '2':
			return this.props.history.push("#transactions")
		case '3':
			return this.props.history.push("#events")
		case '4':
			return this.props.history.push("#votes")
		case '5':
			return this.props.history.push("#raw")
	}
}


extraData() {
	const { isLoading, result,splitlogs,preps,votes } = this.state;
	let b0='border-top-0'

	return isLoading
	? wait()
	: (
		<Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.handleClick(k)} transition={false}>
		<Tab mountOnEnter={true} eventKey="1" title={this.lex('details')}>
		<Container>
		<Row>
		  <div className="col-md-6">
		    <table className="table text-color text-left">
		      <tbody>
		        <tr>
		          <th className={b0}>{this.lex('conf')+':'}</th>
		          <td className={b0}>
		          <NumberFormat className="d-none d-md-inline" value={this.state.confirmations} decimalScale='0' displayType={'text'} thousandSeparator={true}/>
		          </td>
		        </tr>
		        <tr>
		        <th>{this.lex('tindex')+':'}</th>
		          <td><NumberFormat value={this.state.txindex} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		        <tr>
		          <th>{this.lex('nid')+':'}</th>
		            <td><NumberFormat value={this.state.nid} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		        <tr>
		          <th>{this.lex('nonce')+':'}</th>
		            <td><NumberFormat value={this.state.nonce} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		        <tr>
		          <th>{this.lex('version')+':'}</th>
		            <td><NumberFormat value={this.state.version} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		      </tbody>
		    </table>
		</div>
		<div className="col-md-6">
		    <table className="table text-color text-left">
		      <tbody>
		      <tr>
		        <th className={b0}>{this.lex('slimit')+':'}</th>
		        <td className={b0}>
		          <NumberFormat value={this.state.steplimit} displayType={'text'} thousandSeparator={true} />
		        </td>
		      </tr>

		      <tr>
		        <th>{this.lex('step')+':'}</th>
		        <td>
		          <NumberFormat value={this.state.stepsused} displayType={'text'} thousandSeparator={true} />
		        </td>
		      </tr>
		        <tr className={(this.state.data) === 'No data' ? 'd-none' : 'd-row'}>
		          <th>{this.lex('data')+':'}</th>
		           <td><textarea disabled spellCheck='false' className="form-control rounded-0" value={this.state.data}></textarea></td>
		        </tr>
		        <tr className={(this.state.signature === 'No signature') ? 'd-none' : 'd-table-row'}>
		          <th>{this.lex('sig')+':'}</th>
		        <td><textarea disabled spellCheck='false' className="form-control rounded-0" value={this.state.signature}></textarea></td>
		        </tr>
		      </tbody>
		    </table>
		  </div>
		</Row>
		</Container>

		</Tab>
		{splitlogs.length === 0 ? ' ' : (
		<Tab mountOnEnter={true} eventKey="2" title={`${this.lex('inttransfers')} (${splitlogs.length})`}>
		<Container>
		{this.renderTxhead(result.eventLogs)}
		</Container>

		</Tab>)}
		{result.eventLogs === 'undefined' || result.eventLogs.length === 0  ? ' ' : (
		<Tab mountOnEnter={true} eventKey="3" title={`${this.lex('evlog')} (${result.eventLogs.length})`}>
		<div className="container overflow-auto pt-0 mt-0">
		{this.renderLogs(result.eventLogs)}
		</div>
		</Tab>)}
		{votes.length === 0 ? (' ') : (
		<Tab mountOnEnter={true} eventKey="4" title={this.lex('votes')+' ('+votes.length+')'}>
		<Container>
		{votes.length === 0 ? (' ') : isLoading? wait() : renderVoteTab(votes,preps)}
		</Container>
		</Tab>)}
		<Tab mountOnEnter={true} eventKey="5" title={this.lex('raw')}>
		<Container>

		<div className="overflow-auto p-2 media-bg text-left">
		<ReactJson src={(typeof(this.state.rawData)) === 'undefined' ? {data:'No Data'} : (this.state.rawData)} />
		</div>
		</Container>

		</Tab>
		</Tabs>
	)
}


render() {
	const {isLoading}=this.state
	return isLoading
	? wait ()
	: (
		 <div className="Transaction">
		  <div className="container mt-3 rounded">
		 	 <div className="card text-color border-0 shadow-sm">
			  <div className="card-body pb-0 rounded">
		 	 	 <Container>
				  <Row>
					 <div className="grey-border pr-2 pl-2 pb-0 col-md-12 text-left">
						<div className="float-left text-break"><h4 className="display-5 mb-0 p-1 d-flex align-items-center text-capitalize"><FontAwesomeIcon icon={faFileAlt} />&nbsp;{this.lex('tx')}<ClipBoard name={this.state.tx.txHash} type={this.lex('tx')}/></h4>
						<span className="h6 pl-1 mt-0 d-inline-block text-secondary">{this.state.tx.txHash}</span>
						</div>
					 	<div className="float-right pt-2 d-none d-md-block">
							 {/*<DropMenu condition={'d-inline-block'} content={(<Dropdown.Item eventKey="1" disabled><span><FontAwesomeIcon icon={faFilePdf} /> Print Receipt</span></Dropdown.Item>)} />*/}
						</div>
					 </div>
			 	 	</Row>
			 		{this.topTable()}
				</Container>
			</div>
		</div>
			<div ref={this.scrollDiv} className="card border-0 shadow-sm mt-3 text-color">
			{this.extraData()}
			</div>
		</div>
	</div>);
 }
}
export default withTranslation('common')(withRouter(Transaction));
