import React, { useState, useEffect,useRef,createRef } from 'react';
import Posts from './posts';
import Pagination from './../utils/pagination';
import axios from 'axios';
import {Table, Button} from 'react-bootstrap'
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {API} from './../utils/util'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const RepsTable = ({type, icxusd, mult,t}) => {
  const [posts, setPosts] = useState([]);
  const [irep,setiRep] = useState('0x01');
  const [allposts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(40);
  let [block, setBlock] = useState('0x0');
  let [count, setCount] = useState(0);
  // let [icxusd, setPrice] = useState(0);
  let [leader, setLeader] = useState('');
  let [days, setDays] = useState(1);
  let [delay] = useState(15000);
  const myRef = createRef();

  const lex = (w) => t(w, { framework: "react-i18next" })



  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      // let lastBlock = '{"jsonrpc":"2.0","id":1577374497682,"method":"icx_getLastBlock"}';
      let getPreps = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPReps"}}}';
      let getIISSInfo = '{"jsonrpc":"2.0","method":"icx_call","id":2329967368,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getIISSInfo"}}}';

      // const requestOne = axios.post(API,lastBlock)
      let requestOne = axios.get('https://api.iconwat.ch/blocks/?page=1&count=1')
      let requestTwo, requestFour;

      (type === 'blacklist') ?
      requestTwo = axios.get('https://tracker.icon.foundation/v3/iiss/prep/list?count=100&grade=3') :
      requestTwo = axios.post(API,getPreps)
      // const requestThree = axios.get('./../data/data.json')
      requestFour = axios.post(API,getIISSInfo)
      // await axios.all([requestOne, requestTwo,requestThree,requestFour])
      await axios.all([requestOne, requestTwo, requestFour])
      .then(axios.spread((...responses) => {
      const responseOne = responses[0]
      const responseTwo = responses[1]

      // const responseThree = responses[2]
      const responseFour = responses[2]

      // const responseThree = responses[2]
      // console.log(responseTwo.data.result.preps)
      setBlock(responseOne.data.blocks[0].height)
      setLeader(responseOne.data.blocks[0].producer)
      type === 'blacklist' ? setPosts(responseTwo.data.data) : setPosts(responseTwo.data.result.preps);
      type === 'blacklist' ? setAllPosts(responseTwo.data.data) : setAllPosts(responseTwo.data.result.preps);
      // setiRep(responseFour.data.result.variable.irep)
      if (responseFour.data.result.variable.irep != undefined) {setiRep(responseFour.data.result.variable.irep)}

      // setPosts(preps.preps);

      // setAllPosts(preps.preps);
      // setPrice(responseThree.data.icxusd);
      // console.log(preps)


    })).catch(errors => {
      // react on errors.
    })

      setLoading(false);
    };
    // setPosts(preps.preps);

    fetchPosts();
  }, [type]);

  useInterval(() => {
    const getlead = async () => {
      // let lastBlock = '{"jsonrpc":"2.0","id":1577374497682,"method":"icx_getLastBlock"}';
      await axios.get('https://api.iconwat.ch/blocks/?page=1&count=1')
      .then (res => {
        setLeader(res.data.blocks[0].producer)

      })
    }
    getlead();

    setCount(count + 1);
  }, delay);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  // Change page
  const paginate = pageNumber => {setCurrentPage(pageNumber)
  myRef.current.scrollIntoView({ behavior: 'smooth' })
};

    return loading
    ? wait()
    : (
    <div ref={myRef} className="card border-0 pb-0 mb-0 text-justify-start">
        <Table className="table text-color text-left" hover>
        <thead>
        <tr>
          <th colSpan="1" scope="col" className="border-top-0">#</th>
          <th colSpan="5" scope="col" className=" border-top-0 text-capitalize">{lex('prep')}</th>
          <th colSpan="3" className="border-top-0" scope="col">{lex('role')}</th>
          <th colSpan="3" className="d-none d-lg-table-cell border-top-0" scope="col">{lex('country')}</th>

          <th colSpan="3" className="border-top-0 d-none d-md-table-cell" scope="col">{lex('prodr')}</th>
          {/*<th colSpan="3" className="d-none d-lg-table-cell border-top-0" scope="col">{lex('irep')}</th>*/}
          <th colSpan="3" className="d-none d-lg-table-cell border-top-0" scope="col">{lex('votes')}</th>
          <th colSpan="4" className="d-none d-md-table-cell border-top-0" scope="col"><Button variant="outline-white" className={'text-color p-0'} onClick={() => days === 1 ? setDays(30) : days === 30 ? setDays(365) : setDays(1)}><FontAwesomeIcon icon={faCalendarAlt} /></Button> {days === 1 ? lex('reward') : days === 30 ? lex('mreward') : lex('yreward')}</th>
        </tr>
        </thead>
        <tbody id="txs">
        <Posts currentPage={currentPage} posts={currentPosts} loading={loading} price={icxusd} mult={mult} allposts={allposts} leader={leader} type={type} days={days} block={block} irep={irep} />
        </tbody>
      </Table>
      <div className="pl-2 pb-0 mb-0 pt-2">
      <Pagination
        currentPage={currentPage}
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
      </div>
    </div>
  );
};

export default withTranslation('common')(RepsTable);
