import React from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import {wait} from './../Clipboard/wait'


const Posts = ({ currentPage, posts, loading, leader }) => {

  return loading
  ? wait()
  : posts.map((line, index) => {

    // console.log(leader[address])

     const { address, amount } = line //destructuring
     // console.log(leader[address])

     return (
       <div className="col-12 col-sm-4 col-lg-3 p-1" key={index}>
       <div className="card p-1 border-0 shadow-sm media-bg">
          <div className="d-flex align-items-center grey-border justify-content-center p-1">
          <h6 className="media border-top-0 p-1 m-0">
          <Jazzicon diameter={18} seed={(typeof(address) !== 'undefined') ? jsNumberForAddress(address) : 0} />&nbsp;
          <Link to={`/address/${address}`}>
          {(typeof(leader[address]) === 'undefined') ? address.slice(0,12)+'...' : leader[address] }
         </Link>
         </h6>
         </div>
         <div className="card-body p-1 media-bg">
         <p className="card-text">Stake: <NumberFormat decimalScale='2' value={(typeof(amount) === 'undefined') ? '0' : (amount)} displayType={'text'} thousandSeparator={true}/></p>
         </div>
       </div>
       </div>
     )
   })
};

export default Posts;
