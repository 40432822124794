import React from 'react';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function FlagIcon(_ref) {
  let code = _ref.code,
      style = _ref.style,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 24 : _ref$size,
      props = _objectWithoutPropertiesLoose(_ref, ["code", "style", "size"]);

  return React.createElement("img", _extends({}, props, {
    alt: code,
    src: "../img/flags/" + code + ".svg",
    style: _extends({}, style, {
      height: `${size}px`,
      width: `${size}px`,
      display: "inline-block"
    })
  }));
}

export { FlagIcon };
