import React from 'react';
import { Link } from 'react-router-dom';
import {Spinner,Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import Moment from 'react-moment';
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from 'react-i18next';

const Posts = ({ currentPage, posts, loading, leaders, live, t }) => {
  const lex = (w) => t(w, { framework: "react-i18next" })

  if (loading) {
    return <div className="row h-50"><div className="col-sm-12 h-100 d-table"><Spinner animation="border" variant="info" /></div></div>;
  }

  return posts.map((line, index) => {


    const { timestamp, hash, value, from, to, height, status } = line //destructuring

     return (
       <tr key={index}>
       <td colSpan="3" className="col-special text-truncate">
       <span className="d-inline-block d-md-none">TX Hash:&nbsp;</span>
       <Link to={`/tx/${hash}`}>{hash.slice(2,12)}</Link></td>
       <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate">
       <Badge className="badge-width text-uppercase" variant={status === 1 ? 'success' : 'danger'}>
       {status === 1 ? lex('success') : lex('failed')}
       </Badge>
     </td>
       {live ? '' : <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate "><Moment fromNow >{timestamp/1000}</Moment></td>}
       <td colSpan="3"className="d-none d-lg-table-cell"><Link to={`/block/${height}`}><NumberFormat decimalScale='0' value={height} displayType={'text'} thousandSeparator={true} prefix={''}/></Link></td>
       <td colSpan="12"><div className="media border-0 p-0 mx-auto">
       {(typeof(from) === 'undefined' || from === 'genesistx 0') ? ' ' : (
       <div className="d-none d-md-block"><Jazzicon diameter={20} seed={(from === 'genesistx' || typeof(from)) === 'undefined' ? 0 : jsNumberForAddress(from)} /></div>
     )}
       &nbsp;
       {(from ==='genesistx 0') ? 'genesistx 0' : (from === '') ? 'System' :
        (<Link to={`/address/${from}`}>
        {typeof(from) === 'undefined' ? '' : (typeof(leaders[from]) === 'undefined') ? from.slice(0,12)+'...' : leaders[from] }
        </Link> )}
       <FontAwesomeIcon className="mt-1 mb-1 mr-3 ml-3" icon={faLongArrowAltRight} />
       <div className="d-none d-md-block"><Jazzicon diameter={20} seed={typeof(to) !== 'undefined' || typeof(to) !== 'undefined'  ? jsNumberForAddress(to) : 0} /></div>
       &nbsp;
       {(to === '') ? 'System' : (
       <Link to={`/address/${to}`}>
       {typeof(to) === 'undefined' ? '' : (typeof(leaders[to]) === 'undefined') ? to.slice(0,12)+'...' : leaders[to] }
       </Link>)}
       </div>
       </td>
       <td colSpan="3">
       <Badge className='p-1 badge-width media-bg text-color'>
       <NumberFormat decimalScale='2' value={value/1000000000000000000} displayType={'text'} thousandSeparator={true} prefix={'ICX '}/>
       </Badge>
       </td>
       </tr>
     )
   })
};

export default withTranslation('common')(Posts);
