import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import {Row, Col, Badge, Tabs,Tab, Tooltip,OverlayTrigger} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactJson from 'react-json-view'
import system from './../assets/set/staked.svg'
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';
import {thes,peer} from './../utils/requests'
import {request} from './../utils/funcs'
import axios from 'axios';



class System extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      prep:{cid:'',config:{dbType:'',platform:''},status:'', height:'', leader:'0x0',versions:{},module:{metrics:{consensus_height:null},network:{p2p:{seeds:{},roots:{}}}}},
      block:{producer:''},
      tx:{total:''},
      key: 1,
      preps:{}
    }
    this.scrollDiv = React.createRef();
  }

  lex = (w) => this.props.t(w, { framework: "react-i18next" })

  async componentDidMount() {
        switch (this.props.location.hash) {
          case ('#details'):
          this.setState({ isLoading: true, key:1, hash:'#details' })
          this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
          break;
          case ('#raw'):
          this.setState({ isLoading: true, key:2, hash:'#raw' })
          this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
          break;
          default:
          this.setState({ isLoading: true, key:1})
        }

    document.title = 'ICON System';

    await axios.all([request(thes()), 
                    request(peer()),
                    request('https://api.iconwat.ch/blocks/?page=1&count=1'),
                    request('https://api.iconwat.ch/transactions/?page=1&count=1')
                    ]
                    )
    .then(axios.spread((...res) => {

    this.setState({ preps:res[0].data, prep:res[1].data, block:res[2].data.blocks[0], tx:res[3].data})
  })).catch(errors => {
    // react on errors.
  })

    this.setState({ isLoading: false})

  }

async componentDidUpdate(prevProps, prevState) {
  if(prevProps.location.hash!==this.props.location.hash){
    this.props.location.hash === '#details' ? this.setState({ key:1, hash:'#details' }) : this.props.location.hash === '#raw' ? this.setState({ key:2, hash:'#raw' }) : this.setState({ key:1, hash:'#details' });
  }
}


  handleClick = (e) => {
    this.setState({key:e})
    switch (e) {
      case '1':
      default:
      return this.props.history.push("#details")
      case '2':
        return this.props.history.push("#raw")

    }
  }

  extraData(t0, lor) {
    const { isLoading,prep } = this.state;

    return isLoading
    ? wait()
    : (
      <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.handleClick(k)} transition={false}>
      <Tab mountOnEnter={true} eventKey="1" title="More Details">
      <div className="container">
      <Row>
        <Col md={6}>
          <table className="table text-color text-left">
            <tbody>
            <tr>
            <th className={t0}>{this.lex('mainpreps')}:</th>
            <td className={t0}>
            <Link to={`../governance`}>{Object.keys(prep.module.network.p2p.roots).length}</Link>
            </td>
          </tr>
          <tr>
            <th>{this.lex('sub')}:</th>
          <td>
            <Link to={`../governance`}>{Object.keys(prep.module.network.p2p.seeds).length}</Link>
          </td>
          </tr>
            </tbody>
          </table>
      </Col>
      <Col md={6}>
          <table className="table text-color text-left">
            <tbody>
            <tr>
              <th className={t0}>Platform:</th>
              <td className={t0}>
              <code className={lor}>{prep.config.platform}</code>
              </td>
            </tr>
            <tr>
              <th>dbType:</th>
              <td>
              <code className={lor}>{prep.config.dbType}</code>
              </td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      </div>
      </Tab>
      <Tab mountOnEnter={true} eventKey="2" title="Raw Data">
      <div className="container">
      <div className="overflow-auto p-2 media-bg text-left">
      <ReactJson src={(typeof(prep)) === 'undefined' ? {data:'No Data'} : (prep)} />
      </div>
      </div>
      </Tab>
      </Tabs>


    )

  }



  topTable(t0, lor){
    const { isLoading, prep, preps, block, tx } = this.state;
    return isLoading
    ? wait()
    : (

    <Row>
      <Col md={6} className="overflow-auto">
        <table className="table text-color text-left">
          <tbody>
            <tr>
              <th className={t0}>{this.lex('status')}: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Data by <FontAwesomeIcon icon={faServer} /> Rhizome</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></th>
              <td className={t0}>
              <Badge className="pt-1 pb-1 pr-2 pl-2" variant={prep.state === 'started' ? 'success' : 'danger'}>
              {prep.state === 'started' ? 'ONLINE' : 'OFFLINE'}
              </Badge>
              </td>
            </tr>
            <tr>
              <th>{this.lex('block')}:</th>
              <td>
                <Link to={`../block/${prep.module.metrics.consensus_height}`}>{prep.module.metrics.consensus_height}</Link>
              </td>
            </tr>
            <tr>
              <th>{this.lex('confirmedblock')}:</th>
              <td>
              <Link to={`../block/${prep.height}`}>{prep.height}</Link>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col md={6}>
      <table className="table text-color text-left">
        <tbody>
          <tr>
            <th className={t0}>{this.lex('leader')}:</th>
            <td className={t0}>
            <div className="d-flex align-items-center text-truncate">
            <Jazzicon diameter={16} seed={typeof(block.producer) === 'undefined' ? 0 : jsNumberForAddress(block.producer)} />&nbsp;
            <Link to={`/address/${block.producer}`}>
            {preps[block.producer]}
           </Link>
           </div>
            </td>
          </tr>
          <tr>
            <th>{this.lex('transactions')}:</th>
            <td>
              {console.log(tx)}
            <NumberFormat value={tx.total} displayType={'text'} thousandSeparator={true} prefix={''}/>
            </td>
          </tr>
          <tr>
          <th>CID:</th>
          <td>
          <code className={lor}>{prep.cid}</code>
          </td>
        </tr>
      </tbody>
    </table>
  </Col>
</Row>
    )
  }

  render() {
    const { isLoading } = this.state;
    let t0 = 'border-top-0'
    let lor = 'text-color'

    return isLoading
    ? wait()
    : (
<div className="Block mt-3">
      <div className="container">
      <div className="card text-color border-0 shadow-sm">
     	 <div className="card-body pb-0 rounded">
     		 <div className="container">
     			 <Row>
              <div className="grey-border p-2 mb-3 col-md-12 text-left">
                  <h4 className="display-5">                  <img
                                         src={system}
                                         className="pb-2"
                                         width="35"
                                         height="35"
                                         alt={'system'}
                                       /> {this.lex('system')}</h4>

              </div>
            </Row>

{this.topTable(t0,lor)}
      </div>
    </div>
  </div>

  <div ref={this.scrollDiv} className="card mt-3 text-color border-0 shadow-sm">
{this.extraData(t0,lor)}
        </div>
        </div>
        </div>
  );
  }
}
export default withTranslation('common')(withRouter(System));
