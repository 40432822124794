import React, { Component } from 'react';
import block from './../assets/set/block.svg'
// import Paginate from './../Transactions/paginate';
import {Button} from 'react-bootstrap'
import Pagify from './paginate';
import Pills from './../Pills/'
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';

class Blocks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      title: 'ICON Blocks',
      activePage:0,
      live:false,
      interval:2500,
      prepss:{totalStake:'0x0',totalDelegated:'100',preps:[]},
      preps:{}

    }
  }
  
lex = (w) => this.props.t(w, { framework: "react-i18next" })


componentDidMount() {
    this.setState({ isLoading: true,title:'ICON Blocks' });
    let currentPage = this.props.match.params.currentPage ==='undefined' ? '0' : this.props.match.params.currentPage
    document.title = 'ICON Blocks';

    (axios.get(`/data/thes`))
    .then(res => {
      this.setState({preps:res.data});
    })

    this.setState({isLoading:false,activePage:currentPage})
  }

static getDerivedStateFromProps(nextProps, prevState){

if(typeof(nextProps.match.params.currentPage!=='undefined')) {
 if(nextProps.match.params.currentPage!==prevState.currentPage){
   return { activePage: nextProps.match.params.currentPage, pageNumber: nextProps.match.params.currentPage};
}
else return null;
}
}


async componentDidUpdate(prevProps, prevState) {
  if(prevProps.match.params.currentPage!==this.props.match.params.currentPage){

}
}

changeLive = (live) => this.setState({live:!live})

render() {
    const {isLoading,activePage,preps, prepss,live,interval } = this.state;

    return isLoading
    ? wait()
    : (
  <div className="Blocks container">
  <Pills type="tx" thes={preps} preps={prepss}/>

  <div className="row mt-3 mb-5">
        <div className="col-sm-12">

        <div className="card border-0 shadow-sm">
          <div className="card-body">
            <div className="row">
            <div className="col-6 float-left text-left">

                  <h3 className="float-left">
                  <img
                       src={block}
                       className="pb-2"
                       width="35"
                       height="35"
                       alt={'blocks'}
                     /> ICON {this.lex('block')}</h3>

                    </div>
                    <div className={`col-6 float-right`}>
                                            <Button
                                            className="float-right mr-2"
                                            variant={live? 'dark' : 'primary'}
                                            disabled={isLoading}
                                            onClick={()=>this.changeLive(live)}
                                            >
                                            {isLoading ? 'Loading…' : <span><FontAwesomeIcon icon={live? faPause : faPlay} /></span>}
                                          </Button>

                      </div>

            </div>
            <Pagify type={'blocks'} live={live} interval={interval} forcedPage={activePage} preps={preps} />
      </div>
      </div>
            </div>

</div>


</div>
);
}
}

export default withTranslation('common')(Blocks);
