import React, { useState, useEffect } from 'react';
import Posts from './posts';
import Pagination from './../utils/pagination';
import axios from 'axios';
import {Table} from 'react-bootstrap'
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';

const Paginate = ({page, interval, preps, tokens, mult, t}) => {
  const lex = (w) => t(w, { framework: "react-i18next" })
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(page === 0 || typeof(page) === 'undefined' || page === '' ? 1 : page);
  // const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  // const [leaders, setLeaders] = useState([]);
  // let [count, setCount] = useState(0);
  


  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      let link;
      link = 'https://api.iconwat.ch/tokens/?page='+currentPage+'&count='+postsPerPage

      const res = await axios.get(link);
      setPosts(res.data.tokens);
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage, postsPerPage]);


  const currentPosts = posts
  const paginate = pageNumber => {setCurrentPage(pageNumber)
  window.scrollTo(0, 0)
};
  return loading
  ? wait()
  : ((typeof(posts) === 'undefined') || (posts === null))
  ? ''
  : (

    <div>
      {tokens ? 
      (
        <Table className="text-left text-color" hover>
            <thead>
            <tr>
            <th colSpan="3" className="border-0">{lex('token')}</th>
            <th colSpan="2" className="border-0 d-none d-lg-table-cell">{lex('marketcap')}</th>
            <th colSpan="2" className="border-0">{lex('price')}</th>
            <th colSpan="3" className="border-0 d-none d-lg-table-cell">{lex('supply')}</th>
            <th colSpan="3" className="border-0 d-none d-lg-table-cell">{lex('deployed')}</th>
            </tr>
            </thead>
            <tbody id="txs">
            <Posts currentPage={currentPage} posts={currentPosts} loading={loading} mult={mult} />
      </tbody>
    </Table>
      ) 
    : ( <Table className="breakTable text-left text-color" hover>
            <thead>
            <tr>
            <th colSpan="3" className="border-0">{lex('contract')}</th>
            <th colSpan="3" className="border-0 d-none d-lg-table-cell">{lex('balance')}</th>
            <th colSpan="3" className="border-0 d-none d-lg-table-cell">{lex('supply')}</th>
            <th colSpan="3" className="border-0">{lex('deployed')}</th>
            <th colSpan="2" className="border-0 d-none d-lg-table-cell">{lex('status')}</th>
            </tr>
            </thead>
            <tbody id="txs">
            <Posts currentPage={currentPage} posts={currentPosts} loading={loading} mult={mult} />
      </tbody>
    </Table>
    )}
    <div className={posts.length < 50 ? 'd-none' : 'd-block' }>
    <Pagination
      currentPage={currentPage}
      postsPerPage={postsPerPage}
      totalPosts={posts.length}
      paginate={paginate}
    />
    </div>
    </div>

  );
};

export default withTranslation('common')(Paginate);
