import React from 'react';
import { Link } from 'react-router-dom';
import {Spinner} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'

const Posts = ({ currentPage, posts, loading, leader, account }) => {

  // console.log(posts)
  if (loading) {
    return <div className="row h-50"><div className="col-sm-12 h-100 d-table"><Spinner animation="border" variant="info" /></div></div>;
  }

  return posts.map((line, index) => {

    // console.log(leader[address])

    const { rank, address, quantity, txCount, percentage } = line //destructuring
     // console.log(leader[address])

     return (
       <tr key={index}>
       <td colSpan="1" className="d-none d-lg-table-cell">
       <NumberFormat decimalScale='2' value={typeof(rank) !== 'undefined' ? rank : 0 } displayType={'text'} thousandSeparator={true}/>
       </td>
       <td colSpan="5">
       <div className=" d-flex align-items-center text-truncate">
       <Jazzicon diameter={18} seed={typeof(address) === 'undefined' || address === 0 ? 0 : jsNumberForAddress(address)} />&nbsp;
       <Link to={`/address/${address}`}>{address.slice(0,25)+'...'}</Link>
       </div>
       </td>
       <td colSpan="3" className="d-none d-xl-table-cell">
       <NumberFormat decimalScale='2' value={typeof(txCount) !== 'undefined' ? txCount : 0 } displayType={'text'} thousandSeparator={true}/>
       </td>
       <td colSpan="3">
       <NumberFormat decimalScale='2' value={typeof(quantity) !== 'undefined' ? quantity : 0 } displayType={'text'} thousandSeparator={true} />
       </td>
       <td colSpan="2">
       <NumberFormat decimalScale='2' value={typeof(percentage) !== 'undefined' ? percentage : 0 } displayType={'text'} thousandSeparator={true} suffix={'%'}/>
       </td>
       </tr>
     )
   })
};

export default Posts;
