import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap'
import axios from 'axios'
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fall:1,
      isLoading: true,
      market:[],
      blockchain:[]

    };
  }
lex = (w) => this.props.t(w, { framework: "react-i18next" })

componentDidMount() {
  this.setState({ isLoading: true });
  document.title = 'ICON '+this.lex('chartsstats')

  let firstCall = axios.get('./data/market')
  let secondCall = axios.get('./data/blockchain')
  let thirdCall = axios.get('./data/staking')
  axios.all([firstCall, secondCall, thirdCall]).then(axios.spread((...responses) => {
    const responseOne = responses[0]
    const responseTwo = responses[1]
    const responseThree = responses[2]

    this.setState({market:responseOne.data.data,blockchain:responseTwo.data.data,staking:responseThree.data.data,isLoading: false})
}))
}

renderDapps = (charts) => {
return charts.map((dapp, index) => {
const {name, url, img, ver} = dapp
return (

    <div key={index} className="col-sm-12 col-md-6 col-lg-4 pr-2 pl-2 pt-2 pb-0">
      <div className="chartp shadow-sm p-0">
      <div className="desc p-1 text-left">
      <div className="p-2 h6 mb-0">
      <Link to={`/chart/${url}`}>{name}</Link>
      </div>
      </div>
      <div className="card-body p-1">
      <Link to={`/chart/${url}`}>
      <img className="rounded-0 bg-media img-fluid w-100" src={`/img/charts/${img}?v=${ver}`} alt={name} />
      </Link>
      </div>
      </div>
      </div>
)})
}

  render (){
    const {isLoading,market,blockchain,staking} = this.state

    return isLoading
    ? wait()
    : (
    <div className="container mt-3">
    <Row>
    <Col>
      <div className="card text-color border-0 shadow-sm">
      <div className="card-body rounded">
      <div className="grey-border p-2 mb-3 col-md-12 text-left">
          <h4 className="display-5">{this.lex('chartsstats')}</h4>
          <h6 className="d-none d-md-inline text-secondary">{this.lex('chartstag')}</h6>
      </div>
      <div className="container">

      <div className="text-left">
      <h5>{this.lex('marketdata')}</h5>
      </div>
      <Row className="pb-3">
      {this.renderDapps(market)}
      </Row>
      <div className="text-left">
      <h5>{this.lex('blockchaindata')}</h5>
      </div>
      <Row className="pb-3">
      {this.renderDapps(blockchain)}
      </Row>
      <div className="text-left">
      <h5>{this.lex('stakingdata')}</h5>
      </div>
      <Row className="pb-3">
      {this.renderDapps(staking)}
      </Row>
      </div>
      </div>
      </div>
      </Col>

      </Row>
      </div>
    )

  }
}

export default withTranslation('common')(Charts)
