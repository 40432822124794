import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, Link } from 'react-router-dom';
import { faSignInAlt,faWallet,faSignOutAlt,faKey } from '@fortawesome/free-solid-svg-icons';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import { Modal, Dropdown, Button } from 'react-bootstrap';
// import QRCodes from './qrcode';
import logo from './../assets/wait.svg';
// import ledger from './../assets/ledger.svg';
import {ICONEX_RELAY} from './../utils/util'

const INITIAL_STATE = {
  wallet: null,
  keystore: null,
  balance: null,
  fullBalance: null,
  stake: {},
  iScore: {},
  delegations: null,
  votingPower: null, // Is this required?
  createWallet: null,
  unlockWallet: null,
  accessLedgerWallet: null,
  accessICONexWallet: null,
  unloadWallet: null,
  refreshWallet: null,
  isLoading: false,
};

// const WalletContext = createContext(INITIAL_STATE);



function UnlockWithICONex({ onUnlockWallet, history }) {
  // const { accessICONexWallet } = useWallet();
  const [hasError, setError] = useState(false);
  const [address, setAddress] = useState('');
  const [dropshow, setDropshow] = useState(false);
  const [hasExtension, setHasExtension] = useState(false);
  // const [hasAccount, setHasAccount] = useState(false);
  // const [isChecking, setIsChecking] = useState(true);
  const [wallet, setWallet] = useState(INITIAL_STATE.wallet);
  // const [isLoading, setIsLoading] = useState(INITIAL_STATE.isLoading);
  const [show, setShow] = useState(false);

  // function useWallet() {
  //   return useContext(WalletContext);
  // }

  const relayEventHandler = useCallback((event) => {
    const { type, payload } = event.detail;
    switch (type) {
      case 'RESPONSE_HAS_ACCOUNT':
        return handleResponseHasAccount(payload);
      case 'RESPONSE_ADDRESS':
        return handleResponseAddress(payload);
      default:
    }
  },[handleResponseAddress,handleResponseHasAccount])
  
  useEffect(() => {
    // setIsChecking(true);
    // setTimeout(() => setIsChecking(false), 500);
    window.addEventListener('ICONEX_RELAY_RESPONSE', relayEventHandler);
    // window.dispatchEvent(
    //   new CustomEvent(ICONEX_RELAY.REQUEST, {
    //     detail: { type: 'REQUEST_HAS_ACCOUNT' },
    //   })
    // );
    return () => void window.removeEventListener('ICONEX_RELAY_RESPONSE', relayEventHandler);
  }, [relayEventHandler]); 
  
  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(ICONEX_RELAY.REQUEST, {
        detail: { type: 'REQUEST_HAS_ACCOUNT' },
      })
    );
  }, [relayEventHandler]); 
  

  function handleResponseHasAccount({ hasAccount }) {
    setHasExtension(true);
    // setHasAccount(hasAccount);
    // setIsChecking(false);
  }
  

  function handleResponseAddress(address) {
    console.log(address)
    accessICONexWallet(address);
    // onUnlockWallet();
  }
  
  function accessICONexWallet(address) {
  const wallet = {
    getAddress: () => address  };
  setWallet(wallet);
  refreshWallet(wallet);
}

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

  function getICONexAddress() {
    // if (!hasExtension) {

    // } else {
    window.dispatchEvent(
      new CustomEvent(ICONEX_RELAY.REQUEST, {
        detail: { type: 'REQUEST_ADDRESS' },
      })
    );
    // }
  }
  
  async function refreshWallet(providedWallet) {
  // setIsLoading(true);
  const address = (providedWallet || wallet).getAddress();
  localStorage.setItem("wallet", address)
  setAddress(address)
  history.push('/wallet')
  // const [balance, stake, iScore, { delegations, votingPower }] = await Promise.all([
  //   getBalance(address),
  //   getStake(address),
  //   getIScore(address),
  //   getDelegations(address),
  // ]);
  // setBalance(balance);
  // setStake(stake);
  // setIScore(iScore);
  // setDelegations(delegations);
  // setVotingPower(votingPower);
  // 
  // const { staked, unstaking } = stake;
  // setFullBalance(balance.plus(staked).plus(unstaking || 0));

  // setIsLoading(false);
}
let langColor='dropdown-bg dropdown-item text-color font-weight-light'

const onMouseEnter = () => {
  setDropshow(true)
  }

const onMouseLeave = () => {
  setDropshow(false)
  }
const changeDrop = () => {
  setDropshow(false)
  
}

const showError = () => {
  setError(true)
}
const unshowError = () => {
  setError(false)
}

  return (
    <>
      {(localStorage.wallet === '' || typeof(localStorage.wallet) === 'undefined' || address === 'logedout')
      ? (
        <Dropdown alignRight className="d-none d-md-flex align-items-center" onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} show={dropshow}>
          <Dropdown.Toggle variant="white" className="text-white mb-1 pb-0 langButton">
          <FontAwesomeIcon className="p-0 m-0"
          icon={faSignInAlt} />&nbsp; &#9662;
          
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-nohover pt-1 mt-0">
        <Dropdown.Item onClick={!hasExtension ? handleShow : getICONexAddress} className={langColor} >
        <img
            src={logo}
            width="20"
            height="20"
            alt='iconex'
          />
          <span className="text-color weight-400">&nbsp;ICONex</span></Dropdown.Item>
          {/* <Dropdown.Item onClick={()=>history.push('/wallet/create-account')} className={langColor} ><span className="text-color"><FontAwesomeIcon icon={faKey} />&nbsp;New Wallet</span></Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>

    ) 
      : (
        <Dropdown alignRight className="d-none d-md-flex align-items-center" onClick={changeDrop} onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} show={dropshow}>
          <Dropdown.Toggle variant="white" className="text-white mb-1 pb-0 langButton">
        <Jazzicon diameter={15} seed={typeof(localStorage.wallet) === 'undefined' ? 0 : jsNumberForAddress(localStorage.wallet)} /> &#9662;

        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-nohover pt-1 mt-0">
        <Link to={`/address/${localStorage.wallet}`}><Dropdown.Header className={'mb-0 pointer'}><span className="h6">Account:</span><br/><span className="text-color h6">{localStorage.wallet.slice(0,10)}...{localStorage.wallet.substr(-4)}</span></Dropdown.Header></Link>
        {/* <Dropdown.Divider />
        <Dropdown.Item onClick={()=>history.push('/wallet')} className={langColor} ><span className="text-color"><FontAwesomeIcon icon={faWallet} />&nbsp;Wallet</span></Dropdown.Item> */}
        {/*<QRCodes name={localStorage.wallet} type={'menu'} />*/}
        <Dropdown.Divider />
        <Dropdown.Item className={langColor} onClick={() => localStorage.removeItem('wallet')+setAddress('logedout')} ><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      )}
      <Modal
        show={show} 
        onHide={handleClose}
        onExit={unshowError}
        className="mt-6 p-0"
      >
      <Modal.Header className="text-color dropdown-nohover" closeButton>
      <Modal.Title>ICONex</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center text-color dropdown-nohover pt-4 pb-1">
      
      <img
          src={logo}
          width="80"
          height="80"
          alt='iconex'
          className="d"
        />
        <br/>
        {!hasError ? <span className="d-block text-danger pt-3">&nbsp;</span> : <span className="d-block text-danger pt-3 pb-2 weight-400">Please install ICONex and try again</span>}
        <Button variant="info" className="btn mt-2 btn-xs btn-block weight-400 mb-2" onClick={!hasExtension ? showError : getICONexAddress}  >
        ICONEX</Button>
      </Modal.Body>      
      <Modal.Footer className="dropdown-nohover justify-content-center p-2">

      <a href='https://chrome.google.com/webstore/detail/iconex/flpiciilemghbmfalicajoolhkkenfel' rel="noopener noreferrer" target="_blank"><span className="text-color">Need to install ICONex? Click here &#187;</span></a>
      </Modal.Footer>
</Modal>
    </>
  );
}

export default withRouter(UnlockWithICONex);