import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
// import PieChart from "highcharts-react-official";
import Highcharts from 'highcharts/highstock'
// import Lowcharts from 'highcharts'
import axios from 'axios';
require("highcharts/modules/exporting")(Highcharts);

// const Serve = 'https://ctz.blockmove.eu/api/v3'


class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fall:1,
      title:'',
      // subtitle:"The ICON (ICX) daily Volume chart shows the daily historical Volume of ICX transacted.",
      subtitle:"This chart displays the number of daily transactions on the ICON blockchain.",
      // To avoid unnecessary update keep all options in the state.
      isLoading: true,
      chartOptions: {
        lang: {
    numericSymbols: null,
    thousandsSep: ','
},
        chart: {
          animation: true,
          height: 500,
          zoomType: "x",
        },
        resetZoomButton: {
     position: {
         // align: 'right', // by default
         // verticalAlign: 'top', // by default
         x: -10,
         y: 10
     },
     relativeTo: 'chart'
 },
        styledMode: true,
        className:'bgLight',
        credits:{enabled:false},
        exporting: {
          enabled: true,
  buttons: {
    contextButton: {
        menuItems: [
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG'
        ]
    },
  }
},
        scrollbar: {
            enabled: false
        },
        title: {
          // text: 'Daily Volume',
          text: '',
          className: 'text-color'
        },
        subtitle: {
                text: document.ontouchstart === undefined ?
                    'Source: iconwat.ch<br />Click and drag in the plot area to zoom in' : 'Source: iconwat.ch<br />Pinch the chart to zoom in',
                    className:'text-color'
            },
        legend: {
          enabled: false
      },
        rangeSelector: {
            selected: '1'
        },
        xAxis: {
          className:'bgLight',
          categories: '',
        },
        series: [
          { data: [],
            className:'bgLight', }
        ],
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.x:%e %B %Y}<br>{series.name}: <b>{point.y}</b>'
            },
      },
      hoverData: null
    };

    Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
})

  }

  async componentDidMount() {
    // console.log(this.props)
    let fall;
    let title;
    let label;
    let sidelabel;
    let suffix;
    switch(this.props.fall) {
      case('transactions'):
      this.setState({fall:1})
      title='Daily Transactions'
      fall=1
      label='Transactions'
      sidelabel='Number of Transactions'
      suffix=''
      break;
      case('volume'):
      this.setState({fall:2})
      title='Daily Volume'
      fall=2
      label='Volume'
      sidelabel='Daily Volume in ICX'
      suffix=' ICX'
      break;
      case('fees'):
      this.setState({fall:3})
      title='Daily Transaction Fees'
      fall=3
      label='Fees'
      sidelabel='Transaction Fees in ICX'
      suffix=' ICX'
      break;
      case('price'):
      this.setState({fall:4})
      title='Daily Price'
      fall=4
      label='Price'
      sidelabel='ICX Price in USD'
      suffix=' USD'
      break;
      case('blocktime'):
      this.setState({fall:5})
      title='Average Block Time'
      fall=5
      label='Blocktime'
      sidelabel='Time in seconds'
      suffix=' s'
      break;
      case('totaltx'):
      this.setState({fall:6})
      title='Total Transactions'
      fall=6
      label='Transactions'
      sidelabel='Number of Transactions'
      suffix=''
      break;
      case('supply'):
      this.setState({fall:7})
      title='Breakdown by Supply Types'
      fall=7
      label='ICON Total Supply'
      sidelabel='ICX Supply'
      suffix=''
      break;
      case('iconomics'):
      this.setState({fall:8})
      title='Staking Economics'
      fall=8
      label='ICON Total Supply'
      sidelabel='ICX Supply'
      suffix=''
      break;
      case('averagefee'):
      this.setState({fall:9})
      title='Average Fees'
      fall=9
      label='Fee'
      sidelabel='Fees in ICX'
      suffix=' ICX'
      break;
      case('dailyusers'):
      this.setState({fall:10})
      title='Daily Users'
      fall=10
      label='Users'
      sidelabel='Daily Users'
      suffix=''
      break;
      case('addresses'):
      this.setState({fall:11})
      title='Total Addresses'
      fall=11
      label='Addresses'
      sidelabel='Addresses'
      suffix=''
      break;
      case('claims'):
      this.setState({fall:12})
      title='i_Score Claims'
      fall=12
      label='ICX'
      sidelabel='Claims in ICX'
      suffix=''
      break;
      default:
      this.setState({fall:1})
      title='Daily Transactions'
      fall=1
      label='Transactions'
      sidelabel='Number of Transactions'
      suffix=''


    }
      var today = new Date()
      var to = today.toISOString().slice(0,10);
      // var from =  new Date(today.getTime() - (365*24*60*60*1000)).toISOString().slice(0,10)
      var from =  new Date(1516744800).toISOString().slice(0,10)
      let link = 'https://api.iconwat.ch/daily/?from='+from+'&to='+to
    await (axios.get(link))
        .then(async res => {
          const txs = res.data.data;


          const chartOptions = {
    lang: {
      thousandsSep: ','
    },
            chart: {
              height: 500,
            },
            title: {
              text: title,
            },
            yAxis: {
              min:0,
              title:{
                text: sidelabel,
              },
            },
            legend: {
              enabled: false
          },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.x:%e %B %Y}<br>{series.name}: <b>{point.y}</b>'
                },
            zoomType: 'x',
                  xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                     day: '%d %b %Y'    //ex- 01 Jan 2016
                  }
                                  },
                  series: [{
                  data:[],
                  name: label,
                  pointStart: Date.UTC(1516744800),
                  pointInterval: 24 * 3600 * 1000,
                  }],
                }
                let table = txs
                let data = [];
                // let datab = [];
                let labels = [];

                let m = 0;

                // console.log(fall)
        table.forEach((i) => {
            // data.push(i.volumeICX);
            if (fall === 2) {
              data.push(i.volumeICX);
            } else if (fall === 3) {
              data.push(i.fees)
            } else if (fall === 4) {
              data.push(parseFloat(i.priceClose))
            } else if (fall === 5) {
              data.push(86400 / (i.lastBlock-i.firstBlock))
            } else if (fall === 6) {
              m = m+i.txCount
              data.push(m)
            } else if (fall === 7) {
              // data.push(m)
            } else if (fall === 8) {
              // data.push(m)
            }  else if (fall === 9) {
              let num = parseFloat(i.fees)/parseFloat(i.txCount)
              data.push(num)
            }  else if (fall === 10) {
              data.push(i.walletsTransacted)
            }  else if (fall === 11) {
              data.push(i.totalWallets)
            }  else if (fall === 12) {
              if (typeof(i.claimedICX) !== 'undefined') data.push(i.claimedICX)
            }     else {
            data.push(i.txCount)
            }
            if (fall !== 7) {
            labels.push(i.date)
            }
        })



        if (fall === 3 || fall === 4 || fall === 6 || fall === 11 ) {

          chartOptions.chart.type = 'area'


          let plotOptions = {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            }

              chartOptions.plotOptions = plotOptions


        }

        let first = fall === 12 ? '2019-08-28' : labels[0]

        if (fall === 8) {
          let supply = this.props.supply
          chartOptions.chart.type = "pie"
          // chartOptions.chart.width = 300
          chartOptions.legend.enabled = true
          // await axios.post(Serve,'{"jsonrpc": "2.0","method": "icx_getTotalSupply","id": 1234}')
          // await axios.get('https://tracker.icon.foundation/v0/main/mainInfo')
          // .then(res => {
            // let num = parseInt(res.data.result.slice(2,50),16)/1000000000000000000
            // let num = res.data.tmainInfo.icxSupply
            // let num = this.props.supply
            // let pie = {name: "Browsers", colorByPoint: true, data:[]}
            let locked = {name: "Locked Supply", y: this.props.locked, drilldown:"Locked Supply"}
            let staked = {name: "Staked Supply", y: this.props.stake, drilldown:"Genesis Supply"}
            let circulating = {name: "Circulating Supply", y: this.props.circulation-this.props.treasury-(this.props.stake), drilldown:"Circulating Supply"}
            let treasury = {name: "Public Treasury", y: parseInt(this.props.treasury), drilldown:"Public Treasury"}
            // pie.data.push(initial)
            // pie.data.push(newSupply)
            chartOptions.series[0].colorByPoint = true
            data.push(locked)
            data.push(staked)
            data.push(circulating)
            data.push(treasury)

            chartOptions.yAxis.title.text =''

            chartOptions.responsive = {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -5
                    },
                    title: {
                        text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
    }



            chartOptions.plotOptions = {
              pie: {
                colors: ['#22B573', '#00A99D', '#28ABE2', '#0071BC', '#39AE70', '#F77C4E', '#FF9655', '#FFF263', '#FFF263', '#000000'],
                  allowPointSelect: true,
                  cursor: 'pointer',
                  // dataLabels: {
                  //     enabled: false
                  // },
                  showInLegend: true
              },
        series: {
            dataLabels: {
                enabled: true,
                pointFormatter: function() {
                   // style="color:'+this.point.color+'"
                return '<span>'+this.point.name+'</span><b> ('+((this.y / supply)*100).toLocaleString(undefined, {
         maximumFractionDigits: 2
       }) + '%)</b><br/>';
              }
                // format: '{point.name} ({point.y})'
            }
        }
    }



          chartOptions.tooltip = {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f}</b> ICX'
    }

        // })
      } else if (fall === 7) {
          chartOptions.chart.type = "pie"
          // chartOptions.chart.width = 300
          chartOptions.legend.enabled = true
          // await axios.post(Serve,'{"jsonrpc": "2.0","method": "icx_getTotalSupply","id": 1234}')
          // await axios.get('https://tracker.icon.foundation/v0/main/mainInfo')
          // .then(res => {
            // let num = parseInt(res.data.result.slice(2,50),16)/1000000000000000000
            // let num = res.data.tmainInfo.icxSupply
            let num = this.props.supply
            // console.log(num)
            // let pie = {name: "Browsers", colorByPoint: true, data:[]}
            let initial = {name: "Genesis Supply", y: 800460000, drilldown:"Genesis Supply"}
            let newSupply = {name: "Staking Rewards", y: (num - 800460000), drilldown:"Staking Rewards"}
            // pie.data.push(initial)
            // pie.data.push(newSupply)
            chartOptions.series[0].colorByPoint = true
            data.push(initial)
            data.push(newSupply)
            chartOptions.yAxis.title.text =''

            chartOptions.responsive = {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -5
                    },
                    title: {
                        text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
    }



            chartOptions.plotOptions = {
              pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  // dataLabels: {
                  //     enabled: false
                  // },
                  showInLegend: true
              },
              series: {
                dataLabels: {
                    enabled: true,
                    pointFormatter: function() {
                       // style="color:'+this.point.color+'"
                    return '<span>'+this.point.name+'</span><b> ('+((this.y / num)*100).toLocaleString(undefined, {
             maximumFractionDigits: 2
           }) + '%)</b><br/>';
                  }
                    // format: '{point.name} ({point.y})'
                }
            }
    }



          chartOptions.tooltip = {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f}</b> ICX'
    }

        // })
      } else {

        chartOptions.tooltip.pointFormat = '{point.x:%e %B %Y}<br>{series.name}: <b>{point.y}</b>'+suffix
        chartOptions.series[0].pointStart = new Date(first).getTime()

      }
        chartOptions.series[0].data = data

        // console.log(JSON.stringify(dataa))

        this.setState({
            chartOptions:chartOptions,
            isLoading: false
        })



  })
}

  render() {
    const { chartOptions, fall } = this.state;
    // console.log(fall)
    // if (fall === 7) {

    // return(

    //   <PieChart highcharts={Lowcharts} immutable = { false } options={chartOptions} />

    // )
    // }

    return (

        <HighchartsReact
          highcharts={Highcharts}
          constructorType={this.props.fall !== 'iconomics' && this.props.fall !== 'supply' ? 'stockChart' : ''}
          immutable = { false }
          containerProps = {{ className: 'text-color' }}
          options={chartOptions}
        />

    )
  }
}

export default LineChart;
