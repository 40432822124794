import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import axios from 'axios';
import {wait} from './../Clipboard/wait'


class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fall:1,
      title:'',
      // subtitle:"The ICON (ICX) daily Volume chart shows the daily historical Volume of ICX transacted.",
      subtitle:"This chart displays the number of daily transactions on the ICON blockchain.",
      // To avoid unnecessary update keep all options in the state.
      isLoading: true,
      chartOptions: {
        lang: {
    numericSymbols: null,
    thousandsSep: ','
},
        chart: {
          animation: true,
          height: 500,
          zoomType: "x",
        },
        resetZoomButton: {
     position: {
         // align: 'right', // by default
         // verticalAlign: 'top', // by default
         x: -10,
         y: 10
     },
     relativeTo: 'chart'
 },
        styledMode: true,
        className:'bgLight',
        scrollbar: {
            enabled: false
        },
        title: {
          // text: 'Daily Volume',
          text: '',
          className: 'text-color'
        },
        rangeSelector: {
            selected: '1'
        },
        xAxis: {
          className:'bgLight',
          categories: '',
        },
        series: [
          { data: [],
            className:'bgLight', }
        ],
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.x:%e %B %Y}<br>{series.name}: <b>{point.y}</b>'
            },
      },
      hoverData: null
    };

    Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
})

  }

  async componentDidMount() {
    // console.log(this.props)
    let title;
    let label;
    let suffix;
      title=this.props.title
      label='Transactions'
      suffix=''
      var today = new Date()
      var to = today.toISOString().slice(0,10);
      var from =  new Date(today.getTime() - (20*24*60*60*1000)).toISOString().slice(0,10)
      from =  new Date(from).toISOString().slice(0,10)
      let link = 'https://api.iconwat.ch/daily/?from='+from+'&to='+to
    await (axios.get(link))
        .then(async res => {
          const txs = res.data.data;


          const chartOptions = {
    lang: {
      thousandsSep: ','
    },
            chart: {
              height: 350,
            },
            exporting: {
              enabled: false
            },
            title: {
              text: title,
            },
            yAxis: {
              title:{
                text: '',
              },
            },
            legend: {
              enabled: false
          },
          credits:{enabled:false},
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.x:%e %B %Y}<br>{series.name}: <b>{point.y}</b>'
                },
            zoomType: 'x',
                  xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                     day: '%d %b'    //ex- 01 Jan 2016
                  }
                                  },
                  series: [{
                  data:[],
                  name: label,
                  pointStart: Date.UTC(1516744800),
                  pointInterval: 24 * 3600 * 1000,
                  }],
                }
                let table = txs
                let data = [];
                // let datab = [];
                let labels = [];
                // console.log(fall)
        table.forEach((i) => {
            data.push(i.txCount)
            labels.push(i.date)
        })
        chartOptions.chart.type = 'spline'


        let first = labels[0]

        chartOptions.tooltip.pointFormat = '{point.x:%e %B %Y}<br>{series.name}: <b>{point.y}</b>'+suffix
        chartOptions.series[0].pointStart = new Date(first).getTime()

        chartOptions.series[0].data = data
        this.setState({
            chartOptions:chartOptions,
            isLoading: false
        })



  })
}

  render() {
    const { isLoading,chartOptions } = this.state;
    return isLoading
    ? wait()
    : (

        <HighchartsReact
          highcharts={Highcharts}
          immutable = { false }
          containerProps = {{ className: 'text-color' }}
          options={chartOptions}
        />

    )
  }
}

export default LineChart;
