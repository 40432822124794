import React, {Component} from 'react';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import {API} from './../utils/util'
import {getTotalSupply,getPreps} from './../utils/calls'


const CalcInput = props => (
  <React.Fragment>
  <div className="input-group">


    <input
     className="form-control text-color"
      type="number"
      onChange={props.onChange}
      id={props.id}
      value={props.value}
      step="any"
      />
      <div className="input-group-append">
      <div className="input-group-text media-color">ICX</div>
    </div>
      </div>

  </React.Fragment>
)

const simpleReturn = (value, rate) => ((value * rate));


class SimpleCalculator extends Component {

  state = {
    lumpSumValue: 100,
    rate: null,
    total: 0,
    totalDay: 0,
    totalMonth: 0,
    min: 0,
    show:false,
    interest: 0,
    max: 1000000000000000000

  }

  lex = (w) => this.props.t(w, { framework: "react-i18next" })


  componentDidMount() {
    this.calculate()
  }

  async calculate() {

if (this.state.rate === null) {

    let totalSupply;
    let delegatedSupply;
    let firstCall = axios.post(API,getPreps())
    let secondCall = axios.post(API,getTotalSupply())

    await axios.all([firstCall, secondCall]).then(axios.spread((...responses) => {
    const responseOne = responses[0]
    const responseTwo = responses[1]

    delegatedSupply = parseInt(responseOne.data.result.totalDelegated.slice(2,60),16)/ 1000000000000000000
    // delegatedSupply = delegatedSupply+(17100000*6)
    totalSupply = parseInt(responseTwo.data.result.slice(2,60),16)/ 1000000000000000000
    // totalSupply = totalSupply+(196590.18*30*6)

    })).catch(errors => {
      // react on errors.
    })

    let r_min = 0.02, r_max = 0.12, r_point = 0.7;

    let delegationRate = delegatedSupply / totalSupply;


    if(delegationRate >= 0.7) delegationRate = 0.7;
    let r_rep = (((r_max - r_min) / (r_point ** 2)) * (delegationRate - r_point) ** 2 + r_min)*3;
    this.setState({ rate: r_rep })

  }

    const total = simpleReturn(this.state.lumpSumValue, this.state.rate)
    this.setState({ total: total, totalDay: (total/365), totalMonth: ((total/365)*30), interest: ((this.state.rate)*100) })
  }

  onCalcInputChange(e) {
    const target = e.currentTarget
    this.setState({ [`${target.id}Value`]: parseFloat(target.value) || '', show:true }, this.calculate)
  }

  render() {
    return (
      <React.Fragment>

        <div className="calculator-wrap">
        <span className="float-left pb-2 d-none d-md-block">{this.lex('interest')+' '} <span id="totalDay"><NumberFormat decimalScale='2' value={this.state.interest} displayType={'text'} thousandSeparator={true} suffix={this.lex('pa')}/></span>
        </span>
          <CalcInput
            id="lumpSum"
            onChange={this.onCalcInputChange.bind(this)}
            value={this.state.lumpSumValue}
            step="any"
            min="-10"
            max="1000000000" />

            {/*(show) ? (*/}
            <div className="container mt-2">
            <div className="row">
            <div className="col-6 pl-0 pr-1">
            <div className="input-group">

            <NumberFormat decimalScale='2' className="form-control text-color" disabled value={this.state.totalMonth} displayType={'input'} thousandSeparator={true}/>
            <div className="input-group-append">
            <div className="input-group-text media-color">{this.lex('month')}</div>
          </div>
          </div>
          </div>
          <div className="col-6 pl-1 pr-0">
          <div className="input-group">

          <NumberFormat decimalScale='2' className="form-control text-color" disabled value={this.state.total} displayType={'input'} thousandSeparator={true} />
          <div className="input-group-append">
          <div className="input-group-text media-color">{this.lex('year')}</div>
        </div>
        </div>
          </div>

          </div>
          </div>{/*) : '' */}

        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation('common')(SimpleCalculator);
