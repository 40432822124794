import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import Home from './../Home';
import Block from './../Block';
import Blocks from './../Blocks';
import Address from './../Address';
import Transaction from './../Transaction';
import AppHeader from './../AppHeader';
import AppFooter from './../AppFooter';
import Governance from './../Governance';
import NodeTracker from './../System/nodes';
import NotFoundPage from './../NotFoundPage';
import ScrollToTop from './../ScrollToTop';
import Proposal from './../Proposal';
import Proposals from './../Proposals';
import Transactions from './../Transactions';
import StakingCalc from './../System/calc';
import Dapps from './../Dapps';
import Token from './../Token';
import Tokens from './../Tokens';
import Convert from './../Convert';
import System from './../System';
import Chart from './../Chart';
import Charts from './../Chart/charts';
import Wallet from './../Wallet/';
import Prep from './../Convert/prep';
import axios from 'axios'
import {PageView, initGA} from './../Tracking';
import { withTranslation } from 'react-i18next';
import {getCurr} from './../utils/funcs'

import ScrollTop from 'react-scrolltop-button';

class App extends Component {

  constructor(props) {
  super(props);
  this.state = {
    currency: null,
    totalVol:0,
    mult:1,
    icxusd:0,
    change:0
  }
}

componentDidMount() {
    axios.get('./../data/data.json').then(res=>this.setState({totalVol:res.data.totalVol,mult:res.data.forex[getCurr()],icxusd:res.data.icxusd,change:res.data.priceChangePercent}))
    this.setState({currency:getCurr()})
    initGA('UA-146105422-2');
    PageView();
  }

handleCurrency = (e) => {
  this.setState({currency:e.target.id})
  localStorage.setItem("currency",e.target.id)
  axios.get('./../data/data.json').then(res=>this.setState({mult:res.data.forex[getCurr()],icxusd:res.data.icxusd,change:res.data.priceChangePercent}))
}

  render() {
    const {currency, mult, icxusd, change, totalVol} = this.state
    return (
      <div className="App">

        <Router>
        <ScrollToTop>
        <div id="container">

        <AppHeader handleCurrency={this.handleCurrency} currency={currency} mult={mult} icxusd={icxusd} change={change} />
        <div id="main">
        <Switch>

        {/* <Route exact path="/" component={Home}/> */}
        <Route exact path="/" render={()=><Home currency={currency} mult={mult} icxusd={icxusd} change={change} totalVol={totalVol}/>}/>
        <Route exact path="/blocks" component={Blocks}/>
        <Route exact path="/transactions" component={Transactions}/>
        <Route exact path="/tokens" render={()=>(<Tokens mult={mult} icxusd={icxusd} />)} />
        <Route exact path="/contracts" render={()=>(<Tokens mult={mult} icxusd={icxusd} />)} />
        <Route exact path="/live" component={Transactions}/>
        <Route exact path="/governance" render={()=>(<Governance mult={mult} icxusd={icxusd} />)} />
        <Route exact path="/proposals" component={Proposals}/>
        <Route exact path="/dapps" component={Dapps}/>
        <Route exact path="/system" component={System}/>
        <Route exact path="/convert" component={Convert}/>
        <Route exact path="/charts" component={Charts}/>
        <Route exact path="/wallet/create-account" component={Wallet}/>
        <Route exact path="/wallet/vote" component={Wallet}/>
        <Route exact path="/wallet/transfer" component={Wallet}/>
        <Route exact path="/wallet" component={Wallet}/>
        <Route exact path="/prepcalc" render={()=>(<StakingCalc currency={currency} mult={mult} icxusd={icxusd} />)} />
        <Route exact path="/prep" component={Prep}/>
        <Route exact path="/nodetracker" component={NodeTracker}/>
        <Route path="/chart/:chart" component={Chart}/>
        <Route path="/blocks/:currentPage" component={Blocks}/>
        <Route path="/transactions/:currentPage" component={Transactions}/>
        <Route path="/block/:blockHash" render={()=>(<Block currency={currency} mult={mult} icxusd={icxusd} />)} />
        <Route path="/token/:addrHash" render={()=>(<Token currency={currency} mult={mult} icxusd={icxusd} />)}/>
        <Route path="/contract/:addrHash" render={()=>(<Address currency={currency} mult={mult} icxusd={icxusd} />)}/>
        <Route path="/address/:addrHash" render={()=>(<Address currency={currency} mult={mult} icxusd={icxusd} />)}/>
        <Route path="/tx/:txHash" render={()=>(<Transaction currency={currency} mult={mult} icxusd={icxusd} />)}/>
        <Route path="/proposal/:txHash" component={Proposal}/>
        <Route path="/transaction/:txHash" component={Transaction}/>
        <Route component={NotFoundPage} />


        </Switch>
        </div>
        </div>
        <AppFooter />

        </ScrollToTop>

        </Router>


        <ScrollTop
          text='&#x2912;'
          style={{fontWeight:'bold',
                  fontSize:'1rem'}}
        distance={1200}
          speed={500}
          target={0}
        />
        </div>
    );
  }
}


export default withTranslation('common')(App);
