import BigNumber from 'bignumber.js'
import axios from 'axios'
import {API} from './util'


export const handleFocus = (event) => event.target.select();

export const addDefaultSrc = (ev) => { ev.target.src = './../logos/noprofile.svg'}

export function numberFormat(n,d) {
  let dec = parseInt(d)
  return BigNumber(n.slice(2,60),16).multipliedBy(1/(dec < 2 ? 1 : 10**dec)).toFixed();
}

export function apiFormat(n) {
  return BigNumber(n.slice(2,60),16).toFixed();
}

export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function arrayRemove(arr, value) {

   return arr.filter(function(ele){
       return ele !== value;
   });

}

export const check = localStorage.wallet !== '' || typeof(localStorage.wallet) !== 'undefined'

export const getCurr = () => localStorage.getItem('currency') === null ? 'USD' : localStorage.currency

export const currSymbol = () => {
  let symbol;
  switch(localStorage.currency) {
    case ('AUD'):
      symbol='AU$'
      break;
    case ('CAD'):
      symbol='CA$'
      break;
    case ('CNY'):
      symbol='CN¥'
      break;
    case ('EUR'):
      symbol='€'
      break;
    case ('GBP'):
      symbol='£'
      break;
    case ('HKD'):
      symbol='HK$'
      break;
    case ('INR'):
      symbol='₹'
      break;
    case ('JPY'):
      symbol='JP¥'
      break;
    case ('KRW'):
      symbol='₩'
      break;
    case ('CZK'):
      symbol='Kč'
      break;
    case ('RUB'):
      symbol='₽'
      break;
    case ('USD'):
    default:
      symbol='$'                              
  }
  return symbol;
}

export const isAddr = (hex) => {
  let re = /[0-9A-Fa-f]{40}/g
  let str = hex.substr(2,42)
  return re.test(str)
}

export const isTx = (hex) => {
  let re = /[0-9A-Fa-f]{64}/g
  let str = hex.substr(2,66)
  return re.test(str)
}

export const isContr = (hex) => {
  return hex.substr(0,2) === 'cx'
}

export const call = (b) => axios.post(API,b)

export const request = (link) => axios.get(link)