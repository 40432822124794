import React, { useState, useRef,useEffect } from 'react';
import Posts from './posts';
import Pagination from './../Pagination';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {Table} from 'react-bootstrap'
import {wait} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';

function useInterval(callback, delay) {
  const savedCallback = useRef();


  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

  const Pagify = ({forcedPage, props, live, interval, preps, t}) => {
    const lex = (w) => t(w, { framework: "react-i18next" })


  const [posts, setPosts] = useState({listSize:0,blocks:[{"timestamp":1576849851877092}]});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(forcedPage !== 0 || typeof(forcedPage) !== 'undefined' ? 1 : forcedPage);
  // const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);
  // const [leaders, setLeaders] = useState([]);
  const [delay] = useState(interval);
  let [count, setCount] = useState(0);
  // let [count, setCount] = useState(0);


  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      // await (axios.get(`/data/thes`))
      // .then(mes => {
      //   setLeaders(mes.data);
      // })

      let link;
      link = 'https://api.iconwat.ch/blocks/?page='+currentPage+'&count='+postsPerPage

      const res = await axios.get(link);

      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage, postsPerPage]);


  useInterval(() => {
    const fetchPosts = async () => {
      // setLoading(true);

      let link;
      link = 'https://api.iconwat.ch/blocks/?page='+currentPage+'&count='+postsPerPage

      const res = await axios.get(link);

      setPosts(res.data);
      // setLoading(false);
    };
    if (live === true) fetchPosts();


    setCount(count + 1);
  }, delay, [live]);


  const currentPosts = posts.blocks


  const paginate = pageNumber => {setCurrentPage(pageNumber)
  window.scrollTo(0, 0)
};

  return loading
  ? wait()
  : ((typeof(posts.blocks) === 'undefined') || (posts.blocks === null))
  ? 'Nothing here yet!'
  : (

    <div className="text-left">
    <Table className="text-color" hover>
    <thead>
        <tr>
        <th colSpan="2">#</th>
        <th colSpan="3" className="d-none d-lg-table-cell">{lex('time')}</th>
        <th colSpan="3" className="d-none d-lg-table-cell">{lex('hash')}</th>
        <th colSpan="1">{lex('txx')}</th>
        <th colSpan="2" className="d-none d-md-table-cell">{lex('bp')}</th>
        <th colSpan="2">{lex('value')}</th>
        <th colSpan="2" className="d-none d-lg-table-cell">{lex('fee')}</th>
        </tr>
    </thead>
      <tbody id="txs">
      <Posts currentPage={currentPage} posts={currentPosts} loading={loading} leaders={preps} />
      </tbody>
    </Table>
    <div className={`pl-2 pb-0 mb-0 pt-2 ${(live===true) ? 'd-none' : ''}`}>
    <div className={posts.total < 100 ? 'd-none' : 'd-block' }>
    <Pagination
      currentPage={currentPage}
      postsPerPage={postsPerPage}
      totalPosts={posts.total}
      paginate={paginate}

    />
    </div>
    </div>
    </div>

  );
};

export default withTranslation('common')(withRouter(Pagify));
