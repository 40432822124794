import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './../assets/logo.svg';


class NotFoundPage extends Component {

    render(){
        return <div className="p-3">
        <h2>404?</h2>
        <img
            src={logo}
            width="11%"
            height="11%"
            className="d-inline-block"
            alt="ICONwatch logo"
          />            <p className="p-3" style={{textAlign:"center"}}>
              <Link className="btn btn-primary" to="/">Go to Home</Link>
            </p>
          </div>;
    }
}
export default NotFoundPage;
