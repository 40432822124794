export const getBalance = (address) => '{"jsonrpc":"2.0","id":1577509156742,"method":"icx_getBalance","params":{"address":"'+address+'"}}'

export const getStake = (address) => '{"jsonrpc":"2.0","id":1577513268363,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getStake","params":{"address":"'+address+'"}}}}'

export const queryIScore = (address) => '{"jsonrpc":"2.0","id":1577513268452,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"queryIScore","params":{"address":"'+address+'"}}}}'

export const getDelegation = (address) => '{"jsonrpc":"2.0","id":1577513268567,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getDelegation","params":{"address":"'+address+'"}}}}'

export const getPRep = (address) => '{"jsonrpc":"2.0","id":1577513268727,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPRep","params":{"address":"'+address+'"}}}}'

export const getProposal = (address) => '{"jsonrpc":"2.0","id":3631116711,"method":"icx_call","params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000001","dataType":"call","data":{"method":"getProposal","params":{"id":"'+address+'"}}}}'


//contracts

export const getName = (address) => '{"jsonrpc":"2.0","method":"icx_call","params":{"from":"hx23ada4a4b444acf8706a6f50bbc9149be1781e13","to":"'+address+'","dataType":"call","data":{"method":"name"}},"id":1234}'

export const getScoreStatus = (address) => '{"jsonrpc":"2.0","id":3639752025,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000001","dataType":"call","data":{"method":"getScoreStatus","params":{"address":"'+address+'"}}}}'

export const getScoreApi = (address) => '{"jsonrpc":"2.0","id":1234,"method":"icx_getScoreApi","params":{"address":"'+address+'"}}'

export const getDecimals = (address) => '{"jsonrpc":"2.0","method":"icx_call","params":{"from":"hx23ada4a4b444acf8706a6f50bbc9149be1781e13","to":"'+address+'","dataType":"call","data":{"method":"decimals"}},"id":1234}'

// block

export const getBlockHeight = (block) => '{"jsonrpc":"2.0","id":1577495919111,"method":"icx_getBlockByHeight","params":{"height":"'+block+'"}}'

export const getBlockHash = (block) => '{"jsonrpc":"2.0","id":1577502038080,"method":"icx_getBlockByHash","params":{"hash":"'+block+'"}}'

// tx

export const getTxHash = (tx) => '{"jsonrpc":"2.0","id":1577509156742,"method":"icx_getTransactionByHash","params":{"txHash":"'+tx+'"}}'

export const getTxResult = (tx) => '{"jsonrpc":"2.0","id":1577509156900,"method":"icx_getTransactionResult","params":{"txHash":"'+tx+'"}}'


// global 

export const getLastBlock = () => '{"jsonrpc":"2.0","id":1577509157053,"method":"icx_getLastBlock"}'

export const getTotalSupply = () => '{"jsonrpc": "2.0","method": "icx_getTotalSupply","id": 1234}'

export const getPreps = () => '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPReps"}}}'

export const getIIS = () => '{"jsonrpc":"2.0","method":"icx_call","id":2329967368,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getIISSInfo"}}}'