import React, { Component } from 'react';
import {Spinner} from 'react-bootstrap'
import RenderProps from './renderProps';
import { withTranslation } from 'react-i18next';


class Proposals extends Component {

handlePageChange(page) {
    const { onPageChange } = this.props;
    onPageChange(page);
}


constructor(props) {
  super(props);
  this.state = {
    isLoading: false,
    preps:[],
    proposals:[]
  }
}

lex = (w) => this.props.t(w, { framework: "react-i18next" })


componentDidMount() {
  this.setState({isLoading:true})
  document.title = 'ICON Network Votes';

  this.setState({isLoading:false})
}


render() {
  const {isLoading} = this.state

if (isLoading) {
return         <div className="p-5"><Spinner animation="border" variant="info" /></div>
;
}
return (
  <div>
    <div className="Proposals">
      <div className="container mt-4">
        <div className="row">
          <div className="col-12">
            <div className="card p-3 border-0">
            <div className="container">
              <div className="row grey-border">
              <div className="col-sm-12 text-left v-100">
              <h4 className="display-5 mt-2 d-flex align-items-center">
              {this.lex('proposals')}
              </h4>
              </div>
              </div>
            </div>

              <RenderProps />
                {/*this.renderProposals(proposals, preps)*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

);
}
}

export default withTranslation('common')(Proposals);
