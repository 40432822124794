import React, {Component} from 'react'
import LineChart from './highchart'
import {Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap'
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faInfoCircle, faSync} from "@fortawesome/free-solid-svg-icons";
import {wait} from './../Clipboard/wait'
import ChartsFooter from './footer'
import {API} from './../utils/util'


const Serve = API


class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title:'Daily Transactions',
      fall:8,
      subtitle:"This chart displays the number of daily transactions on the ICON blockchain.",
      isLoading: true,
      supply:0,
      circulation:0,
      icxusd:0,
      treasury:0,
      stake:0,
      wallets:0,
      votes:0,
      voters:0,
      delegation:0,
      transactions:0,
      voteRewards:0,
      repMoney:0,
      inflation:0,
      interest:0
    };
  }

  _isMounted = false;


componentDidMount() {
  this._isMounted = true;
  this.isLoading = true;
    // console.log(this.props)
    this.doStuff()

  }

async doStuff() {
  let title;
  switch(this.props.match.params.chart) {
    case('transactions'):
      this.setState({fall:1,title:'Daily Transactions Chart',subtitle:'This chart displays the number of daily transactions on the ICON blockchain.'})
      title = 'Daily Transactions Chart';
    break;
    case('volume'):
      this.setState({fall:2,title:'Daily Volume Chart',subtitle:'The ICON (ICX) daily Volume chart shows the daily historical Volume of ICX transacted.'})
      title = 'Daily Volume Chart';
    break;
    case('fees'):
      this.setState({fall:3,title:'Daily Transaction Fees Chart',subtitle:'The daily Transaction Fees chart shows the historical daily fees of the ICON network.'})
      title = 'Daily Transaction Fees Chart';
    break;
    case('price'):
      this.setState({fall:4,title:'Daily Price Chart',subtitle:'The Daily Price chart shows the daily historical price for ICX in USD.'})
      title = 'Daily Price Chart';
    break;
    case('blocktime'):
      this.setState({fall:5,title:'Average Block Time Chart',subtitle:'The Average Block Time Chart shows the historical average time taken in seconds for a block to be included in the ICON blockchain.'})
      title = 'Average Block Time Chart';
    break;
    case('totaltx'):
      this.setState({fall:6,title:'Total Transactions Chart',subtitle:'The Total Transactions chart shows the transaction growth on the ICON blockchain over time.'})
      title = 'Total Transactions Chart';
    break;
    case('supply'):
      this.setState({fall:7,title:'Total Supply and Market Capitalization',subtitle:'The Total Transactions chart shows the transaction growth on the ICON blockchain over time.'})
      title = 'Total Supply and Market Capitalization';
      this.getSupply()
    break;
    case('iconomics'):
      this.setState({fall:8,title:'Staking Economics',subtitle:'Insight into some of ICON\'s \'hidden\' network statistics.'})
      title = 'Staking Economics';
      this.getSupply()
    break;
    case('averagefee'):
      this.setState({fall:9,title:'Average Fee',subtitle:'The Average Fee Chart shows the historical average daily fee used on the ICON network.'})
      title = 'Average Fee';
    break;
    case('dailyusers'):
      this.setState({fall:10,title:'Daily Users',subtitle:'The Daily Users Chart shows the daily users of the ICON network.'})
      title = 'Daily Users';
    break;
    case('addresses'):
      this.setState({fall:11,title:'Addresses',subtitle:'The Addresses Chart shows the total number of unique addresses that have transacted on the ICON Network.'})
      title = 'Daily Users';
    break;
    case('claims'):
    this.setState({fall:12,title:'i_Score Claims',subtitle:'The i_Score Claims Chart shows the amount of ICX claimed daily on the ICON Network.'})
    title = 'i_Score Claims';
    break;
    default:
    this.setState({fall:1,title:'Daily Transactions',subtitle:'This chart displays the number of daily transactions on the ICON blockchain.'})
    document.title = 'Daily Transactions';

  }
  document.title = 'ICON ' + title
  this.isLoading = false;

}

async getSupply(){
  let request1 = axios.get('https://tracker.icon.foundation/v0/main/mainInfo')
  let request2 = axios.get('./../data/data.json');
  let request3 = axios.post(Serve,'{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPReps"}}}')
  await axios.all([request1, request2, request3]).then(axios.spread((...responses) => {
    const responseOne = responses[0]
    const responseTwo = responses[1]
    const responseThree = responses[2]
    if (this._isMounted) {

    let rMin = 0.02;
    let rMax = 0.12;
    let rPoint = 0.7;

    let totalDelegated = (parseInt(responseThree.data.result.totalDelegated.slice(2,50),16)/1000000000000000000)
    let supply = responseOne.data.tmainInfo.icxSupply
    let delRate = totalDelegated / supply;

    if(delRate >= rPoint) {

        delRate = rPoint;
    }
    let rRep = ((rMax - rMin) / (rPoint ** 2)) * (delRate - rPoint) ** 2 + rMin;


    let voteRewards = ((totalDelegated * (rRep)) / 365) * 3;

    let repMoney = 0;
    let reps = responseThree.data.result.preps

    for (let i=0;i<reps.length;i++) {
    let money = 0
    let delegated = parseInt(reps[i].delegated.slice(2,50),16)/1000000000000000000;
    let irep = parseInt(reps[i].irep.slice(2,50),16)/1000000000000000000;

    i <= 21
    ? money = (irep * (1 / 2) * 100 * (delegated / totalDelegated)) + (irep * (1 / 2))
    : money = (irep * (1 / 2) * 100 * (delegated / totalDelegated))

    repMoney += money;

    }
    repMoney = repMoney/30

    let inflation = ((((repMoney*30)*12) + (voteRewards*365)) / supply) * 100
    let interest = rRep * 100 * 3

   this.setState({interest:interest,inflation:inflation,repMoney:repMoney,voteRewards:voteRewards,supply:supply,circulation:responseOne.data.tmainInfo.icxCirculationy,treasury:responseOne.data.tmainInfo.publicTreasury,icxusd:responseTwo.data.icxusd,wallets:responseTwo.data.accounts,votes:responseTwo.data.votes,voters:responseTwo.data.voters,transactions:responseTwo.data.transactions,stake:parseInt(responseThree.data.result.totalStake.slice(2,50),16)/1000000000000000000,delegation:totalDelegated})
  }
}

))
}

// componentWillUnmount() {
//   this._isMounted = false;
// }

  async componentDidUpdate(prevProps, prevState) {
  if(prevProps.match.params.chart!==this.props.match.params.chart){
    this.isLoading = true;
    this.doStuff()

    // this.isLoading = false;
  }
}



  render (){
    const {isLoading, title, subtitle, fall, supply, circulation, icxusd, treasury, stake, wallets, voters, delegation, voteRewards, repMoney, inflation, interest} = this.state

    if (isLoading) {wait()}

    if (fall === 8) {
      if (supply === 0 || stake === 0) {
        return wait();
      }
      return(
        <div>
        <div className="container mt-3 mb-3">
        <Row>
        <Col>
          <div className="card text-color border-0 shadow-sm">
          <div className="card-body rounded">
          <div className="grey-border p-2 mb-3 col-md-12 text-left">
              <h4 className="display-5">{title}</h4>
              <h6 className="d-none d-md-inline text-secondary">{subtitle}</h6>
          </div>
          <Row>
          <Col sm={12} md={6}>
          <table className="table text-color text-left">
            <tbody>
            <tr>
            <th className="border-top-0 h5" colSpan="2">Staking Data</th>
          </tr>
            <tr>
            <td className="border-top-0">Daily Rep Rewards:</td>
          <td className="border-top-0">
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"><NumberFormat decimalScale='2' value={repMoney*icxusd} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Tooltip>}><NumberFormat decimalScale='2' value={repMoney} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></OverlayTrigger>
          </td>
          </tr>
          <tr>
            <td>Daily Iconist Rewards:</td>
          <td>
           <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"><NumberFormat decimalScale='2' value={voteRewards*icxusd} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Tooltip>}><NumberFormat decimalScale='2' value={voteRewards} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></OverlayTrigger>
          </td>
          </tr>
          <tr>
            <td>Combined Rewards: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">P-Rep + Iconist Rewards</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
          <td>
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"><NumberFormat decimalScale='2' value={(repMoney + voteRewards)*icxusd} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Tooltip>}><NumberFormat decimalScale='2' value={repMoney + voteRewards} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></OverlayTrigger>
          </td>
          </tr>
          <tr>
            <td>Current Inflation:</td>
          <td>
          <NumberFormat decimalScale='2' value={inflation} displayType={'text'} thousandSeparator={true} suffix={'% p.a.'}/>
          </td>
          </tr>
          <tr>
            <td>Interest Rate:</td>
          <td>
          <NumberFormat decimalScale='2' value={interest} displayType={'text'} thousandSeparator={true} suffix={'% p.a.'}/>
          </td>
          </tr>
          <tr>
            <td>Total Users: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Data by <FontAwesomeIcon icon={faServer} /> Blockmove</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
          <td>
          <NumberFormat decimalScale='2' value={wallets} displayType={'text'} thousandSeparator={true}/>
          </td>
          </tr>
          <tr>
            <td>Unique Voters: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Data by <FontAwesomeIcon icon={faServer} /> Blockmove</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
          <td>
          <NumberFormat decimalScale='2' value={voters} displayType={'text'} thousandSeparator={true}/>
          </td>
          </tr>
          <tr>
          <td>Total Delegation:</td>
        <td>
        <NumberFormat decimalScale='2' value={delegation} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
        </td>
        </tr>
        <tr>
          <td>Total Stake:</td>
        <td>
        <NumberFormat decimalScale='2' value={stake} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
        </td>
        </tr>
            </tbody>
          </table>

          </Col>
          <Col sm={12} md={6}>

      <LineChart fall={(typeof (this.props.match.params.chart) === 'undefined') ? 'transactions' : this.props.match.params.chart} supply={supply} locked={supply - circulation} circulation={circulation} treasury={treasury} stake={stake} />
      </Col>
      </Row>
      </div>
      </div>
      </Col>

      </Row>
      </div>
      <ChartsFooter />
      </div>
    )}

    if (fall === 7) {
      if (supply === 0) {
        return wait()
      }
      return(
        <div>
        <div className="container mt-3 mb-3">
        <Row>
        <Col>
          <div className="card text-color border-0 shadow-sm">
          <div className="card-body rounded">
          <div className="grey-border p-2 mb-3 col-md-12 text-left">
              <h4 className="display-5">{title}</h4>
              <h6 className="d-none d-md-inline text-secondary">{subtitle}</h6>
          </div>
          <Row>
          <Col sm={12} md={6}>

                    <table className="table text-color text-left">
                      <tbody>
                      <tr>
                      <th className="border-top-0 h5" colSpan="2">Market Data </th>
                    </tr>
                      <tr>
                      <td className="border-top-0">Current Price: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Data by <FontAwesomeIcon icon={faSync} /> Binance</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
                    <td className="border-top-0">
                    <NumberFormat decimalScale='4' value={icxusd} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                    </td>
                    </tr>
                    <tr>
                      <td>Market Cap: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Market Cap for Circulating Supply</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
                    <td>
                    <NumberFormat decimalScale='2' value={icxusd*circulation} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                    </td>
                    </tr>
                    <tr>
                    <td>Total Market Cap: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Market Cap for Total Supply</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
                  <td>
                  <NumberFormat decimalScale='2' value={icxusd*supply} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                  </td>
                  </tr>
                      </tbody>
                    </table>
          <table className="table text-color text-left">
            <tbody>
            <tr>
            <th className="border-top-0 h5" colSpan="2">ICX Distribution Overview</th>
          </tr>
            <tr>
            <td className="border-top-0">Total Supply:</td>
          <td className="border-top-0">
          <NumberFormat decimalScale='2' value={supply} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
          </td>
          </tr>
          <tr>
            <td>Genesis Supply:</td>
          <td>
          <NumberFormat decimalScale='2' value={800460000} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
          </td>
          </tr>
          <tr>
            <td>New Supply: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Staking Rewards</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
          <td>
          <NumberFormat decimalScale='2' value={supply - 800460000} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
          </td>
          </tr>
          <tr>
          <td>Locked Supply: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Data by <FontAwesomeIcon icon={faServer} /> ICON Foundation</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></td>
        <td>
        <NumberFormat decimalScale='2' value={supply - circulation} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
        </td>
        </tr>
        <tr>
          <td>Circulating Supply:</td>
        <td>
        <NumberFormat decimalScale='2' value={circulation} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
        </td>
        </tr>


            </tbody>
          </table>

          </Col>
          <Col sm={12} md={6}>

          <LineChart fall={(typeof (this.props.match.params.chart) === 'undefined') ? 'transactions' : this.props.match.params.chart} supply={supply} />
          </Col>
          </Row>
          </div>
          </div>
          </Col>

          </Row>
          </div>
            <ChartsFooter />
          </div>


      )
    }

    return(
      <div>
    <div className="container mt-3 mb-3">
    <Row>
    <Col>
      <div className="card text-color border-0 shadow-sm">
      <div className="card-body rounded">
      <div className="grey-border p-2 mb-3 col-md-12 text-left">
          <h4 className="display-5">{title}</h4>
          <h6 className="d-none d-md-inline text-secondary">{subtitle}</h6>
      </div>
      <Row>
      <Col>
      <LineChart fall={(typeof (this.props.match.params.chart) === 'undefined') ? 'transactions' : this.props.match.params.chart} />
      </Col>
      </Row>
      </div>
      </div>
      </Col>

      </Row>
      </div>
        <ChartsFooter />
      </div>
    )

  }
}

export default Chart
