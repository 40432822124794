import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';

const COPY_IMPORT_TEXT = 'Open Qrcode';

const QRCodes = ({ name, type }) => {
  const [text] = useState(COPY_IMPORT_TEXT);


  function Qrshow() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
      {type === 'menu' 
      ? <li onClick={handleShow} className='dropdown-bg dropdown-item text-color font-weight-light pointer' ><span className="text-color"><FontAwesomeIcon icon={faQrcode} />&nbsp;Qr Code</span></li>
      :(<div onClick={handleShow} className="small ml-3 text-color pointer">
            <FontAwesomeIcon className="small" icon={faQrcode} />
          </div>)}

        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Header className="text-color dropdown-nohover" closeButton>
            <Modal.Title>QrCode</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center text-color dropdown-nohover p-5">
          <QRCode value={name} /><br /><br />
          <span className="text-break">{name}</span>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <Qrshow />

  );
};
export default QRCodes

// export default ({ name, type}) => (
//   <>
// 
// <QRCodes name={name} />
//   </>
// );
