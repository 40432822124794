import { faClipboard } from '@fortawesome/free-regular-svg-icons/faClipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copy from 'copy-text-to-clipboard';
import React, { useCallback, useMemo, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { withTranslation } from 'react-i18next';

const ClipBoard = ({ name, type , t}) => {
  const lex = (w) => t(w, { framework: "react-i18next" })
  const copied = lex('copied')
  const copyme = lex('copy')
  const [text, setText] = useState(lex('copy')+type);
  let tpe = type;
  const textToCopy = useMemo(
    () => name,
    [name],
  );



  const handleCopy = useCallback(() => {
    copy(textToCopy);
    setText(copied);
    setTimeout(() => setText(copyme+ tpe), 2000);
  }, [textToCopy,tpe,copied,copyme]);



  return (
    <OverlayTrigger trigger='hover' placement="right"
      overlay={<Tooltip id={`copy ${name}`}>{text}</Tooltip>}
    >
      <div onClick={handleCopy} className="small ml-3 text-color pointer">
        <FontAwesomeIcon className="small" icon={faClipboard} />
      </div>
    </OverlayTrigger>
  );
};

// export default ClipBoard;

export default withTranslation('common')(ClipBoard)

//  => (
//   <>
//     <ClipBoard name={name} type={type} />
//   </>
// );
