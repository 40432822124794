import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import {API} from './../utils/util'
import {currSymbol} from './../utils/funcs'
import {Tooltip,OverlayTrigger,Row} from 'react-bootstrap'
import {getTotalSupply} from './../utils/calls'


class MarketData extends Component {

  state = {
    lumpSumValue: 100,
    rate: null,
    supply:0,
    total: 0,
    totalDay: 0,
    totalMonth: 0,
    min: 0,
    show:false,
    interest: 0,
    max: 1000000000000000000

  }


  
  lex = (w) => this.props.t(w, { framework: "react-i18next" })

  async componentDidMount() {
    let supply;

    await axios.post(API,getTotalSupply()).then(x=>{
        supply=(parseInt(x.data.result.slice(2,50),16)/1000000000000000000)-299411698
    }
    )
    
    this.setState({supply})
  }

  render() {
    const {change,icxusd,mult,totalVol} = this.props
    const {supply} = this.state
    return (
      <React.Fragment>

        <div className="container pb-2 pl-0 pr-0 m-0">

        <div className="d-flex grey-border ml-0 pl-0 col-md-12 text-left">
            <div className="d-flex flex-grow-1 pb-0">
        <img
                                         src='logos/noprofile.svg'
                                         className="p-1 mr-2"
                                         width="40"
                                         height="40"
                                         alt={'icon'}
                                       /> 
            <h6>
            ICX
            <br/>
            <Link to={`/chart/price`}>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-left">{this.lex('icxpricechart')}</Tooltip>}>

            <NumberFormat className='text-color' value={icxusd*mult} decimalScale={4} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} />
            </OverlayTrigger>
            </Link>
            </h6>
            </div>
            <div className="d-flex pb-0 align-self-center">
            <span className={change < 0 ? 'text-danger' : 'text-success'}><NumberFormat value={change} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={change >= 0 ? '+' : ''} suffix={'%'} /></span>
            </div>
        
      </div>
            
      <Row className="flex-column text-left p-0 m-0">
            <div>
            <span className="dropdown-header text-uppercase pl-1 mb-0 pb-0 h6">{this.lex('marketcap')}</span>
            <Link to={`/chart/supply`}>
            <span className="dropdown-item searchBtn text-color pl-1 mb-0"><NumberFormat value={icxusd*mult*supply} decimalScale={3} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} /></span>
            </Link>
            </div>
            <div>

            <span className="dropdown-header text-uppercase pl-1 pb-0 m-0 h6">{this.lex('24vol')}</span>
                          <NumberFormat className={'dropdown-item searchBtn text-color pl-1 mb-0 pb-0'} value={totalVol*mult} decimalScale={3} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} />
            </div>
            </Row>
            </div>


      </React.Fragment>
    );
  }
}

export default withTranslation('common')(withRouter(MarketData));
