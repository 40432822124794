import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import Moment from 'react-moment';
import {wait,tomApi} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';

const Posts = ({ currentPage, posts, loading, leaders }) => {

  return loading
  ? wait ()
  : posts.map((line, index) => {


    const { timestamp, txCount, hash, value, fee, producer, height } = line //destructuring

     return (
       <tr key={index}>
         <td colSpan="2"><Link to={`/block/${height}`}><NumberFormat decimalScale='0' value={height} displayType={'text'} thousandSeparator={true} prefix={''}/></Link></td>
         <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate"><Moment fromNow >{timestamp/1000}</Moment></td>
       <td colSpan="3" className="d-none d-lg-table-cell col-special text-truncate"><Link to={`/block/${hash}`}>{hash}</Link></td>
       <td colSpan="1" className="col-special text-truncate">{txCount}</td>
       <td colSpan="2" className="d-none d-md-table-cell col-special text-truncate">
       <div className="d-flex align-items-center">
     <Jazzicon diameter={18} seed={typeof(producer) === 'undefined' || producer === 0 ? 0 : jsNumberForAddress(producer)} />&nbsp;
     <Link to={`/address/${producer}`}> {typeof(leaders) === 'undefined' ? 0 : leaders[producer]}</Link>
     </div></td>
<td colSpan="2" className="col-special">
<Badge className='p-1 badge-width media-bg text-color'>
<NumberFormat decimalScale='2' value={tomApi(value,18)} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
</Badge></td>
     <td colSpan="2" className="col-special d-none d-lg-table-cell"><NumberFormat decimalScale='5' value={tomApi(fee,18)} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></td>
       </tr>
     )
   })
};

export default withTranslation('common')(withRouter(Posts));
