import React, { Component } from 'react';
import './style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator,faAngleDoubleRight,faLongArrowAltRight,faPoll} from "@fortawesome/free-solid-svg-icons";
import {withRouter, Link} from 'react-router-dom'
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import Pills from './../Pills';
import SimpleCalculator from './../SimpleCalculator';
import MarketData from './../MarketData';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import axios from 'axios';
import RepsTable from './../Governance/repstable';
import {Tabs, Tab} from 'react-bootstrap';
import LineChart from './../Chart/highcharts'
import Pagify from './../Blocks/paginate';
import { withTranslation } from 'react-i18next';

import Paginate from './../Transactions/paginate';
// const Paginate = React.lazy(() => import ('./../Transactions/paginate'));


class Home extends Component {
_isMounted = false;
intervalID;

constructor(props) {
  super(props);
  this.state = {
    key:1,
    isLoading: true,
    preps:{},
    tx: [],
    block: [{height:''}],
    block_value: [],
    new_transactions: [],
    block_times: [],
    links:[],
    countries:[],
    votes:[],
    productivity:[],
    names:[],
    prepss:{totalStake:'0x0',totalDelegated:'100',preps:[]}
    }
    this.scrollDiv = React.createRef();
}

async componentDidMount() {
  document.title = 'ICON Blockchain Explorer';
  this._isMounted = true;
  this.getHash()
      // this.getData();
  // this.intervalID = setInterval(this.getData.bind(this), 2500);

  await (axios.get(`/data/thes`))
  .then(res => {
    if (this._isMounted) {
    this.setState({preps:res.data})
  }
    })
    .catch(errors=>{})


}

async componentDidUpdate(prevProps, prevState) {
if(prevProps.location.hash!==this.props.location.hash){
  this.getHash() 
}
if(prevProps.location.search!==this.props.location.search){
  (typeof(this.props.location.search) === 'undefined' || this.props.location.search === '' ) ? this.setState({currPage:1}) : this.setState({currPage:parseInt(this.props.location.search.slice(6,20))})
}

}

getHash() {
  
  switch (this.props.location.hash) {
    case ('#tx'):
    this.setState({ isLoading: true, key:'2', hash:'#tx' })
    this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
    break;
    case ('#blocks'):
    this.setState({ isLoading: true, key:'3', hash:'#blocks' })
    this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
    break;
    case ('#reps'):
    this.setState({ isLoading: true, key:'1', hash:'#reps' })
    this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
    break;
    default:
    this.setState({ isLoading: true, key:'1'})
  }
}


componentWillUnmount() {
  clearTimeout(this.intervalID);
  this._isMounted = false;
}

// async getData() {
//   await axios.get('https://api.iconwat.ch/main')
//   // await axios.get(`/data/main.json`)
//   .then(res => {
//     if (this._isMounted) {
//     this.setState({block: res.data.blocks,tx:res.data.transactions});
//     }
//   })
//   .catch(errors=>{})

// }
lex = (w) => this.props.t(w, { framework: "react-i18next" })


// renderTransactions() {
//   const TxData = this.state.tx.slice(0,6)
//   return TxData.map((tx, index) => {
//      const { timestamp, hash, from, to, value } = tx //destructuring
//      return (
//        <div key={index} className="media">
//          <div className="media-body text-color text-truncate">
//            <div>
//          <h6>Tx&nbsp;
//            <Link to={`/tx/${hash}`}>
//               {typeof(hash) === 'undefined' ? '' : hash.slice(0,24)}...
//             </Link>
//             <span className="float-right d-none font-weight-light d-lg-block"><Moment fromNow unix="unix">{(timestamp/1000000)}</Moment></span>
//           </h6>

//          </div>
//        <div className={((from) === '') ? 'd-none' : ''}>
//       From:&nbsp;
//         <Jazzicon diameter={12} seed={(from) === '' ? 0 : jsNumberForAddress(from)} />&nbsp;
//   <Link to={(from) === '' ? '/' : '/address/'+from } >{(from) === '' ? '' :  from.slice(0,8)}...</Link>
//   &nbsp;<FontAwesomeIcon icon={faLongArrowAltRight} />
//   &nbsp;To:&nbsp;
//     <Jazzicon diameter={12} seed={(to) === '' ? 0 : jsNumberForAddress(to)} />&nbsp;
//  <Link to={(to) === '' ? '/' : '/address/'+to } >{(to) === '' ? '' :  to.slice(0,8)}...</Link>
//    <span className="float-right d-none d-md-block">
//     <FontAwesomeIcon icon={faAngleDoubleRight} />&nbsp;
//    <NumberFormat decimalScale='2' value={(value) === '' ? 0 : (value / 1000000000000000000)} displayType={'text'} thousandSeparator={true} prefix={'ICX '}/>&nbsp;
//    </span>
//    </div>
//    <div className={((from) !== '') ? 'd-none' : ''}>
//     <code className="text-color">System Call</code>
//    </div>
//      </div>
//      </div>
//      )
//    })
// }

// renderBlocks() {
//   const {preps} = this.state;
//   const Blocks = this.state.block.slice(0,6);

//   return Blocks.map((tx, index) => {
//      const { height, timestamp, txCount, producer} = tx //destructuring
//      return (


//        <div key={index} className="media">
//        <div className="flagIcon">
//          <Jazzicon diameter={22} seed={parseFloat(height)} />
//      </div>
//          <div className="media-body">
//          <h6>Block
// <Link to={`/block/${height}`}> <NumberFormat value={height} displayType={'text'} thousandSeparator={true}/></Link>
//   <span className="float-right d-none font-weight-light d-lg-block"><Moment fromNow unix="unix">{(timestamp/1000000)}</Moment></span>
//        </h6>
//      <div><span className="d-none d-lg-inline">Produced</span> by
//    <Link className="text-truncate" to={`/address/${producer}`}> {typeof(preps[producer]) !== 'undefined' ? preps[producer] : (producer) }</Link>
//    <span className="float-right"><b>{txCount}</b> Transaction(s)</span>

//  </div>

//      </div>
//      </div>
//      )
//    })
// }

handleClick = (e) => {
  // console.log('handleClick', e);
  this.setState({key:e})
  // console.log('test')
  switch (e) {
    case '1':
    default:
      return this.props.history.push("#reps")
    case '2':
      return this.props.history.push("#tx")
    case '3':
      return this.props.history.push("#blocks")
  }
}

render() {

  const {preps, key} = this.state
  const {change,icxusd,currency,mult,totalVol} = this.props


return (
  <div>
  <div className="container pt-0 mt-0">
  <Pills type="home" block={this.state.block[0].height} txcount={this.state.block[0].txCount} thes={preps} preps={this.state.prepss} icxusd={icxusd} mult={mult}/>

    <div className="row pr-2 pl-2 mt-3">
      <div className="col-12 col-md-8 pr-2 pl-2 mb-3 h-100">
          <div className="card border-0 shadow-sm">
              <LineChart title={this.lex('daily')} />
        </div>
        </div>

        <div className="col-12 col-md-4 pr-2 pl-2 mb-3 d-flex flex-column align-content-stretch flex-wrap h-100">
        <div className="calculator card border-0 shadow-sm pr-3 pl-3 pt-3">
            <div className="card-body p-0 m-0 h-50">
            {/* <div className="row">
              <div className="col-md-12">
                <h5 className="float-left font-weight-bold"><FontAwesomeIcon icon={faPoll} /> {this.lex('marketdata')}</h5>
              </div>
            </div> */}
            <MarketData icxusd={icxusd} mult={mult} totalVol={totalVol} change={change} />
            </div>
        </div>

        <div  className="calculator card border-0 shadow-sm pr-3 pl-3 pt-2 pb-2 mt-3">
            <div className="card-body p-1 h-50">
            <div className="row">
              <div className="col-md-12">
                <h5 className="float-left font-weight-bold"><FontAwesomeIcon icon={faCalculator} /> {this.lex('calc')}</h5>
              </div>
            </div>
            <SimpleCalculator />
            </div>
        </div>


      </div>
      </div>
      </div>

      {/*test*/}
      <div ref={this.scrollDiv} className="container">

      <div className="row pr-2 pl-2">

      <div className="col-12 pr-2 pl-2 mb-3">
          <div className="card border-0 shadow-sm">
         <Tabs id="profile-tabs" activeKey={key} onSelect={k => this.handleClick(k)} transition={false}>
          <Tab mountOnEnter={true} id="reps" eventKey="1" title={this.lex('preps')}>
          <RepsTable type="gov" icxusd={icxusd} mult={mult} />
          </Tab>
          <Tab mountOnEnter={true} id="transactions" eventKey="2" title={this.lex('ltx')}>
          {key !== '2'
          ? ''
          : <Paginate type={'transactions'} live={true} interval={2200} preps={preps} />
          }
          </Tab>
          <Tab mountOnEnter={true} id="blocks" eventKey="3" title={this.lex('lblocks')}>
          {key !== '3'
          ? ''
          : <Pagify type={'blocks'} live={true} interval={2200} preps={preps} />
          }
          </Tab>
          {/*
          <Tab mountOnEnter={true} id="system" eventKey="4" title={this.lex('stats')}>
          {key !== '4'
          ? ''
          : <System />
          }
          </Tab>*/}
          </Tabs>
            </div>
            </div>

      </div>
    </div>
    {/*test*/}

    </div>




);
}
}

export default withTranslation('common')(withRouter(Home));
