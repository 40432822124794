import React from 'react';
import { Link } from 'react-router-dom';
import {Spinner,Tooltip,OverlayTrigger} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import {Badge} from 'react-bootstrap'
import { FlagIcon } from './../Clipboard/flag';
import { withTranslation } from 'react-i18next';
import {currSymbol} from './../utils/funcs'

const Posts = ({ currentPage, posts, loading, leader,allposts, price, mult, type, days, t, irep }) => {
  const lex = (w) => t(w, { framework: "react-i18next" })

  if (loading) {
    return <tr className="p-5"><td><Spinner animation="border" variant="info" /></td></tr>;
  }

    const addDefaultSrc = (ev) => {
    ev.target.src = './../logos/noprofile.svg'
  }
  // console.log(irep)
  let iRep = (parseInt((irep.slice(0,50)),16)/1000000000000000000) || 0
  // console.log(iRep)

  // let topDelegated = 0;
  let totalDelegated = 0
  if (type !== 'blacklist') {

  allposts.forEach(function(element, index) {
  let delegated = parseInt((element.delegated.slice(0,50)),16)/1000000000000000000;
  totalDelegated += delegated
  })
  }

  let newiRep = iRep;

  return posts.map((prep, index) => {



     let { name, address, nodeAddress, delegated, validatedBlocks, grade, totalBlocks, country, penaltyStatus, penalty, unvalidatedSequenceBlocks } = prep //destructuring
     let img = './../logos/'+address+'.png'
     if (type === 'blacklist') {
      delegated = '0x0'
      irep = '0x'+(irep*1000000000000000000).toString(16)
      grade = penaltyStatus === 2 ? '0x5' : '0x6'
      }
     return (
       <tr key={index}>
       <td colSpan="1"><span className="d-flex align-items-center">{(index+1 + ((currentPage*40)-40))}</span></td>
         <td colSpan="5" className="text-truncate"><div className="d-flex d-flex align-items-center">
         <span className="logoIcon float-left"><img src={img} onError={addDefaultSrc} alt='' /></span>
         <Link to={`/address/${address}`}>{name}</Link></div>
         </td>
         <td colSpan="3">
         <Badge className={`p-1 m-auto badge-width text-color prime-badge btn text-uppercase ${(leader === nodeAddress) ? 'btn-outline-danger' : (grade === '0x0' && unvalidatedSequenceBlocks === '0x0' ) ? 'btn-outline-info' : (grade === '0x2' && penalty === '0x0') ? 'btn-outline-primary' : (grade === '0x5' || grade === '0x6' || (grade === '0x2' && penalty === '0x3') || (grade === '0x0' && unvalidatedSequenceBlocks !== '0x0')) ? 'btn-outline-warning' : 'btn-outline-secondary'}`}>{(leader === address) ? lex('leader') : (grade === '0x0') ? lex('main') : (grade === '0x1') ? lex('sub') : (grade === '0x5') ? lex('blacklist') : (grade === '0x6') ? 'WITHDRAW' : 'PREP'}</Badge>
         </td>
         <td colSpan="3" className= "d-none d-lg-table-cell"><div className="d-flex d-flex align-items-center">
         <FlagIcon code={country !== '' || typeof(country) !== 'undefined' ? country : 'WW'} size={20} />&nbsp;{country !== '' || typeof(country) !== 'undefined' ? country : 'WWW'}
         </div></td>
         <td colSpan="3" className="d-none d-md-table-cell">
         <NumberFormat value={(type === 'blacklist') ? '-' : (validatedBlocks/totalBlocks)*100} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={''} suffix={'%'}/>
         </td>
         {/*<td colSpan="3" className= "d-none d-lg-table-cell">
         <NumberFormat decimalScale='2' value={(parseInt((irep.slice(2,40)),16)/1000000000000000000).toLocaleString(undefined, {maximumFractionDigits: 0})} displayType={'text'} thousandSeparator={true} prefix={''}/>
         </td>*/}
         <td colSpan="3" className= "d-none d-lg-table-cell">
         <NumberFormat value={(type === 'blacklist') ? '-' : (parseInt((delegated.slice(2,40)),16)/1000000000000000000).toLocaleString(undefined, {maximumFractionDigits: 0})} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={''} suffix={''}/>
         </td>
         <td colSpan="4" className="d-none d-md-table-cell">
         <div className="d-flex flex-column">
         <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-left">{(type === 'blacklist') ? 'No Reward' : <NumberFormat value={((index+1 + ((currentPage*50)-50)) > 120) ? 0 : ((index+1 + ((currentPage*50)-50)) <= 22) ? (((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) + (newiRep * (1 / 2))) / 30)*price*mult * days : ((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) / 30)*price*mult  * days} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={currSymbol()}/>}</Tooltip>}>
         <NumberFormat value={(type === 'blacklist') ? '-' : ((index+1 + ((currentPage*50)-50)) > 120) ? 0 : ((index+1 + ((currentPage*50)-50)) <= 22) ? (((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) + (newiRep * (1 / 2))) / 30) * days : ((newiRep * (1 / 2) * 100 * ((parseInt((delegated.slice(2,40)),16)/1000000000000000000) / totalDelegated)) / 30) * days} decimalScale='2' displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
         </OverlayTrigger>
         </div>
         </td>
       </tr>
     )
   })
};

export default withTranslation('common')(Posts);
