import React, { Component } from 'react';
import logo from './../assets/logo.svg';
import { Navbar, Button, Dropdown, Nav,NavItem, Form, FormControl, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMoon, faDollarSign, faYenSign, faPoundSign, faRupeeSign, faEuroSign, faWonSign, faRubleSign, faICursor, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { withRouter, Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FlagIcon } from './../Clipboard/flag';
import NumberFormat from 'react-number-format';
import UnlockWithICONex from './../Clipboard/iconex'
import {currSymbol,getCurr} from './../utils/funcs'
import {langColor} from './../utils/style'

library.add(faDollarSign,faEuroSign,faWonSign,faRupeeSign,faPoundSign,faYenSign,faRubleSign,faICursor, faAngleDown);


console.log('*********\nVOTE\nFOR\nBLOCKMOVE\n*********')


class AppHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
  checked: localStorage.getItem("theme") === "dark" ? true : localStorage.getItem("theme") === "light" ? false : window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false,
  theme: localStorage.getItem("theme"),
  value: '',
  icxusd:0,
  mult:1,
  change:0,
  dropshow:false,
  dropshowGov:false,
  dropshowRes:false,
  dropshowBn:false,
  currency: null,
  language: this.equalsAny(localStorage.i18nextLng, 'zh', 'zh-HK', 'zh-CN', 'zh-TW', 'zh-SG') ? 'zh' : this.equalsAny(localStorage.i18nextLng, 'ko', 'ko-KR', 'ko-KP') ? 'ko' : 'en'

}
this.handleSubmit = this.handleSubmit.bind(this);
this.handleChange = this.handleChange.bind(this);

}
lex = (w) => this.props.t(w, { framework: "react-i18next" })

equalsAny (first) {
    return !![].slice.call(arguments, 1).filter(function (val) {
        return first === val;
    }).length;
}
  componentDidMount() {
    this.setState({currency:getCurr()})
    if ((localStorage.getItem("theme")) === null) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        localStorage.setItem("theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
      }
    }
    // Update the data-theme attribute of our html tag
    // axios.get('./../data/data.json').then(res=>this.setState({mult:res.data.forex[getCurr()],icxusd:res.data.icxusd,change:res.data.priceChangePercent}))
    
    
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
      this.setState({value: ''}) // <= here
  }
  
  onUnlockWallet = () => {
    this.props.history.push('/preps')
  }

  toggleThemeChange = () => {
    const { checked } = this.state;
    // If theme is light then change to dark
    if (checked === false) {
      // Update localstorage
      localStorage.setItem("theme", "dark");
      /**
       * The document.getElementsByTagName(...).setAttribute(...)
       * will only update the value
       */
      // Update the data-theme attribute of our html tag
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      // Update our state
      this.setState({
        // Ensure our switch is on if we change to dark theme
        checked: true
      });
    } else {
      // Update localstorage
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      // Update our state
      this.setState({
        // Ensure our switch is off if we change to light theme
        checked: false
      });
    }
  };

  handleChange(event) {
    // console.log(event.target.name)
    let key = event.target.name

    this.setState(
      {[key] : event.target.value});
  }

handleSubmit(event) {
  event.preventDefault();
    // console.log(this.state);
    let value = this.state.value.replace(/[^\w\s]/gi, '').toLowerCase();

    // const checkBlock = async (block_hash) => {
    //   let currBlockObj = 'test';
    //   currBlockObj = (await iconService.getBlockByHash(block_hash).execute());
    //
    //   // console.log(currBlockObj)
    //   return currBlockObj;
    // }
    let value2 = parseFloat(value)
    // console.log(value2)
    if (value.length === 0) {
    } else if (value.length <= 8 && isNaN(value2) !== true) {
      this.props.history.push('/block/' + value);
    } else if (value.slice(0,2) === 'cx') {
      this.props.history.push('/address/' + value);
    } else if (value.slice(0,2) === 'hx') {
      this.props.history.push('/address/' + value);
    } else if (value.slice(0,2) === '0x') {
      axios.post('https://ctz.blockmove.eu/api/v3','{"jsonrpc":"2.0","id":1577502038080,"method":"icx_getBlockByHash","params":{"hash":"'+value+'"}}')
      .then(res => {
        // if (typeof (res.data.result.height) !== 'undefined') {
            this.props.history.push('/block/' + value);
        // } else {
        //   this.props.history.push('/tx/' + value);
        // }
      })

    // )
      .catch(err => {
        // alert(err)
        this.props.history.push('/tx/' + value);
      })


      // an error occurred, call the done function and pass the err message
      // this.props.history.push('/block/' + value);
    //   }
    // );
      // let checkblock = ;
      // console.log(checkblock)

    //   if (typeof (checkblock) === null) {
    //     this.props.history.push('/block/' + value);
    //
    // } else {
    //   this.props.history.push('/tx/' + value);
    // }
    //
    } else {
            this.props.history.push('/404');
    }
    // this.props.history.push(newLocation);
    this.setState({value: ''}) // <= here

  }

  // handleCurrency = (e) => {
  //   // console.log('handleCurrency', e.target.id);
  //   this.setState({currency:e.target.id})
  //   localStorage.setItem("currency",e.target.id)
  //   axios.get('./../data/data.json').then(res=>this.setState({mult:res.data.forex[getCurr()],icxusd:res.data.icxusd,change:res.data.priceChangePercent}))
  // }

  renderCurrencies = () => {
    const currencies = [
      {name:'USD',font:'dollar-sign'},
      {name:'AUD',font:'dollar-sign'},
      {name:'CAD',font:'dollar-sign'},
      {name:'CNY',font:'yen-sign'},
      {name:'CZK',font:'i-cursor'},
      {name:'EUR',font:'euro-sign'},
      {name:'GBP',font:'pound-sign'},
      {name:'HKD',font:'dollar-sign'},
      {name:'INR',font:'rupee-sign'},
      {name:'JPY',font:'yen-sign'},
      {name:'KRW',font:'won-sign'},    
      {name:'RUB',font:'ruble-sign'}    

    ]
    return currencies.map((x,index)=> {
      const {font,name} = x
      return (
        <Dropdown.Item key={index} id={name} onClick={e=>this.props.handleCurrency(e)} className={langColor}><FontAwesomeIcon icon={['fa', font]} />&nbsp;{name}</Dropdown.Item>
      )
    })


  }

  render(){
    const {dropshow,dropshowGov,dropshowRes,dropshowBn,language} = this.state
    const {change,icxusd,currency,mult} = this.props
    const onMouseEnter = () => {
      this.setState({dropshow: true})
      }

    const onMouseLeave = () => {
      this.setState({dropshow: false})
      }
    const onMouseEnterGov = () => {
      this.setState({dropshowGov: true})
      }
    const onMouseLeaveGov = () => {
      this.setState({dropshowGov: false})
      } 
    const onMouseEnterRes = () => {
      this.setState({dropshowRes: true})
      }
    const onMouseLeaveRes = () => {
      this.setState({dropshowRes: false})
      } 
    const onMouseEnterBn = () => {
      this.setState({dropshowBn: true})
      }
    const onMouseLeaveBn = () => {
      this.setState({dropshowBn: false})
      } 
    const item = (link, title) => <Dropdown.Item onClick={() => (goTo(link)+this.setState({dropshow: false}))} className={dropdownColor}>{title}</Dropdown.Item>
    const goTo = (here) => this.props.history.push(here)
    const { i18n } = this.props;
    const nav = (link, title) => <NavItem onClick={() => this.props.history.push(link)} className="text-color nav-link pointer">{title}</NavItem>
    const link = (link, title) => <Dropdown.Item href={link} rel="noopener noreferrer" target="_blank" className={dropdownColor}>{title}</Dropdown.Item>
    let header = (title) => <div><Dropdown.Header className={dropdownHeader}>{title}</Dropdown.Header><Dropdown.Divider /></div>
    let dropdownColor='dropdown-bg dropdown-item text-uppercase text-color'
    let dropdownHeader='dropdown-header text-uppercase mb-0 h6'
    let col='d-flex flex-column'
    return (
    <div className="App-header">

      <Navbar collapseOnSelect expand="md" className="p-0 p-md-0 navbar app-header d-flex flex-column">
      <div className="mb-1 d-sm-none d-md-flex flex-row-reverse topbar w-100">
      <div className="container d-flex flex-row">
      <Row>
      <Col className="d-flex">
      <Nav>

      <div className="d-none d-md-inline-block price rounded ml-2">
      <span className="text-light">ICX: <NumberFormat value={icxusd*mult} decimalScale={4} displayType={'text'} thousandSeparator={true} prefix={currSymbol()} /></span> <span className={change < 0 ? 'text-danger' : 'text-success'}>(<NumberFormat value={change} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={change >= 0 ? '+' : ''} suffix={'%)'} /></span>
      </div>
            </Nav>

      </Col>
      </Row>
      <Row className="d-none d-md-flex pr-3">

      <Col>

      <Nav>

      <div>
      <Button onClick={() => this.toggleThemeChange()}  className="switch m-1 pt-1 langButton border-0">
      <FontAwesomeIcon icon={faMoon} />
      </Button>
      </div>
      <Dropdown alignRight className="m-1 p-0">
        <Dropdown.Toggle variant="white" className=" p-0">
          <FlagIcon code={language.toUpperCase()} size={25} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-nohover">
        <Dropdown.Item className={langColor} onClick={() => (i18n.changeLanguage('en')+localStorage.setItem("i18nextLng", "en")+this.setState({language:'en'}))}><FlagIcon className="pb-1" code={'USA'.toUpperCase()} size={20} />&nbsp;English</Dropdown.Item>
        <Dropdown.Item className={langColor} onClick={() => (i18n.changeLanguage('zh')+localStorage.setItem("i18nextLng", "zh")+this.setState({language:'zh'}))}><FlagIcon className="pb-1" code={'CHN'.toUpperCase()} size={20} />&nbsp;中文</Dropdown.Item>
        <Dropdown.Item className={langColor} onClick={() => (i18n.changeLanguage('ko')+localStorage.setItem("i18nextLng", "ko")+this.setState({language:'ko'}))}><FlagIcon className="pb-1" code={'KOR'.toUpperCase()} size={20} />&nbsp;한국어</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown alignRight>
        <Dropdown.Toggle variant="white" className="text-white mb-1 pb-0 pr-0 langButton">
          <FontAwesomeIcon icon={['fa', currency === 'JPY' ? 'yen-sign' : currency === 'CNY' ? 'yen-sign' : currency === 'EUR' ? 'euro-sign' : currency === 'KRW' ? 'won-sign' : currency === 'RUB' ? 'ruble-sign' : currency === 'CZK' ? 'i-cursor'  : currency === 'INR' ? 'rupee-sign' : currency === 'GBP' ? 'pound-sign' : 'dollar-sign']} />&nbsp;{currency}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-nohover">
      {this.renderCurrencies()}

        </Dropdown.Menu>
      </Dropdown>
      <UnlockWithICONex onClick={() => this.onUnlockWallet()} />
      </Nav>

        </Col>
        </Row>
        </div>
        </div>

        <div className="container toplowbar">
          <div className="w-auto pt-0 pb-0 pl-3 pl-md-0">
          <Navbar.Brand className="text-color">
          <Link to="/">
          <img
              src={logo}
              width="60"
              height="60"
              className="d-inline-block align-middle"
              alt="ICONwatch logo"
            />{' '}<span className="d-none d-sm-inline-block logoSpan">iconwatch</span>
            </Link>
          </Navbar.Brand>
          </div>
          <div className="pr-3">
          <Navbar.Toggle className="text-color" aria-controls="responsive-navbar-nav" />
          </div>
          <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
            <div className="row w-100">
              <div className="col ml-1 pr-0">
                <Nav className="justify-content-end">
                  <Form onSubmit={this.handleSubmit} inline className="my-2 ml-2 my-lg-0"  id="navBarSearchForm" >
                  <FormControl type="text" name="value" value={this.state.value} onChange={this.handleChange} autoComplete="off" spellCheck="false" placeholder={this.lex('searchbar')} />
                  {/*<Button className="d-none d-md-block text-color searchBtn" onClick={this.handleSubmit}><FontAwesomeIcon icon={faSearch} /></Button>*/}
                  </Form>
                </Nav>
              </div>
              <div className="w-100"></div>
              <div className="col pr-0">
                <Nav className="justify-content-end">
                  {nav(`/`,this.lex('title'))}
                  {/* {nav(`/dapps`,<span>{this.lex('dapps')} <sup className="text-danger font-weight-bold"></sup></span>)} */}
                  {/* {nav(`/tokens`,<span>{this.lex('tokens')}  */}
                  {/* <sup className="text-danger font-weight-bold">{this.lex('new')}</sup> */}
                  {/* </span>)} */}
                  <Dropdown alignRight className="d-none d-md-flex align-items-center" onMouseOver={onMouseEnterBn} onMouseLeave={onMouseLeaveBn} show={dropshowBn}>
                    <Dropdown.Toggle className="dropdown-toggle text-color font-weight-light align-bottom" variant="white">
                    {this.lex('blockchain')} <FontAwesomeIcon className="small" icon={faAngleDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-nohover pt-1 mt-0">
                    <div className="d-flex flex-row-reverse p-1">
                      <div className={col}>
                        {item(`/live`,this.lex('iconlive'))}
                        {item(`/tokens`,this.lex('tokens'))}
                        {item(`/blocks`,this.lex('blocks'))}
                        {item(`/transactions`,this.lex('transactions'))}
                        {/*<Dropdown.Item className={dropdownColor} disabled><del>{this.lex('contracts')}</del></Dropdown.Item>*/}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown alignRight className="d-none d-md-flex align-items-center" onMouseOver={onMouseEnterRes} onMouseLeave={onMouseLeaveRes} show={dropshowRes}>
                    <Dropdown.Toggle className="dropdown-toggle text-color font-weight-light align-bottom" variant="white">
                    {this.lex('resources')} <FontAwesomeIcon className="small" icon={faAngleDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-nohover pt-1 mt-0">
                    <div className="d-flex flex-row-reverse p-1">
                        <div className={col}>
                        {item(`/charts`,this.lex('charts'))}
                        <Dropdown.Divider />
                        {item(`/dapps`,this.lex('dapps'))}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown alignRight className="d-none d-md-flex align-items-center" onMouseOver={onMouseEnterGov} onMouseLeave={onMouseLeaveGov} show={dropshowGov}>
                    <Dropdown.Toggle className="dropdown-toggle text-color font-weight-light align-bottom" variant="white">
                    {this.lex('gov')} <FontAwesomeIcon className="small" icon={faAngleDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-nohover pt-1 mt-0">
                      <div className="d-flex flex-row-reverse p-1">
                        <div className={col}>
                        {item(`/governance`,this.lex('preps'))}
                        {item(`/governance#votes`,this.lex('proposals'))}
                        {item(`/governance#blacklist`,this.lex('blacklist'))}
                        <Dropdown.Divider />
                        {item(`/nodetracker`,this.lex('nodetracker'))}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown alignRight className="d-none d-md-flex align-items-center" onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} show={dropshow}>
                    <Dropdown.Toggle className="dropdown-toggle text-color font-weight-light align-bottom" variant="white">
                    {this.lex('more')} <FontAwesomeIcon className="small" icon={faAngleDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-nohover pt-1 mt-0">
                      <div className="d-flex flex-row-reverse p-1">
                        {/* <div className={col}>
                        {header(this.lex('testnet'))}
                        {link('https://test.iconwat.ch','euljiro')}
                        {link('https://dev.iconwat.ch','yeouido')}
                        </div> */}
                        <div className="d-flex flex-column">
                        {header(this.lex('tools'))}
                        {item(`/convert`,this.lex('converttool'))}
                        {item(`/prepcalc`,this.lex('prepcalc'))}
                        {/* {item(`/system`,this.lex('system'))} */}
                        </div>
                        <div className="d-flex flex-column">
                        {header(this.lex('development'))}
                        {/* {link('https://icon.foundation/resources/whitepaper/ICON_Whitepaper_EN.pdf',this.lex('whitepaper'))}
                        {link('https://icon.foundation/download/ICON_Decentralized_Autonomous_Governance_System_EN.pdf',this.lex('arch'))} */}
                        {link('https://icondev.io','dev portal')}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </div>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
    );
  }
}

// export default withRouter(AppHeader);
export default withTranslation('common')(withRouter(AppHeader));
