import React from 'react';
import { Link } from 'react-router-dom';
import {Badge} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import {wait,tomApi} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';
import {currSymbol} from './../utils/funcs';

const Posts = ({ currentPage, posts, loading, mult, t}) => {
  const lex = (w) => t(w, { framework: "react-i18next" })
  return loading 
  ? wait()
  : posts.map((score, index) => {
    let { status, totalSupply, symbol, decimals, name, address, balance, owner, price } = score //destructuring
    balance = !balance ? 0 : balance
    // name = !name ? '-' : name
    owner = !owner ? '-' : owner
    totalSupply = !totalSupply ? 0 : totalSupply
    decimals = !decimals ? 0 : decimals
    symbol = !symbol ? '' : symbol
     return (
       <tr key={index}>
       <td colSpan="3" className="col-special text-truncate">
       <div className="d-flex align-items-center">
       <Jazzicon diameter={18} seed={typeof(address) === 'undefined' ? 0 : jsNumberForAddress(address)} />&nbsp;<Link to={`/token/${address}`}>{!name ? address.slice(0,14) : name}</Link>
      </div>
       </td>
       <td colSpan="2" className={'d-none d-lg-table-cell col-special text-truncate'}>
       <NumberFormat decimalScale='1' value={tomApi(totalSupply,decimals)*price*mult} displayType={'text'} thousandSeparator={true} prefix={currSymbol()}/>
       </td>
       <td colSpan="2" className="col-special text-truncate">
       <Badge className='float-left p-1 badge-width media-bg'>
       <NumberFormat decimalScale='3' value={price*mult} displayType={'text'} thousandSeparator={true} prefix={currSymbol()}/>
       </Badge>
       </td>
       <td colSpan="3" className={'d-none d-lg-table-cell col-special text-truncate'}>
       <NumberFormat decimalScale='0' value={tomApi(totalSupply,decimals)} displayType={'text'} thousandSeparator={true} suffix={' '+symbol}/>
       </td>
       <td colSpan="3" className={'d-none d-lg-table-cell col-special text-truncate'}>
       <div className="d-flex align-items-center">
       <Jazzicon diameter={18} seed={typeof(owner) === 'undefined' ? 0 : jsNumberForAddress(owner)} />&nbsp; {!owner ? '-' : <Link to={`/address/${owner}`}>{owner.slice(0,14)}</Link>}
       </div>
       </td>
       </tr>
     )
   })
};

export default withTranslation('common')(Posts);
