import React, { Component } from 'react';
import globe from './../assets/set/server.svg'
import NumberFormat from 'react-number-format';
import {Container,Col,Row, Button, Table} from 'react-bootstrap'
import { withTranslation } from 'react-i18next';
import {currSymbol} from './../utils/funcs'
import axios from 'axios'
import {API} from './../utils/util'
import {getTotalSupply,getPreps,getIIS} from './../utils/calls'
import {wait} from './../Clipboard/wait'


class StakingCalc extends Component {

  constructor(props) {
    super(props);
    this.state = {isLoading:false,votes:500000,monthlyCost:400,price:1,currency:'usd',mult:1,magicNumber:4000000,irep:14000,totalDelegated:337000000,cutOff:100000}
  }


  async componentDidMount() {
    document.title = 'P-Rep Earnings Calculator';
    await this.load()
  }

  async load() {
    let requestOne = axios.post(API,getTotalSupply())
    let requestTwo = axios.post(API,getPreps());
    let requestThree = axios.post(API,getIIS());
    await axios.all([requestOne,requestTwo,requestThree])
        .then(axios.spread((...responses) => {
        const responseOne = responses[0]
        const responseTwo = responses[1]
        const responseThree = responses[2]        

        this.setState({supply:parseInt(responseOne.data.result.slice(2,50),16)/1000000000000000000,irep:(parseInt(((responseThree.data.result.variable.irep).slice(0,50)),16)/1000000000000000000),totalDelegated:(parseInt(((responseTwo.data.result.totalDelegated).slice(0,50)),16)/1000000000000000000),magicNumber:parseInt((responseTwo.data.result.preps[21].delegated).slice(2,50),16)/1000000000000000000,cutOff:parseInt((responseTwo.data.result.preps[99].delegated).slice(2,50),16)/1000000000000000000})

        }))

    this.setState({votes:500000,monthlyCost:400*this.props.mult,price:this.props.icxusd*this.props.mult,currency:this.props.currency,mult:this.props.mult,magicNumber:4000000,isLoading:true})

  }
  
  lex = (w) => this.props.t(w, { framework: "react-i18next" })


  change(e) {
    const target = e.currentTarget
    this.setState({ [`${target.id}`]: (target.value)})
  }

profitCalc = x => {
    const {votes,magicNumber,totalDelegated,irep,cutOff} = this.state

    return (cutOff > votes) ? 0 : (magicNumber < votes) ? (((irep * (1 / 2) * 100 * (((votes)) / totalDelegated)) + (irep * (1 / 2)))) : ((irep * (1 / 2) * 100 * (((votes)) / totalDelegated)))
}

dollarCalc = x => {
    const {price} = this.state
    const {mult} = this.props
    return this.profitCalc()*price*mult
}

results = (monthlyCost) =>{ 
let arr = [{name:'Per Hour',multiply:(x)=>((x)/30)/24},
{name:'Per Day',multiply:x=>(x/30)},
{name:'Per Week',multiply:x=>(x/30)*7},
{name:'Per Month',multiply:x=>x},
// {name:'Per Year',multiply:x=>(x/30)*365}
]

    return arr.map((x,i)=>{
        return (<tr key={i}>
            <td>{x.name}</td>
            <td><NumberFormat value={x.multiply(this.profitCalc()) } decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={'ICX'}/>{' ('}<NumberFormat value={x.multiply(this.dollarCalc()) } decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={currSymbol()}/>{')'}</td>
            <td><NumberFormat value={x.multiply(monthlyCost)} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={currSymbol()}/></td>
            <td><NumberFormat className={(x.multiply(this.dollarCalc()) - x.multiply(monthlyCost) < 0) ? 'text-danger' : 'text-success'} value={x.multiply(this.dollarCalc()) - x.multiply(monthlyCost)} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={currSymbol()}/></td>
            </tr>)
    })
}

render() {
    const {isLoading,votes,monthlyCost,irep,price} = this.state;
    
    // const apend = (title) => {
    //   return(
    //     <div className="input-group-append">
    //     <div className="groupinput input-group-text media-color pl-2 text-center">{title}</div>
    //     </div>
    //   )
    // }

    const title = (title) => {
      return (
        <label>{title}<span className="text-danger"> *</span></label>
      )
    }

    return !isLoading ? wait() : (
    <Container className="mt-3 mb-5">
    <Col>
    <Row>
    <div className="card border-0 shadow-sm">
    <div className="card-body pb-0 rounded">
    <Container>
    <Row>
        <div className="grey-border p-2 mb-3 col-md-12 text-left">
            <h4 className="display-5">                  <img
                                    src={globe}
                                    className="pb-2"
                                    width="35"
                                    height="35"
                                    alt={'globe'}
                                /> {this.lex('prepearn')}</h4>
                                <h6 className="d-none d-md-inline text-secondary">The P-Rep earnings calculator will display your expected earnings in both ICX and Fiat currencies.</h6>

        </div>
    </Row> 
    </Container>
    <Row className="mt-3 mb-3 pr-3 pl-3 text-left">
    <div className="col-md-6 mb-3">
        {title('Votes (ICX)')}
    <input type="number" id='votes' step="any" className="form-control form-control-sm" onChange={this.change.bind(this)} value={votes} />
    </div>
    {/* <div className="js-form-message col-md-4 mb-3">
        {title('Self Vote (ICX)')}
    <input name="ctl00$ContentPlaceHolder1$txtPower" value={selfvote} id="ContentPlaceHolder1_txtPower" className="form-control form-control-sm" type="text" placeholder="" required="" data-msg="Please enter the power consumption" data-error-className="u-has-error" data-success-className="u-has-success" />
    </div> */}
    <div className="col-md-6 mb-3">
    {title('Monthly Server Costs ('+this.props.currency+')')}
    <input type="number" id='monthlyCost' step="any" className="form-control form-control-sm" onChange={this.change.bind(this)} value={monthlyCost} />
    </div>
    <div className="col-md-6 mb-3">
    {title('iRep')}
    <input type="number" id='irep' step="any" className="form-control form-control-sm" onChange={this.change.bind(this)} value={irep} />
    </div>
    {/* <div className="js-form-message col-md-4 mb-3">
    {title('Total Supply (ICX)')}
    <input name="ctl00$ContentPlaceHolder1$txtAvgBlockTime" value={supply} id="ContentPlaceHolder1_txtAvgBlockTime" className="form-control form-control-sm" type="text" placeholder="" required="" data-msg="Please enter the average block time" data-error-className="u-has-error" data-success-className="u-has-success" />
    </div> */}
    <div className="col-md-6 mb-3">
    {title('ICX Price ('+this.props.currency+')')}
    <input type="number" id='price' step="any" className="form-control form-control-sm" onChange={this.change.bind(this)} value={price} />
    </div>
    </Row>
    <div className="text-right pr-3">
    <Button onClick={x=>this.load()} variant="outline-white" className="text-color">Reset</Button>
    {/* <Button onClick={x=>this.reset()} className="btn btn-sm btn-secondary mr-1 cancel">Reset</Button> */}
    {/* <input type="submit" name="ctl00$ContentPlaceHolder1$btnSubmit" value="Calculate" id="ContentPlaceHolder1_btnSubmit" className="btn btn-sm btn-primary" /> */}
    </div>
    <hr />
    <div className="pr-3 pl-3">
    <h3 className="card-header-title d-flex align-items-center mb-3"><span>Projected Earnings</span></h3>
    <div className="table-responsive mb-1">
    <Table className="text-color" size="md" hover>
    <thead className="media-color text-left">
    <tr>
    <th scope="col">Duration</th>
    <th scope="col">ICX Earned</th>
    <th scope="col">Server Cost</th>
    <th scope="col">Profit ({this.props.currency})</th>
    </tr>
    </thead>
    <tbody className="text-left">
    {this.results(monthlyCost)}
    </tbody>
    </Table>
    </div>
    </div>
    </div>
    </div>
    </Row>
    </Col>
    </Container>
);
}
}

export default withTranslation('common')(StakingCalc);
