import React, { Component } from 'react';
import RepsTable from './repstable';
import {Tabs, Tab} from 'react-bootstrap'
import RenderProps from './../Proposals/renderProps';
import { withRouter} from 'react-router-dom';
import Pills from './../Pills';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import {API} from './../utils/util'

class Governance extends Component {

        constructor(props) {
          super(props);
          this.state = {
            key:1,
            hash:'',
            currPage:1,
            preps:{totalStake:'',totalDelegated:'',preps:[]}
          }
          // this.handleClick = this.handleClick.bind(this);
        }

componentDidMount() {
  document.title = this.props.location.hash === '#votes' ? 'ICON Network Votes' : this.props.location.hash === '#blacklist' ? 'ICON P-Rep Blacklist' :'ICON Network Governance';
  (typeof(this.props.location.search) === 'undefined' || this.props.location.search === '' ) ? this.setState({currPage:1}) : this.setState({currPage:parseInt(this.props.location.search.slice(6,20))})
  // this.props.match.params.pathHash === 'votes' ? this.setState({ isLoading: true, key:2 }) : this.setState({ isLoading: true });
  this.props.location.hash === '#votes' ? this.setState({ key:2, hash:'#votes' }) : this.props.location.hash === '#blacklist' ? this.setState({ key:3, hash:'#blacklist' }) : this.setState({ key:1, hash:'#reps' });
  this.getData(this.props.location.hash)
}

lex = (w) => this.props.t(w, { framework: "react-i18next" })

static getDerivedStateFromProps(nextProps, prevState){

 if(nextProps.location.hash!==prevState.hash){
   return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
}
else return null;
}

async componentDidUpdate(prevProps, prevState) {
if(prevProps.location.hash!==this.props.location.hash){
  document.title = this.props.location.hash === '#votes' ? 'ICON Network Votes' : this.props.location.hash === '#blacklist' ? 'ICON P-Rep Blacklist' :'ICON Network Governance';
  this.props.location.hash === '#votes' ? this.setState({ key:2, hash:'#votes' }) : this.props.location.hash === '#blacklist' ? this.setState({ key:3, hash:'#blacklist' }) : this.setState({ key:1, hash:'#reps' });
}
if(prevProps.location.search!==this.props.location.search){
  (typeof(this.props.location.search) === 'undefined' || this.props.location.search === '' ) ? this.setState({currPage:1}) : this.setState({currPage:parseInt(this.props.location.search.slice(6,20))})
}

}

async getData(hash) {

  let getPreps = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPReps"}}}';
  await axios.post(API,getPreps)
  .then(res => {
  this.setState({preps:res.data.result})
  })


  // let getInactivePReps = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getInactivePReps"}}}';
  // await axios.post(API,getInactivePReps)
  // .then(res => {
  // this.setState({blacklist:res.data.result})
  // console.log(res)
  // })


  // let testcommand = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"estimateUnstakeLockPeriod"}}}';
  //
  // await axios.post('https://ctz.blockmove.eu/api/v3',testcommand)
  // .then(res => {
  // console.log(res)
  // })

}



handleClick = (e) => {
  // console.log('handleClick', e);
  this.setState({key:e})
  // console.log('test')
  switch (e) {
    case '1':
    default:
      return this.props.history.push("/governance")
    case '2':
      return this.props.history.push("#votes")
    case '3':
      return this.props.history.push("#blacklist")
  }
}

render() {

  const {key,preps,currPage } = this.state;

return (
        <div className="Gov container">
          <Pills type="gov" preps={preps} thes={preps}/>
            <div className="row mt-3">
              <div className="col-12">
                <div className="card border-0 shadow-sm">
                  <Tabs className="nav-tabs" id="profile-tabs" activeKey={key} onSelect={k => this.handleClick(k)} transition={false}>
                  <Tab mountOnEnter={true} id="reps" eventKey="1" title={this.lex('preps')}>
                  <RepsTable type="gov" page={currPage} icxusd={this.props.icxusd} mult={this.props.mult} />
                  {/*(typeof(this.props.location.search) === 'undefined' || this.props.location.search === '' ) ? 1 : parseInt(this.props.location.search.slice(6,20))*/}
                  </Tab>
                  <Tab mountOnEnter={true} id="proposals" eventKey="2" title={this.lex('proposals')}>
                  <RenderProps />
                  </Tab>
                  <Tab mountOnEnter={true} id="blacklist" eventKey="3" title={this.lex('blacklist')}>
                  <RepsTable page={1} type={'blacklist'} icxusd={this.props.icxusd} mult={this.props.mult} />
                  </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

);
}
}

export default withTranslation('common')(withRouter(Governance));
