import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import {Table, Badge, Tabs,Tab, Tooltip,OverlayTrigger, Container, Row} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import ReactJson from 'react-json-view'
import {wait, numberFormat,simple} from './../Clipboard/wait'
import { withTranslation } from 'react-i18next';
import {currSymbol} from './../utils/funcs'
import {call,request} from './../utils/funcs'
import {thes} from './../utils/requests'
import {getBlockHeight,getBlockHash} from './../utils/calls'

const thtop = (content) => <th className="border-top-0">{content}</th>
const tdtop = (content) => <td className="border-top-0">{content}</td>


class Block extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      future:false,
      preps:{},
      block_hash:0,
      hash:'',
      icxusd:0,
      value:0,
      localized:0,
      tx:[{txHash:'1234'}],
      block: {data:'No Data.'},
      block_from:[],
      block_to:[],
      block_value:[],
      key: 1
    }
    this.scrollDiv = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  async componentDidMount() {

    this.setTab()

    this.setState({ isLoading: true})

    document.addEventListener('keydown', this.handleKeyPress);

    request(thes()).then(res => {this.setState({ preps:res.data })})

    let block_hash;
    if (this.props.match.params.blockHash.length > 8 && this.props.match.params.blockHash.length < 66) {
      this.props.history.push('/404');
      block_hash = this.props.match.params.blockHash < 0 ? 0 : this.props.match.params.blockHash;
    }  else if (this.props.match.params.blockHash.length >66) {
      this.props.history.push('/404')
      block_hash = this.props.match.params.blockHash < 0 ? 0 : this.props.match.params.blockHash;
    }  else {
      block_hash = this.props.match.params.blockHash < 0 ? 0 : this.props.match.params.blockHash;
    }
    document.title = 'ICON Block: '+block_hash;


    this.getBlock(block_hash)

  }

  componentWillUnmount() {
  document.removeEventListener('keydown', this.handleKeyPress);
}

lex = (w) => this.props.t(w, { framework: "react-i18next" })

static getDerivedStateFromProps(nextProps, prevState){

  if(nextProps.match.params.blockHash!==prevState.blockHash){
    return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
  } else if (nextProps.location.hash!==prevState.hash) {

    return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
  }
  else return null;
}

async componentDidUpdate(prevProps, prevState) {
  if(prevProps.match.params.blockHash!==this.props.match.params.blockHash){

    this.setTab()

    document.title = 'ICON Block: '+this.props.match.params.blockHash;

    this.getBlock(this.props.match.params.blockHash)
      
  }
  if(prevProps.location.hash!==this.props.location.hash){

    this.setTab(true)
  }
}

setTab(change = false){
  this.props.location.hash === '#details' 
  ? this.setState({ key:2, hash:'#details' }) 
  : this.props.location.hash === '#raw' 
  ? this.setState({ key:3, hash:'#raw' }) 
  : this.setState({ key:1 })
  if (this.props.location.hash !== '' && !change) this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })

}

  async getBlock(block_hash){
    let currBlockObj;

    if (block_hash.length === 66) {


      await call(getBlockHash(block_hash)).then(res=>{currBlockObj=res.data.result})
      // .catch(errors => {
      //   if (typeof(errors) !== 'undefined') {
      //     currBlockObj=errors.response.data.error
      //   console.log(errors.response.data.error.message)
      // }
      // })
    
    } else {

      block_hash = '0x'+parseInt(block_hash).toString(16);
      await call((getBlockHeight(block_hash))).then(res=>{currBlockObj=res.data.result})
      // .catch(errors => {
      //   if (typeof(errors) !== 'undefined') {
      //     currBlockObj=errors.response.data.error
      //   console.log(errors.response.data.error.message)
      // }
      // })

    }
    
    let tx = [];
    let value = 0;
    // console.log(currBlockObj.message)
    currBlockObj.confirmed_transaction_list.forEach((i) => {
       tx.push(i);
      if (i.value != null) {
        value += parseInt(i.value.slice(2,50),16)/1000000000000000000
      }
    })

    this.setState({
      isLoading: false,
      tx: tx,
      next_block: (currBlockObj.height + 1),
      previous_block: (currBlockObj.height - 1),
      block_hash: currBlockObj.block_hash,
      value: simple(value),
      block_ts: (currBlockObj.time_stamp / 1000000),
      block_txs: parseInt(currBlockObj.confirmed_transaction_list.slice().length, 10),
      block: currBlockObj
    })

  }

  renderTxhead() {
    const {tx} = this.state;
    return (tx.length === 0) 
    ? this.lex('notx')
    : (
      <Table className="text-color text-left" size="md" hover>
        <thead>
          <tr>
            <th className="border-top-0">{this.lex('hash')}</th>
            <th className="border-top-0">{this.lex('type')}</th>
            <th colSpan="2" className="border-top-0 d-none d-lg-table-cell">{this.lex('from')}</th>
            <th colSpan="2" className="border-top-0 d-none d-lg-table-cell">{this.lex('to')}</th>
            <th className="border-top-0 d-none d-lg-table-cell">{this.lex('value')}</th>
          </tr>
        </thead>
        <tbody>
          {this.renderTxs(tx)}
        </tbody>
      </Table>
    )}

  renderTxs(tx) {
    const {preps} = this.state;
    let flex = 'd-flex align-content-center'
    return tx.map((tx, index) => {
       let { txHash, tx_hash, dataType, from, to, value } = tx //destructuring
       if (typeof(txHash) === 'undefined') {txHash = '0x'+tx_hash}
       return (
         <tr key={index}>
             <td className="col-special text-center text-truncate align-middle"><div className="d-flex p-0 justify-content-left">
             <Link to={`/tx/${txHash}`}>{(txHash) === '0xundefined' ? '' : txHash.slice(2,14)}</Link>
             </div></td>
             <td className="col-special"><div className={flex}>
             <Badge className='p-1 badge-width text-uppercase' variant={(typeof(dataType) === 'undefined' || (dataType=== 'message')) ? 'primary' : ((dataType=== 'call') ? 'secondary' : 'dark')}>
             {(typeof(dataType) === 'undefined' || (dataType=== 'message')) ? this.lex('transfer') : ((dataType=== 'call') ? this.lex('contract') : this.lex('system'))}
             </Badge>
             </div></td>
           <td colSpan="2" className="col-special d-none d-lg-table-cell"><div className={flex}>
           {typeof(from) === 'undefined' ? '' : (
           <div><Jazzicon className={typeof(from) === 'undefined' ? 'd-none' : 'd-inline-block'}  diameter={20} seed={typeof(from) === 'undefined' ? 0 : jsNumberForAddress(from)} />
           </div>)}&nbsp;
           <Link to={typeof(from) === 'undefined' ? '/' : '/address/'+from }>
           {typeof(from) === 'undefined' ? '' : (typeof(preps[from]) === 'undefined') ? from.slice(0,14)+'...' : preps[from] }
           </Link>
             </div></td>
             <td colSpan="2" className="col-special d-none d-lg-table-cell"><div className={flex}>
           {typeof(to) === 'undefined' ? '' : (
             <div><Jazzicon className={typeof(to) === 'undefined' ? 'd-none' : 'd-inline-block'} diameter={20} seed={typeof(to) === 'undefined' ? 0 : jsNumberForAddress(to)} />
             </div>)}&nbsp;
           <Link to={typeof(to) === 'undefined' ? '/' : '/address/'+to }>
           {typeof(to) === 'undefined' ? '' : (typeof(preps[to]) === 'undefined') ? to.slice(0,14)+'...' : preps[to] }
           </Link>
           </div></td>
           <td className="col-special d-none d-lg-table-cell text-center"><div className="d-flex border-0 p-0 justify-content-right">
           <Badge className='float-left p-1 badge-width media-bg'>
             <NumberFormat value={typeof(value) === 'undefined' ? 0 : numberFormat(value,18)} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
           </Badge>
           </div></td>
         </tr>
       )
     })
  }

  handleClick = (e) => {
    this.setState({key:e})
    switch (e) {
      case '1':
      default:
        return this.props.history.push("#transactions")
      case '2':
        return this.props.history.push("#details")
      case '3':
        return this.props.history.push("#raw")

    }
  }

  handleKeyPress = (event) => {
    const {next_block,previous_block} = this.state
    if (event.keyCode === 37) {
      this.props.history.push('/block/' + (previous_block))
    }
    if (event.keyCode === 39) {
      this.props.history.push('/block/' + (next_block))
    }
  }

  extraData() {
    const { isLoading, tx, key, block } = this.state;

    return isLoading 
    ? wait()
    : (
      <Tabs className="nav-tabs" id="profile-tabs" activeKey={key} onSelect={k => this.handleClick(k)} transition={false}>
      <Tab mountOnEnter={true} eventKey="1" title={`${this.lex('transactions')} (${tx.length})`}>
      <Container>
      {this.renderTxhead()}
      </Container>
      </Tab>
      <Tab mountOnEnter={true} eventKey="2" title={this.lex('details')}>
      <Container>
      <Row>
        <div className="col-md-6">
          <table className="table text-color text-left">
            <tbody>
              <tr>
                {thtop(this.lex('merkle')+':')}
                {tdtop(<code className="text-color">{block.merkle_tree_root_hash}</code>)}
              </tr>
              <tr>
                <th>{this.lex('version')+':'}</th>
                <td>
                  {block.version}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <Table className="text-color text-left">
            <tbody>
              <tr>
                {thtop(this.lex('parent')+':')}
                {tdtop(<Link to={`../block/${'0x'+block.prev_block_hash}`}>{'0x'+block.prev_block_hash}</Link>)}
              </tr>
              <tr>
                <th>{this.lex('sig')+':'}</th>
                <td><textarea disabled spellCheck='false' className="form-control rounded-0" value={block.signature}></textarea></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Row>
      </Container>
      </Tab>
      <Tab mountOnEnter={true} eventKey="3" title={this.lex('raw')}>
      <Container>
      <div className="overflow-auto p-2 media-bg text-left">
      <ReactJson src={(typeof(block)) === 'undefined' ? {data:'No Data'} : (block)} />
      </div>
      </Container>
      </Tab>
      </Tabs>
    )

  }


  topTable(value){
    const { isLoading, preps, block, previous_block, next_block, block_ts, block_txs } = this.state;
    const {icxusd, mult} = this.props
    return isLoading 
    ? wait()
    : (
      <Row>
        <div className="col-md-6 overflow-auto">
          <Table className="text-color text-left">
            <tbody>
              <tr>
                {thtop(this.lex('height')+':')}
                {tdtop(
                <div>
                  <NumberFormat value={block.height} displayType={'text'} thousandSeparator={true} prefix={''}/>&nbsp;&nbsp;
                  <div className="d-inline-block">
                  <Link className={block.height === 0 ? 'd-none' : 'd-inline'} to={`../block/${previous_block}`}>&larr;{this.lex('prev')}</Link>&nbsp;
                  <Link to={`../block/${next_block}`}>{this.lex('next')}&rarr;</Link>&nbsp;
                  </div>
                </div>)}
              </tr>
              <tr>
                <th>{this.lex('time')+':'}</th>
                <td>
                  <Moment format="YYYY/MM/DD HH:mm:ss" unix="unix">{block_ts}</Moment>
                </td>
              </tr>
              <tr>
                <th>{this.lex('bp')+':'}</th>
                <td>
                  <Link to={`../address/${block.peer_id}`}>{typeof(preps[block.peer_id]) !== 'undefined' ? preps[block.peer_id] : block.peer_id}</Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="col-md-6">
          <Table className="text-color text-left">
            <tbody>
              <tr>
              {thtop(this.lex('transactions')+':')}
              {tdtop(<NumberFormat value={block_txs} displayType={'text'} thousandSeparator={true} suffix={' '+this.lex('tx_s')}/>)}
              </tr>
              <tr>
                <th>{this.lex('value')+':'}</th>
                <td>
                <NumberFormat decimalScale='18' value={value} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-left"><NumberFormat decimalScale='4' value={icxusd*mult} displayType={'text'} thousandSeparator={true} prefix={' @ '+currSymbol()} suffix={'/ICX'}/></Tooltip>}>
                <NumberFormat decimalScale='2' value={((value*icxusd*mult)) < 0.005 ? 0 : value*icxusd*mult} displayType={'text'} thousandSeparator={true} prefix={' ('+currSymbol()} suffix={')'}/>
                </OverlayTrigger>
                </td>
              </tr>
              <tr>
                <th>{this.lex('blockhash')+':'}</th>
                <td>
                  <code className="text-color">{'0x'+block.block_hash}</code>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Row>
    )
  }

  render() {
    const { isLoading,value,block } = this.state;
    return isLoading
    ? wait()
    : (
        <Container className="mt-3">
          <div className="card text-color border-0 shadow-sm">
            <div className="card-body pb-0 rounded">
              <Container>
                <Row>
                  <div className="grey-border p-2 mb-3 col-md-12 text-left">
                    <h4 className="display-5 d-flex align-items-center"> <Jazzicon diameter={20} seed={block.height} />&nbsp;
                      <NumberFormat value={block.height} displayType={'text'} thousandSeparator={true} prefix={this.lex('block')+' '}/></h4>
                    <h6 className="d-none d-md-inline text-secondary">{'0x'+block.block_hash}</h6>
                  </div>
                </Row>
                {this.topTable(value)}
              </Container>
            </div>
          </div>
          <div ref={this.scrollDiv} className="card mt-3 text-color border-0 shadow-sm">
            {this.extraData()}
          </div>
        </Container>
      );
    }
  }
// export default withRouter(Block);
export default withTranslation('common')(withRouter(Block));
